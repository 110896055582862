import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DataGridSearch from "components/DataGridSearch/OrdersSearch";
import Filters from "components/Orders/Filters";
import AddAdminOrderForm from "components/Orders/OrderForm/AddAdminOrderForm";
import OrdersDataGrid from "components/Orders/OrdersDataGrid";
import TabFilters from "components/UI/TabFilters";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import OrderDetailsPage from "pages/Orders/OrderDetailsPage";
import PropTypes from "prop-types";
import { memo } from "react";
import { ORDER_STATUS_OPTIONS } from "utils/constants/orderStatusOptions";

function Orders({ create, orderDetails }) {
  if (create) {
    return <AddAdminOrderForm />;
  }

  if (orderDetails) {
    return <OrderDetailsPage />;
  }
  return (
    <Box py={2}>
      <Box paddingTop={2} paddingBottom={2} px={1}>
        <CustomBreadcrumbs />
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          variant="h1"
          color="primary"
          fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.6rem" }}
          fontWeight={600}
          paddingTop={1}
          paddingBottom={1}
        >
          Orders
        </Typography>
        <Filters />
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {/* <Actions /> */}
        <DataGridSearch searchQueryParam="orderId" />
      </Box>
      <TabFilters
        queryKey="status"
        tabOptions={ORDER_STATUS_OPTIONS}
        defaultTab={{ label: "All Orders", value: "all" }}
      />
      <OrdersDataGrid />
    </Box>
  );
}

export default memo(Orders);

Orders.propTypes = {
  create: PropTypes.bool,
  orderDetails: PropTypes.bool,
};

Orders.defaultProps = {
  create: false,
  orderDetails: false,
};
