import AddIcon from "@mui/icons-material/Add";
import { Link } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bulkUploadProducts } from "store/slices/productsSlice";
import { PRIMARY, SEARCH_ICON_COLOR } from "styles/colors";
import StyledMuiCard from "./BulkUploadCard/StyledMuiCard";

function ProductBulkUpload() {
  const [csvFile, setCsvFile] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.products);

  const inputRef = useRef();

  const handleCsvUpload = useCallback(() => {
    if (csvFile && csvFile.type === "text/csv") {
      setError("");
      let formData = new FormData();
      formData.append("csvFile", csvFile);
      dispatch(bulkUploadProducts({ data: formData })).then(() =>
        setCsvFile("")
      );
    } else {
      setError("Please select a CSV file");
    }
  }, [csvFile, dispatch]);

  return (
    <Box py={2} px={1}>
      <Box display="flex" alignItems="center">
        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            fontSize: { md: "1rem", lg: "1.5rem" },
            paddingRight: "1rem",
          }}
        >
          PRODUCTS
        </Typography>
        <Box paddingTop={2} paddingBottom={1}>
          <CustomBreadcrumbs />
        </Box>
      </Box>
      <Box display="flex" maxWidth="100%" justifyContent="space-between">
        <Typography
          variant="h6"
          fontWeight="600"
          color="primary.main"
          fontSize={{ xs: "0.75rem", sm: "1rem", md: "2rem" }}
        >
          Bulk Upload
        </Typography>

        <Link
          href="https://bazaarghar-main.s3.me-south-1.amazonaws.com/ProductCsvTemplate.csv"
          style={linkStyle}
        >
          Download Template
        </Link>
      </Box>
      <Box py={3}>
        <StyledMuiCard
          title="Upload CSV File"
          subTitle="Upload csv as for the designated required format"
          sx={{
            pointerEvents: loading ? "none" : "auto",
          }}
        >
          <Box
            onClick={() => {
              if (!loading) {
                inputRef.current?.click();
              }
            }}
            sx={{
              border: "2px dashed #068ECF",
              padding: "20px",
              margin: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
              cursor: loading ? "not-allowed" : "pointer",
            }}
          >
            {csvFile ? (
              <Typography>{csvFile.name}</Typography>
            ) : (
              <Box display="flex" flexDirection="column" alignItems="center">
                <AddIcon sx={{ color: PRIMARY, fontSize: "50px" }} />
                <Typography variant="h5" color={SEARCH_ICON_COLOR}>
                  Choose File
                </Typography>
              </Box>
            )}
          </Box>

          {error && (
            <Box px={2}>
              <Typography color="error" fontSize={{ lg: "1rem" }}>
                {error}
              </Typography>
            </Box>
          )}
          <Box textAlign="right" px={2} pb={2}>
            <Button
              variant="contained"
              onClick={handleCsvUpload}
              disableElevation
              disabled={loading || !csvFile}
              sx={{
                fontWeight: "bold",
                minWidth: { md: 80, xl: 230 },
                height: { xs: 45, xl: 50 },
              }}
            >
              {loading ? "Uploading" : `Upload`}
            </Button>
          </Box>
          <input
            type="file"
            hidden
            ref={inputRef}
            onChange={(e) => setCsvFile(e.target.files[0])}
          />
        </StyledMuiCard>
      </Box>
    </Box>
  );
}

export default ProductBulkUpload;

const linkStyle = {
  fontWeight: "600",
  alignSelf: "center",
  textDecoration: "underline",
  textDecorationThickness: "15%",
};
