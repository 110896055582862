import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import { Form, Formik } from "formik";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addAuthDetail,
  getAuthDetails,
  updateAuthDetail,
} from "store/slices/authSlice";
import reduceObject from "utils/helpers/reduceObject";
import { toast } from "utils/hooks/useToast";
import * as Yup from "yup";
import AccountMediaSection from "./AccountMediaSection";
function UserAccountInformation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authData = useSelector((state) => state.auth?.authData);

  useEffect(() => {
    dispatch(getAuthDetails());
  }, [dispatch]);

  const initialValues = useMemo(
    () => ({
      cnic_no: authData?.cnic_no ?? "",
      bankAccountTitle: authData?.bankAccountTitle ?? "",
      bankAccountNumber: authData?.bankAccountNumber ?? "",
      bankName: authData?.bankName ?? "",
      cnicFront: authData?.cnicFront ?? "",
      cnicBack: authData?.cnicBack ?? "",
    }),
    [authData]
  );

  const handleSubmit = useCallback(
    (values) => {
      const data = {
        cnic_no: values?.cnic_no,
        bankAccountTitle: values?.bankAccountTitle,
        bankAccountNumber: values?.bankAccountNumber,
        bankName: values?.bankName,
        cnicFront: values?.cnicFront,
        cnicBack: values?.cnicBack,
      };

      if (authData.id) {
        const formValues = { ...data };
        const initialValues = { ...authData };
        const updatedValues = reduceObject(formValues, initialValues);

        if (Object.keys(updatedValues).length === 0) {
          return navigate("/settings");
        } else {
          dispatch(updateAuthDetail({ ...updatedValues }))
            .unwrap()
            .then(() => {
              toast.success("Updated Successfully");
              dispatch(getAuthDetails());
            })
            .catch(() => {
              toast.error("Failed to update");
            });
        }
      } else {
        dispatch(addAuthDetail({ ...data }))
          .unwrap()
          .then((response) => {
            if (response?.isSuccess === true) {
              navigate(`/settings`);
              toast.success("Uploaded Successfully");
              dispatch(getAuthDetails());
            } else {
              toast.error(response?.message);
            }
          })
          .catch((error) => {
            toast.error(error.message);
          });
      }
    },
    [authData, dispatch, navigate]
  );

  return (
    <Formik
      enableReinitialize={true}
      validateOnBlur={true}
      validateOnChange={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
              <Stack direction="row" gap={3} p={{ xs: 1, md: 1.5 }}>
                <Grid container spacing={3}>
                  <Grid item xs={6} lg={6}>
                    <Stack direction="column" gap={1}>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontSize="1.25rem"
                        color="text.secondary"
                      >
                        CNIC/CR Number
                      </Typography>
                      <StyledTextField
                        fullWidth
                        type="text"
                        {...formik.getFieldProps("cnic_no")}
                        error={Boolean(
                          formik.touched.cnic_no && formik.errors.cnic_no
                        )}
                        helperText={
                          formik.touched.cnic_no && !!formik.errors.cnic_no
                            ? formik.errors.cnic_no
                            : "Enter a descriptive name of the user here"
                        }
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Stack direction="column" gap={1}>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontSize="1.25rem"
                        color="text.secondary"
                      >
                        Bank Account Title
                      </Typography>
                      <StyledTextField
                        fullWidth
                        type="phone"
                        {...formik.getFieldProps("bankAccountTitle")}
                        error={Boolean(
                          formik.touched.bankAccountTitle &&
                            formik.errors.bankAccountTitle
                        )}
                        helperText={
                          formik.touched.bankAccountTitle &&
                          !!formik.errors.bankAccountTitle
                            ? formik.errors.bankAccountTitle
                            : "Enter the bank account title here"
                        }
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Stack direction="column" gap={1}>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontSize="1.25rem"
                        color="text.secondary"
                      >
                        Bank Account Number
                      </Typography>
                      <StyledTextField
                        fullWidth
                        type="text"
                        {...formik.getFieldProps("bankAccountNumber")}
                        error={Boolean(
                          formik.touched.bankAccountNumber &&
                            formik.errors.bankAccountNumber
                        )}
                        helperText={
                          formik.touched.bankAccountNumber &&
                          !!formik.errors.bankAccountNumber
                            ? formik.errors.bankAccountNumber
                            : "Enter the valid bank account number here"
                        }
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Stack direction="column" gap={1}>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontSize="1.25rem"
                        color="text.secondary"
                      >
                        Bank Name
                      </Typography>
                      <StyledTextField
                        fullWidth
                        type="text"
                        {...formik.getFieldProps("bankName")}
                        error={Boolean(
                          formik.touched.bankName && formik.errors.bankName
                        )}
                        helperText={
                          formik.touched.bankName && !!formik.errors.bankName
                            ? formik.errors.bankName
                            : "Enter the valid bank name here"
                        }
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <AccountMediaSection formik={formik} />
                  </Grid>
                </Grid>
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack direction="row" gap={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={
                    formik.isSubmitting || !formik.isValid || !formik.dirty
                  }
                  onClick={formik.handleSubmit}
                  disableElevation
                  sx={{
                    fontWeight: "bold",
                    minWidth: { md: 100, xl: 250 },
                    height: { xs: 50, xl: 55 },
                  }}
                >
                  Submit
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={formik.resetForm}
                  sx={{
                    fontWeight: "bold",
                    minWidth: { md: 100, lg: 175, xl: 250 },
                    height: { xs: 50, xl: 55 },
                  }}
                >
                  Reset
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default memo(UserAccountInformation);

const validationSchema = Yup.object().shape({
  bankAccountNumber: Yup.string().trim().required("Required"),
  bankAccountTitle: Yup.string().trim().required("Required"),
  bankName: Yup.string().trim().required("Required"),
  cnic_no: Yup.string().trim().required("Required"),
  cnicFront: Yup.string().required("Required"),
  cnicBack: Yup.string().required("Required"),
});
