import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DataGrid } from "@mui/x-data-grid/DataGrid";
import propTypes from "prop-types";
import { memo, useCallback, useMemo } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import useQueryParams from "utils/hooks/useQueryParams";

const NoRecordsOverlay = memo(() => (
  <Stack height="100%" alignItems="center" justifyContent="center">
    No record found
  </Stack>
));

NoRecordsOverlay.displayName = "NoRecordsOverlay";

function StyledDataGrid({
  rows,
  columns,
  loading,
  totalPages,
  customRowId,
  onSelectionModelChange,
  checkboxSelection = true,
  ...props
}) {
  const params = useQueryParams();
  const navigate = useNavigate();
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const currentPage = useMemo(() => parseInt(params.page || 1), [params.page]);

  const handlePagination = useCallback(
    (_, newPage) => {
      window.scrollTo(0, 0);
      const updatedParams = {
        ...params,
        page: newPage,
        limit: params?.limit ?? 20,
      };

      navigate({
        search: createSearchParams(updatedParams).toString(),
      });
    },
    [navigate, params]
  );

  const CustomPagination = useCallback(
    () => (
      <Pagination
        variant="outlined"
        color="primary"
        sx={{ marginY: 4 }}
        size={isTablet ? "small" : "large"}
        count={totalPages}
        page={currentPage}
        disabled={totalPages === 1}
        onChange={handlePagination}
      />
    ),
    [currentPage, isTablet, totalPages, handlePagination]
  );

  const getRowId = useCallback(
    (row) => (customRowId ? row[customRowId] : row?.id),
    [customRowId]
  );

  const getRowSpacing = useCallback(
    (params) => ({
      top: params.isFirstVisible ? 0 : 6,
      bottom: params.isLastVisible ? 0 : 6,
      padding: "10px",
    }),
    []
  );

  const components = useMemo(
    () => ({
      Pagination: CustomPagination,
      NoRowsOverlay: NoRecordsOverlay,
    }),
    [CustomPagination]
  );

  return (
    <Box sx={containerStyles}>
      <DataGrid
        rows={rows}
        columns={columns}
        loading={loading}
        onSelectionModelChange={onSelectionModelChange}
        checkboxSelection={checkboxSelection}
        pagination
        autoHeight
        disableSelectionOnClick
        disableColumnMenu
        rowsPerPageOptions={[10, 15, 20]}
        components={components}
        isCellEditable={false}
        getRowHeight={() => "auto"}
        getRowSpacing={getRowSpacing}
        getRowId={getRowId}
        sx={gridStyles}
        {...props}
      />
    </Box>
  );
}

export default memo(StyledDataGrid);

StyledDataGrid.propTypes = {
  rows: propTypes.oneOfType([propTypes.object, propTypes.array]).isRequired,
  columns: propTypes.oneOfType([propTypes.object, propTypes.array]).isRequired,
  loading: propTypes.bool,
  totalPages: propTypes.number,
  handlePagination: propTypes.func,
  customRowId: propTypes.string,
  onSelectionModelChange: propTypes.func,
  checkboxSelection: propTypes.bool,
  transactionGrid: propTypes.bool,
};

const containerStyles = {
  width: "100%",
  "& .MuiDataGrid-root": { border: "none", fontSize: "1rem" },
  "& .MuiPaginationItem-root.Mui-selected": {
    border: "none",
  },
  "& .MuiPaginationItem-outlinedPrimary": {
    border: "none",
  },
  "& .MuiPaginationItem-previousNext": {
    backgroundColor: "#078ecf1c",
  },
  "& .MuiDataGrid-cell": {
    color: "primary",
    borderBottom: "0px !important",
  },
  "& .MuiDataGrid-row": {
    backgroundColor: "#fff",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  "& .MuiDataGrid-row:hover": {
    backgroundColor: "#fff !important",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  },
  "& .MuiDataGrid-columnHeaders": {
    borderBottom: "0px !important",
  },
  "& .MuiDataGrid-columnHeader:focus": {
    outline: "none !important",
  },
  "& .MuiDataGrid-columnSeparator": {
    visibility: "hidden",
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "none !important",
  },
};
const gridStyles = {
  "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
    {
      outline: "none",
    },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontSize: "1.25rem",
  },
};
