import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import CardTitle from "components/CardTitle";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { getFunnelChartData } from "store/slices/dashboardSlice";
import { PRIMARY, PRIMARY_LIGHT } from "styles/colors";
import useQueryParams from "utils/hooks/useQueryParams";

const ANALYTICS = [
  {
    label: "Sessions Started",
    value: "session_start",
  },
  {
    label: "Product Page Views",
    value: "product_page_view",
  },
  {
    label: "Add To Cart",
    value: "add_to_cart",
  },
  {
    label: "Initiate Checkout",
    value: "InitiateCheckout",
  },
  {
    label: "Purchase",
    value: "purchase",
  },
];

const colors = [PRIMARY, "#82ca9d", "#ffc658", "#d884d8", PRIMARY_LIGHT];

const formatPercentage = (value) => {
  const formatted = (value * 100).toFixed(2);
  // Remove decimal places if they're all zeros
  return formatted.endsWith(".00") ? parseInt(formatted) : formatted;
};

const SalesFunnelBarChart = () => {
  const dispatch = useDispatch();
  const params = useQueryParams();
  const isOpen = useSelector((state) => state.UI.isSidebarOpen);
  const isXL = useMediaQuery((theme) => theme.breakpoints.up("xl"));
  const { loading, data } = useSelector((state) => state.dashboard.funnelChart);

  const fontSize = isXL ? (isOpen ? "0.851rem" : "0.875rem") : "0.8rem";

  const funnelData = ANALYTICS.map(({ label, value: key }, index) => {
    const value = parseInt(data?.[key] ?? 0, 10);
    const baseValue = parseInt(data?.[ANALYTICS[0]?.value] ?? 0, 10);
    const percentage = index === 0 ? null : formatPercentage(value / baseValue);

    return {
      name: label,
      value,
      percentage,
    };
  });

  useEffect(() => {
    dispatch(
      getFunnelChartData({
        ...(params?.from && { from: params?.from }),
        ...(params?.to && { to: params?.to }),
        events: ANALYTICS.map((item) => item.value),
      })
    );
  }, [dispatch, params?.from, params?.to]);

  return (
    <Card
      sx={{
        p: 2,
        height: "100%",
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={3}
        height="100%"
        minHeight={450}
      >
        <CardTitle title="Sales Funnel" />
        {loading ? (
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : Object.keys(data)?.length ? (
          <Box flexGrow={1}>
            <ResponsiveContainer width="100%">
              <BarChart
                data={funnelData}
                layout="vertical"
                margin={{
                  left: isXL ? 20 : 5,
                  right: 30,
                  top: 5,
                  bottom: 5,
                }}
              >
                <XAxis
                  type="number"
                  tick={{
                    fontSize,
                    fill: "#000000",
                  }}
                  axisLine={{ stroke: "#000000" }}
                  tickLine={{ stroke: "#000000" }}
                />
                <YAxis
                  type="category"
                  dataKey="name"
                  tick={{ fontSize, fill: "#000000" }}
                  axisLine={{ stroke: "#000000" }}
                  tickLine={{ stroke: "#000000" }}
                />
                <Tooltip content={<CustomTooltip fontSize={fontSize} />} />
                <Bar dataKey="value" fill={PRIMARY} barSize={30}>
                  {funnelData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index]} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Box>
        ) : null}
        {!loading && !Object.keys(data)?.length && (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Typography
              variant="body2"
              textAlign="center"
              color="text.disabled"
            >
              No data found
            </Typography>
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default SalesFunnelBarChart;

const CustomTooltip = ({ active, payload, fontSize }) => {
  if (active && payload && payload.length) {
    const { name, value, percentage } = payload[0].payload;
    return (
      <Box
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.95)",
          padding: "12px",
          border: "1px solid #d0d0d0",
          borderRadius: "4px",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        }}
      >
        <Typography sx={{ fontSize }} color="primary.main" fontWeight={600}>
          {name}
        </Typography>
        <Typography sx={{ fontSize }}>
          Value: {value.toLocaleString()}
        </Typography>
        {percentage !== null && (
          <Typography sx={{ fontSize }}>
            Conversion Rate: {percentage}%
          </Typography>
        )}
      </Box>
    );
  }
  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.any,
  fontSize: PropTypes.string,
};
