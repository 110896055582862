export const calculatedPrice = (price, pricingSummary) => {
  const productPrice = parseFloat(price) || 0;
  const vat = parseFloat(pricingSummary?.vat) || 0;
  const shipment =
    parseFloat(pricingSummary?.shipment || pricingSummary?.shippment) || 0;
  const forex = parseFloat(pricingSummary?.forex) || 0;

  const premiumPercentage = parseFloat(pricingSummary?.premium) ?? 0;

  const result =
    productPrice +
    (productPrice + (premiumPercentage / 100) * productPrice) *
      (vat / (100 - vat)) +
    shipment +
    (forex / 100) * productPrice +
    (premiumPercentage / 100) * productPrice;

  return Number.isFinite(result) ? result.toFixed(2) : "0.00";
};
