import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import MovingIcon from "@mui/icons-material/Moving";
import PaymentsIcon from "@mui/icons-material/Payments";
import PeopleIcon from "@mui/icons-material/People";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import PersonIcon from "@mui/icons-material/Person";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import TourIcon from "@mui/icons-material/Tour";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import WebIcon from "@mui/icons-material/Web";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import InsightTabPanel from "components/Insights/InsightTabPanel";
import OrdersInsights from "components/Insights/OrdersInsights";
import RevenueInsights from "components/Insights/RevenueInsights";
import UsersInsights from "components/Insights/UsersInsights";
import DateRange from "components/Pickers/DateRange";
import dayjs from "dayjs";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import { getInsights, resetInsightsState } from "store/slices/insightsSlice";
import useQueryParams from "utils/hooks/useQueryParams";

const USERS_INSIGHTS = [
  {
    label: "New Users",
    value: "newUsers",
    type: "metrics",
    icon: <PersonIcon />,
  },
  {
    label: "Active Users",
    value: "activeUsers",
    type: "metrics",
    icon: <PeopleIcon />,
  },
  {
    label: "Total Users",
    value: "totalUsers",
    type: "metrics",
    icon: <PeopleOutlineIcon />,
  },
  {
    label: "Sessions Per User",
    value: "sessionsPerUser",
    type: "metrics",
    icon: <TourIcon />,
  },
  {
    label: "Sessions Started",
    value: "session_start",
    type: "events",
    icon: <TroubleshootIcon />,
  },
  {
    label: "Average Session Duration",
    value: "averageSessionDuration",
    type: "metrics",
    icon: <HistoryToggleOffIcon />,
  },
  {
    label: "Screen Page Views",
    value: "screenPageViews",
    type: "metrics",
    icon: <WebIcon />,
  },
  {
    label: "Bounce Rate",
    value: "bounceRate",
    type: "metrics",
    icon: <MovingIcon />,
  },
];

const ORDERS_INSIGHTS = [
  {
    label: "Add To Wishlist",
    value: "add_to_wishlist",
    type: "events",
    icon: <FavoriteBorderIcon />,
  },
  {
    label: "Add To Cart",
    value: "add_to_cart",
    type: "events",
    icon: <AddShoppingCartIcon />,
  },
  {
    label: "Checkouts",
    value: "InitiateCheckout",
    type: "events",
    icon: <ShoppingCartCheckoutIcon />,
  },
  {
    label: "Cart To View Rate",
    value: "cartToViewRate",
    type: "metrics",
    icon: <ProductionQuantityLimitsIcon />,
  },
];

const REVENUE_INSIGHTS = [
  {
    label: "Average Purchase Revenue",
    value: "averagePurchaseRevenue",
    type: "metrics",
    icon: <ShoppingCartIcon />,
  },
  {
    label: "Total Ad Revenue",
    value: "totalAdRevenue",
    type: "metrics",
    icon: <LocalAtmIcon />,
  },
  {
    label: "Total Revenue",
    value: "totalRevenue",
    type: "metrics",
    icon: <PaymentsIcon />,
  },
  {
    label: "Tax Amount",
    value: "taxAmount",
    type: "metrics",
    icon: <PointOfSaleIcon />,
  },
];

function Insights() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useQueryParams();
  const { loading, insights } = useSelector((state) => state.insights);
  const [tabValue, setTabValue] = useState(0);

  const tabProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleTabChange = (_, newValue) => {
    setTabValue(newValue);
  };

  const handleDateChange = useCallback(
    (range) => {
      navigate({
        search: createSearchParams({
          ...params,
          from: dayjs(range.startDate).format("YYYY-MM-DD"),
          to: dayjs(range.endDate).format("YYYY-MM-DD"),
        }).toString(),
      });
    },
    [navigate, params]
  );

  useEffect(() => {
    const events = [];
    const metrics = [];

    USERS_INSIGHTS.forEach(({ value, type }) => {
      if (type === "events") events.push(value);
      else if (type === "metrics") metrics.push({ name: value });
    });
    ORDERS_INSIGHTS.forEach(({ value, type }) => {
      if (type === "events") events.push(value);
      else if (type === "metrics") metrics.push({ name: value });
    });
    REVENUE_INSIGHTS.forEach(({ value, type }) => {
      if (type === "events") events.push(value);
      else if (type === "metrics") metrics.push({ name: value });
    });

    const data = {
      ...(params?.from && { from: params.from }),
      ...(params?.to && { to: params.to }),
      events,
      metrics,
    };

    dispatch(getInsights(data));
  }, [dispatch, params.from, params.to]);

  useEffect(() => () => () => dispatch(resetInsightsState()), [dispatch]);

  return (
    <Box py={4} display="flex" flexDirection="column" gap={2}>
      <Box>
        <CustomBreadcrumbs />
      </Box>
      <Typography
        variant="h1"
        color="primary"
        fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.5rem" }}
        fontWeight={600}
      >
        Insights
      </Typography>
      {loading ? (
        <Box
          pt="10vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : Object.keys(insights).length ? (
        <>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Tabs
                indicatorColor="none"
                sx={{
                  "& .MuiTab-root.Mui-selected": {
                    backgroundColor: "#068ECF",
                    color: "#fff",
                    borderRadius: 1,
                    fontSize: "1.2rem",
                  },
                  "& .MuiTab-root": {
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                  },
                }}
                value={tabValue}
                onChange={handleTabChange}
              >
                <Tab label="Users" {...tabProps(0)} />
                <Tab label="Orders" {...tabProps(1)} />
                <Tab label="Revenues" {...tabProps(2)} />
              </Tabs>
            </Box>
            <Box>
              <DateRange onChange={handleDateChange} />
            </Box>
          </Box>
          <InsightTabPanel value={tabValue} index={0}>
            <UsersInsights insights={USERS_INSIGHTS} />
          </InsightTabPanel>
          <InsightTabPanel value={tabValue} index={1}>
            <OrdersInsights insights={ORDERS_INSIGHTS} />
          </InsightTabPanel>
          <InsightTabPanel value={tabValue} index={2}>
            <RevenueInsights insights={REVENUE_INSIGHTS} />
          </InsightTabPanel>
        </>
      ) : (
        <Typography
          variant="h6"
          component="h2"
          color="text.disabled"
          fontWeight={600}
          textAlign="center"
          pt={5}
        >
          No data found
        </Typography>
      )}
    </Box>
  );
}

export default Insights;
