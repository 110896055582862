import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FinanceTabs from "components/Finance/FinanceTabs";
import CustomBreadcrumbs from "layout/BreadCrumbs";

function Finance() {
  return (
    <Box py={2}>
      <Box paddingTop={2} paddingBottom={2} px={1}>
        <CustomBreadcrumbs />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography
          variant="h1"
          color="primary"
          fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.6rem" }}
          fontWeight={600}
          paddingTop={1}
          paddingBottom={1}
        >
          Finance
        </Typography>
      </Box>
      <FinanceTabs />
    </Box>
  );
}

export default Finance;
