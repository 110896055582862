import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import _ from "lodash";
import PropTypes from "prop-types";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { FILTERS_BORDER_COLOR, SEARCH_ICON_COLOR } from "styles/colors";
import useQueryParams from "utils/hooks/useQueryParams";

function OrdersSearch({ searchQueryParam }) {
  const params = useQueryParams();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const _params = useMemo(() => {
    let _params = { ...params };
    if (searchValue.length === 16) {
      _params[searchQueryParam] = searchValue;
    } else {
      _params = _.omit(params, searchQueryParam);
    }
    return _params;
  }, [params, searchQueryParam, searchValue]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate({
        search: createSearchParams(_params).toString(),
      });
    }, 100);
    return () => clearTimeout(timeout);
  }, [_params, navigate, params]);

  const handleChange = useCallback((value) => {
    return setSearchValue(value.trim());
  }, []);

  return (
    <Box sx={containerStyles}>
      <TextField
        id="outlined-search"
        placeholder="Search By Order ID"
        type="search"
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ color: SEARCH_ICON_COLOR }} />
            </InputAdornment>
          ),
        }}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
      />
    </Box>
  );
}

export default memo(OrdersSearch);

OrdersSearch.propTypes = {
  searchQueryParam: PropTypes.string.isRequired,
};

const containerStyles = {
  py: 2,
  textAlign: "end",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: FILTERS_BORDER_COLOR,
      borderRadius: "20px",
    },
    "&:hover": {
      "& fieldset": {
        borderColor: FILTERS_BORDER_COLOR,
      },
    },
  },
  "&:hover": {
    "& fieldset": {
      borderColor: FILTERS_BORDER_COLOR,
    },
  },
};
