import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllCities, resetState } from "store/slices/filtersSlice";
import { FILTER_TEXT_COLOR, FILTERS_BACKGROUND_COLOR } from "styles/colors";
import useQueryParams from "utils/hooks/useQueryParams";

const COUNTRIES = [
  { code: "SA", value: "ksa" },
  { code: "CN", value: "china" },
  { code: "PK", value: "pak" },
];

function FilterByCity({ isStorePage = false }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useQueryParams();
  const loading = useSelector((state) => state.filters.loading);
  const provinces = useSelector(
    (state) => state?.filters?.cities?.provinces || []
  );
  const cities = provinces?.flatMap(
    (province) => province?.cities?.map((cityObj) => cityObj.city) || []
  );

  useEffect(() => {
    const country = params?.country;
    if (country) {
      const selectedCountry = COUNTRIES.find(
        (_country) => _country.value === country
      );
      if (selectedCountry) {
        dispatch(getAllCities(selectedCountry?.code));
      }
    }
    return () => dispatch(resetState());
  }, [dispatch, params?.country]);

  return (
    <Autocomplete
      sx={{
        minWidth: 170,
        maxWidth: 180,
      }}
      size="small"
      getOptionLabel={(city) => city}
      isOptionEqualToValue={(option, value) => option.city === value.city}
      noOptionsText={
        isStorePage ? "Please select a country first" : "No Options Available"
      }
      options={cities}
      onChange={(_, newValue) => {
        navigate(newValue ? `/stores?city=${newValue}` : `/stores`);
      }}
      loading={params?.country && loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select City"
          fullWidth
          sx={{
            "& .MuiInputLabel-root": {
              fontSize: "1.063rem",
              height: 44,
            },
            "& .MuiInputBase-root": {
              height: 44,
              backgroundColor: FILTERS_BACKGROUND_COLOR,
              cursor: "pointer",
            },
            input: {
              color: FILTER_TEXT_COLOR,
              "&::placeholder": {
                opacity: 1,
              },
            },
          }}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  );
}

export default memo(FilterByCity);

FilterByCity.propTypes = {
  isStorePage: PropTypes.bool,
};
