import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import OrderStatusCard from "./OrderStatusCards";

const LogisticsStatusCards = () => {
  const stats = useSelector((state) => state.reports.stats);

  const cards = [
    {
      title: "Total Orders",
      countValue: stats?.total ?? 0,
      percentValue: null,
    },
    {
      title: "Delivery Success Ratio",
      countValue: stats?.delivered ?? 0,
      percentValue: stats?.total
        ? ((stats?.delivered / stats?.total) * 100).toFixed(1)
        : 0,
    },
    {
      title: "In Process",
      countValue:
        (
          stats?.pending +
          stats?.new +
          stats?.confirmed +
          stats?.ready +
          stats?.shipped
        ).toFixed(1) ?? 0,
      percentValue: stats?.total
        ? Math.floor(
            ((stats?.pending +
              stats?.new +
              stats?.confirmed +
              stats?.ready +
              stats?.shipped) /
              stats?.total) *
              100
          )
        : 0,
    },
    {
      title: "Refunded Orders",
      countValue: stats?.refunded ?? 0,
      percentValue: stats?.total
        ? ((stats?.refunded / stats?.total) * 100).toFixed(1)
        : 0,
    },
    {
      title: "Returned Orders",
      countValue: stats?.returned ?? 0,
      percentValue: stats?.total
        ? ((stats?.returned / stats?.total) * 100).toFixed(1)
        : 0,
    },
    {
      title: "Rejected Orders",
      countValue: stats?.cancel ?? 0,
      percentValue: stats?.total
        ? ((stats?.cancel / stats?.total) * 100).toFixed(1)
        : 0,
    },
  ];

  return (
    <Box my={3}>
      <Grid container spacing={2}>
        {cards.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <OrderStatusCard
              title={card.title}
              countValue={card.countValue}
              percentValue={card.percentValue}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default LogisticsStatusCards;
