import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import placeholderImg from "assets/images/imagePlaceholder.png";
import ConfirmDelete from "components/Dialog/ConfirmDelete";
import StyledDataGrid from "components/StyledGrid";
import dayjs from "dayjs";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteCollection, getCollection } from "store/slices/collectionSlice";
import {
  setConfirmDeletionDialogOpen,
  setDeleteId,
} from "store/slices/uiSlice";
import statusBadges from "utils/helpers/statusBadge";
import useQueryParams from "utils/hooks/useQueryParams";
import PlusIcon from "../../assets/icons/PlusIcon.png";
import WritingIcon from "../../assets/icons/WritingIcon.svg";
import deleteIcon from "../../assets/icons/deleteIcon.svg";

function CollectionDataGrid() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useQueryParams();
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLaptop = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isLed = useMediaQuery((theme) => theme.breakpoints.up("xl"));

  const { results, loading, totalPages } = useSelector(
    (state) => state.collection
  );

  const fetchData = useCallback(() => {
    dispatch(getCollection(params));
  }, [dispatch, params]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      fetchData();
    }, 500);
    return () => clearTimeout(timeOut);
  }, [dispatch, fetchData]);

  const handleClickOpen = useCallback(
    (collectionId) => {
      dispatch(setConfirmDeletionDialogOpen());
      dispatch(setDeleteId(collectionId));
    },
    [dispatch]
  );
  const columns = [
    {
      field: "serialNumber",
      headerName: "Sr",
      minWidth: 40,
      flex: 0.2,
      filterable: false,
    },
    {
      field: "image",
      headerName: "Image",
      headerAlign: "center",
      align: "center",
      filterable: false,
      disableColumnFilter: true,
      editable: false,
      sortable: false,
      minWidth: 100,
      flex: 0.8,
      renderCell: (params) => (
        <Box>
          <img
            src={params?.row?.image ? params?.row?.image : placeholderImg}
            height={
              isTablet ? "70px" : isLaptop ? "80px" : isLed ? "85px" : "113px"
            }
            width={
              isTablet ? "70px" : isLaptop ? "80px" : isLed ? "85px" : "113px"
            }
            style={{
              borderRadius: "10px",
              marginTop: 6,
              objectFit: "contain",
            }}
            alt={params?.row?.name}
          />
        </Box>
      ),
    },
    {
      field: "name",
      headerName: "Name/Details",
      filterable: false,
      disableColumnFilter: true,
      editable: false,
      sortable: false,
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Typography
            sx={{
              textTransform: "capitalize",
              wordBreak: "break-all",
            }}
          >
            {params?.row?.name}
          </Typography>
        </Box>
      ),
    },
    {
      field: "expireDate",
      headerName: "Expiry Date",
      editable: false,
      minWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Typography
            sx={{
              textTransform: "capitalize",
            }}
          >
            {dayjs(params?.row?.expireDate).format("DD/MM/YYYY")}
          </Typography>
        </Box>
      ),
    },
    {
      field: "active",
      headerName: "Status",
      filterable: false,
      disableColumnFilter: true,
      editable: false,
      sortable: false,
      minWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <Chip
          sx={{
            backgroundColor: statusBadges(
              params?.row?.active ? "active" : "inactive"
            ).bgColor,
            color: statusBadges(params?.row?.active ? "active" : "inactive")
              .color,
            borderRadius: "5px",
            height: "26px",
            textTransform: "capitalize",
            minWidth: "88px",
            fontSize: "12px",
          }}
          variant="contained"
          label={params?.row?.active ? "Active" : "In active"}
        />
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      filterable: false,
      disableColumnFilter: true,
      editable: false,
      sortable: false,
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" sx={actionStyles}>
          <Tooltip title="Update">
            <Button
              variant="text"
              className="button-styles"
              onClick={() => navigate(`update/${params.id}`)}
            >
              <img src={WritingIcon} alt="edit" className="icon-size" />
            </Button>
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              variant="text"
              sx={{
                minWidth: "20px",
              }}
              onClick={() => handleClickOpen(params?.id)}
            >
              <img src={deleteIcon} alt="delete" className="icon-size" />
            </Button>
          </Tooltip>
          <Tooltip title="Add Collection Products">
            <Button
              variant="text"
              className="button-styles"
              sx={{
                borderRadius: "50%",
              }}
              onClick={() => navigate(`add/${params.id}`)}
            >
              <img src={PlusIcon} alt="view" className="icon-size" />
            </Button>
          </Tooltip>
        </Box>
      ),
    },
  ];
  return (
    <>
      <StyledDataGrid
        rows={results ?? []}
        columns={columns}
        loading={loading}
        totalPages={totalPages}
      />
      <ConfirmDelete deleteAction={deleteCollection} />
    </>
  );
}

export default CollectionDataGrid;

const actionStyles = {
  "& .button-styles": { minWidth: "20px", marginLeft: "4px" },
  "& .icon-size": { height: "25px", width: "25px" },
};
