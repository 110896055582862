import { Card, CardContent, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const OrderStatusBarChart = () => {
  const stats = useSelector((state) => state.reports.stats);

  const keysToMap = [
    "new",
    "completed",
    "confirmed",
    "ready",
    "cancel",
    "delivered",
  ];

  const chartData = Object.entries(stats)
    .filter(([key]) => keysToMap.includes(key))
    .map(([key, value]) => ({
      name: key.charAt(0).toUpperCase() + key.slice(1),
      value: value
        ? parseFloat(((value / stats.total) * 100).toFixed(1))
        : null,
    }));

  return (
    <Card sx={{ maxWidth: 1000, margin: "auto", padding: 2 }}>
      <CardContent>
        <Typography variant="h6" mb={5}>
          Orders Status Bar Chart
        </Typography>
        <ResponsiveContainer width="100%" height={415}>
          <BarChart
            data={chartData}
            margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
          >
            <CartesianGrid strokeDasharray="4 4" />
            <XAxis dataKey="name" />
            <YAxis unit="%" />
            <Tooltip />
            <Bar dataKey="value" fill="#36A2EB" name="Percentage" />
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default OrderStatusBarChart;
