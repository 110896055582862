import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import ManualTransactionForm from "components/ManualTransactions/ManualTransactionForm";
import DateFilter from "components/Pickers/DateRange";
import ReportDataGrid from "components/Vmt/ReportDataGrid";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import { getAllOrders } from "store/slices/ordersSlice";
import {
  getAllTransactions,
  getCancelOrdersCsv,
  setFinanceTab,
} from "store/slices/reportSlice";
import { FILTERS_BACKGROUND_COLOR } from "styles/colors";
import useQueryParams from "utils/hooks/useQueryParams";
import OrdersDataGrid from "./OrdersDataGrid";

export default function FinanceTabs() {
  const dispatch = useDispatch();
  const params = useQueryParams();
  const navigate = useNavigate();
  const financeTab = useSelector((state) => state.reports.financeTab);
  const orderLoading = useSelector((state) => state.orders.loading);
  const transactionLoading = useSelector((state) => state.reports.loading);

  const handleChange = useCallback(
    (_, newValue) => {
      dispatch(setFinanceTab(newValue));
    },
    [dispatch]
  );

  const handleCSVClick = () => {
    financeTab === "TRANSACTIONS"
      ? dispatch(getAllTransactions(params))
      : financeTab === "CANCELORDERS"
      ? dispatch(getCancelOrdersCsv())
      : dispatch(getAllOrders());
  };

  const handleDateChange = useCallback(
    (range) => {
      const _params = {
        ...params,
        from: range.startDate.toDateString(),
        to: range.endDate.toDateString(),
        page: 1,
        limit: 20,
      };
      navigate({
        search: createSearchParams(_params).toString(),
      });
    },
    [navigate, params]
  );

  useEffect(() => {
    navigate({
      search: createSearchParams({}).toString(),
    });
  }, [navigate, financeTab]);

  return (
    <Box>
      <Box py={1} sx={containerStyles}>
        <Box
          sx={{
            backgroundColor: FILTERS_BACKGROUND_COLOR,
            marginBottom: { xs: 2, md: 0 },
          }}
        >
          <Box>
            <Tabs
              value={financeTab}
              onChange={handleChange}
              aria-label="return status filters"
            >
              <Tab label="Transactions" value="TRANSACTIONS" />
              <Tab label="Orders" value="ORDERS" />
              <Tab label="Cancel Orders" value="CANCELORDERS" />
              <Tab label="Manual Transactions" value="MANUALTRANSACTIONS" />
            </Tabs>
          </Box>
        </Box>
      </Box>
      {financeTab === "MANUALTRANSACTIONS" ? null : (
        <Box
          marginY={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <DateFilter onChange={handleDateChange} />
          <Button
            variant="contained"
            sx={{ textTransform: "none" }}
            disabled={
              financeTab === "TRANSACTIONS" || financeTab === "CANCELORDERS"
                ? transactionLoading
                : orderLoading
            }
            endIcon={<CloudDownloadIcon />}
            onClick={handleCSVClick}
          >
            Export to CSV
          </Button>
        </Box>
      )}
      <Box mt={2}>
        {financeTab === "MANUALTRANSACTIONS" && <ManualTransactionForm />}
        {financeTab === "TRANSACTIONS" && <ReportDataGrid />}
        {(financeTab === "ORDERS" || financeTab === "CANCELORDERS") && (
          <OrdersDataGrid value={financeTab} />
        )}
      </Box>
    </Box>
  );
}
const containerStyles = {
  display: { xs: "grid", md: "flex" },
  justifyContent: "space-between",
  alignItems: "baseline",
  "& .styled-button": {
    borderRadius: "1px",
    padding: "10px",
    fontSize: {
      md: "1rem",
      xs: "0.7rem",
    },
  },
  "& .MuiOutlinedInput-root": { borderRadius: "20px" },
  "& .MuiTab-root": { textTransform: "capitalize" },
};
