import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { styled, Tooltip, tooltipClasses } from "@mui/material";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import ImagePlaceholder from "assets/icons/ProductAddImagePlaceholder.svg";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import PropTypes from "prop-types";
import { memo, useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setImageValidationError,
  setIsImageValidationDialogOpen,
} from "store/slices/uiSlice";
import validateImageAspectRatio from "utils/helpers/validateImageAspectRatio";
function CategoryMediaSection({ formik }) {
  const dispatch = useDispatch();
  const collectionImageInputRef = useRef(null);
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLarge = useMediaQuery((theme) => theme.breakpoints.down("xl"));
  const [collectionImage, setCollectionImage] = useState(
    formik.values.collectionImage ?? []
  );
  const [open, setOpen] = useState(false);
  const isLaptop = useMediaQuery("(min-width:1024px)");

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // const handleToggleVideo = () => {
  //   setOpenVideo(!openVideo);
  // };

  // const handleCloseVideo = () => {
  //   setOpenVideo(false);
  // };

  const collectionImageHandler = useCallback(
    (e) => {
      const image = e.target.files[0];
      if (image) {
        // function params
        // (image, height, width, fixedDimension, ratioValidation, aspectRatio, aspectRatioString)
        validateImageAspectRatio(image, 1200, 400, true, true, 3 / 1, "3 / 1")
          .then((img) => {
            collectionImageInputRef.current.value = "";
            formik.setFieldValue("collectionImage", img);
            setCollectionImage(URL.createObjectURL(img));
          })
          .catch((error) => {
            collectionImageInputRef.current.value = "";
            dispatch(setIsImageValidationDialogOpen(true));
            dispatch(setImageValidationError(error?.message ?? ""));
          });
      }
    },
    [dispatch, formik]
  );

  return (
    <StyledMuiCard
      title="Media Section"
      subTitle="Upload image relevant to the collection"
    >
      <CardContent>
        <Box display="flex" flexWrap="wrap" gap={4}>
          {/* Collection Main Image */}
          <Stack direction="column" gap={2} pl={2}>
            <Box display="flex" gap={1}>
              <Typography
                variant="h3"
                fontWeight="bold"
                fontSize="1.25rem"
                color="text.secondary"
              >
                Banner Image
              </Typography>
              <LightTooltip
                open={isLaptop ? undefined : open}
                onClose={handleClose}
                disableHoverListener={!isLaptop}
                disableTouchListener={!isLaptop}
                title={
                  <Box padding={1}>
                    <Typography>
                      The image(PNG, JPEG, JPG) should have a width of 1200
                      pixels and a height of 400 pixels.
                    </Typography>
                  </Box>
                }
                placement="right-start"
              >
                <InfoOutlinedIcon
                  color="text.secondary"
                  onClick={handleToggle}
                  sx={{ cursor: "pointer" }}
                />
              </LightTooltip>
            </Box>
            {formik.values?.collectionImage ? (
              <img
                src={formik.values.collectionImage ? collectionImage : ""}
                alt="Collection Main Media"
                height={isSmall ? "150px" : isLarge ? "350px" : "400px"}
                width={"100%"}
                style={{ cursor: "pointer" }}
                onClick={() => collectionImageInputRef.current.click()}
              />
            ) : (
              <Box>
                <img
                  src={ImagePlaceholder}
                  alt="Upload"
                  height={isSmall ? "150px" : isLarge ? "350px" : "400px"}
                  width={isSmall ? "150px" : isLarge ? "350px" : "400px"}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => collectionImageInputRef.current.click()}
                />
              </Box>
            )}
            <input
              type="file"
              ref={collectionImageInputRef}
              style={{ display: "none" }}
              id="update-category-image"
              hidden
              onChange={collectionImageHandler}
              accept="image/x-png,image/png,image/jpeg,image/jpg"
            />
            {formik.errors.collectionImage && (
              <Typography color="#ef5350">
                {formik.errors.collectionImage}*
              </Typography>
            )}
          </Stack>
        </Box>
      </CardContent>
    </StyledMuiCard>
  );
}

CategoryMediaSection.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(CategoryMediaSection);

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));
