import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ReportsCards from "components/Vmt/ReportsCards";
import VmtFilters from "components/Vmt/VmtFilters";
import VmtTabs from "components/Vmt/VmtTabs";
import CustomBreadcrumbs from "layout/BreadCrumbs";

function Transactions() {
  return (
    <Box py={2}>
      <Box paddingTop={2} paddingBottom={2} px={1}>
        <CustomBreadcrumbs />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography
          variant="h1"
          color="primary"
          fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.6rem" }}
          fontWeight={600}
          paddingTop={1}
          paddingBottom={1}
        >
          Transactions
        </Typography>
      </Box>
      <VmtFilters />
      <ReportsCards />
      <VmtTabs />
    </Box>
  );
}

export default Transactions;
