import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import VoucherDescriptionSection from "components/Vouchers/VoucherForm/VoucherDescriptionSection";
import VoucherDetailSection from "components/Vouchers/VoucherForm/VoucherDetailSection";
import { Form, Formik } from "formik";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  setValidationDialogOpen,
  setValidationMessage,
} from "store/slices/uiSlice";
import {
  addVoucher,
  getVoucher,
  resetVoucherState,
  updateVoucher,
} from "store/slices/vouchersSlice";
import { getErrorMessage } from "utils/helpers/apiDataHelpers";
import isValidNumber from "utils/helpers/isValidNumber";
import parseEditorValue from "utils/helpers/parseEditorValue";
import reduceObject from "utils/helpers/reduceObject";
import { toast } from "utils/hooks/useToast";
import * as Yup from "yup";

function VoucherForm() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { voucher, loading } = useSelector((state) => state.vouchers);
  const product = useSelector((state) => state.products.product);

  const initialValues = useMemo(
    () => ({
      name: voucher?.title ?? "",
      status: "inactive",
      quantity: voucher?.quantity ?? "",
      limit: voucher?.limit ?? "",
      numOfVouchers: voucher?.numOfVouchers ?? "",
      description: voucher?.description
        ? parseEditorValue(voucher?.description)
        : "",
      discountType: voucher?.discountType ?? "amount",
      voucherType: voucher?.type ?? "coupon",
      amount: voucher?.amount ?? "",
      voucherCode: voucher?.voucher ?? "",
      startDate: voucher?.startDate ?? "",
      endDate: voucher?.endDate ?? "",
      productId: voucher?.couponTypeId ?? "",
      couponType: voucher?.couponType ?? "",
      productQuantity: product?.quantity ?? "",
    }),
    [voucher, product?.quantity]
  );

  useEffect(() => {
    if (params?.id) {
      const id = setTimeout(() => {
        dispatch(getVoucher(params.id));
      }, 500);

      return () => {
        if (id) {
          clearTimeout(id);
          dispatch(resetVoucherState());
        }
      };
    }
  }, [dispatch, params?.id]);

  const handleSubmit = useCallback(
    (values) => {
      const data = {
        title: values.name,
        quantity: values.quantity,
        limit: values.limit,
        numOfVouchers: values.numOfVouchers,
        description: values.description,
        discountType: values.discountType,
        type: values.voucherType,
        amount: values.amount,
        voucher: values.voucherCode,
        startDate: values.startDate,
        endDate: values.endDate,
      };

      if (values.productId) {
        data.couponTypeId = values.productId;
        data.couponType = values.couponType;
      }

      if (params.id) {
        let newValues = { ...data };
        let currentValues = { ...voucher };
        currentValues.description = parseEditorValue(voucher?.description);
        let updatedValues = reduceObject(newValues, currentValues);
        if (Object.keys(updatedValues).length === 0) {
          return navigate("/products/vouchers");
        } else if (
          values?.quantity > values?.productQuantity &&
          values?.voucherType === "coupon"
        ) {
          dispatch(setValidationDialogOpen(true));
          dispatch(
            setValidationMessage(
              `Quantity should be less than or equal to product quantity. Your product quantity is ${values?.productQuantity}`
            )
          );
        } else {
          updatedValues.title = data?.title;
          dispatch(updateVoucher({ ...updatedValues, id: params.id }))
            .unwrap()
            .then(() => {
              toast.success("Updated Successfully");
              return navigate("/products/vouchers");
            })
            .catch((err) => {
              toast.error(getErrorMessage(err) ?? "Failed to update");
            });
        }
      } else if (
        values?.quantity > values?.productQuantity &&
        values?.voucherType === "coupon"
      ) {
        dispatch(setValidationDialogOpen(true));
        dispatch(
          setValidationMessage(
            `Quantity should be less than or equal to product quantity. Your product quantity is ${values?.productQuantity}`
          )
        );
      } else {
        dispatch(addVoucher(data))
          .unwrap()
          .then(() => {
            toast.success("Voucher created successfully");
            navigate("/products/vouchers");
          })
          .catch((error) => {
            toast.error(getErrorMessage(error) ?? "Something went wrong");
          });
      }
    },
    [dispatch, navigate, params.id, voucher]
  );

  return (
    <Box py={2} px={1}>
      <Box paddingTop={2} paddingBottom={1} py={1}>
        <CustomBreadcrumbs />
      </Box>
      <Typography
        variant="h1"
        color="primary"
        fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.4rem" }}
        mb={1}
      >
        {params?.id ? `Update ${voucher?.title}` : "Add New Voucher"}
      </Typography>
      <Formik
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <Grid item xs={12} lg={12} mt={3} rowSpacing={1} columnSpacing={3}>
              <StyledMuiCard
                title="Voucher Information"
                subTitle="Please enter the basic information of the voucher such as name , quantity , description and number of vouchers"
              >
                <CardContent>
                  <Grid container spacing={2} p={{ xs: 1, md: 1.5 }}>
                    <Grid item xs={8} lg={8}>
                      <Box display="flex" flexDirection="column" gap={2}>
                        <Typography
                          variant="h3"
                          fontWeight="bold"
                          fontSize="1.25rem"
                          color="text.secondary"
                        >
                          Voucher Name
                        </Typography>
                        <StyledTextField
                          fullWidth
                          type="text"
                          {...formik.getFieldProps("name")}
                          error={Boolean(
                            formik.touched.name && formik.errors.name
                          )}
                          helperText={
                            formik.touched.name && !!formik.errors.name
                              ? formik.errors.name
                              : "Enter a descriptive name"
                          }
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4} lg={4}>
                      <Box display="flex" flexDirection="column" gap={2}>
                        <Typography
                          variant="h3"
                          fontWeight="bold"
                          fontSize="1.25rem"
                          color="text.secondary"
                        >
                          Status
                        </Typography>
                        <StyledTextField
                          fullWidth
                          select
                          type="text"
                          {...formik.getFieldProps("status")}
                          error={Boolean(
                            formik.touched.status && formik.errors.status
                          )}
                          helperText={
                            formik.touched.status && !!formik.errors.status
                              ? formik.errors.status
                              : "Select the status of the voucher"
                          }
                        >
                          <MenuItem value="active">Active</MenuItem>
                          <MenuItem value="inactive">Inactive</MenuItem>
                        </StyledTextField>
                      </Box>
                    </Grid>
                    <Grid item xs={4} lg={4}>
                      <Box display="flex" flexDirection="column" gap={2}>
                        <Typography
                          variant="h3"
                          fontWeight="bold"
                          fontSize="1.25rem"
                          color="text.secondary"
                        >
                          Quantity
                        </Typography>
                        <StyledTextField
                          name="quantity"
                          type="text"
                          value={formik.values.quantity}
                          onChange={(e) => {
                            isValidNumber(e.target.value) &&
                              formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          py={2}
                          error={Boolean(
                            formik.touched.quantity && formik.errors.quantity
                          )}
                          helperText={
                            formik.touched.quantity && !!formik.errors.quantity
                              ? formik.errors.quantity
                              : "Enter the quantity of the voucher here"
                          }
                          sx={{
                            "& .MuiOutlinedInput-input": {
                              lineHeight: 1,
                              fontSize: "2rem",
                              fontWeight: "bold",
                              color: "text.secondary",
                              height: "auto",
                              padding: "0.5rem 0.75rem",
                            },
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4} lg={4}>
                      <Box display="flex" flexDirection="column" gap={2}>
                        <Typography
                          variant="h3"
                          fontWeight="bold"
                          fontSize="1.25rem"
                          color="text.secondary"
                        >
                          Limit
                        </Typography>
                        <StyledTextField
                          name="limit"
                          type="text"
                          value={formik.values.limit}
                          onChange={(e) => {
                            isValidNumber(e.target.value) &&
                              formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          py={2}
                          error={Boolean(
                            formik.touched.limit && formik.errors.limit
                          )}
                          helperText={
                            formik.touched.limit && !!formik.errors.limit
                              ? formik.errors.limit
                              : "Enter a limit of the voucher here"
                          }
                          sx={{
                            "& .MuiOutlinedInput-input": {
                              lineHeight: 1,
                              fontSize: "2rem",
                              fontWeight: "bold",
                              color: "text.secondary",
                              height: "auto",
                              padding: "0.5rem 0.75rem",
                            },
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4} lg={4}>
                      <Box display="flex" flexDirection="column" gap={2}>
                        <Typography
                          variant="h3"
                          fontWeight="bold"
                          fontSize="1.25rem"
                          color="text.secondary"
                        >
                          Number of Vouchers
                        </Typography>
                        <StyledTextField
                          name="numOfVouchers"
                          type="text"
                          value={formik.values.numOfVouchers}
                          onChange={(e) => {
                            isValidNumber(e.target.value) &&
                              formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          py={2}
                          error={Boolean(
                            formik.touched.numOfVouchers &&
                              formik.errors.numOfVouchers
                          )}
                          helperText={
                            formik.touched.numOfVouchers &&
                            !!formik.errors.numOfVouchers
                              ? formik.errors.numOfVouchers
                              : "Enter the numbers of vouchers here"
                          }
                          sx={{
                            "& .MuiOutlinedInput-input": {
                              lineHeight: 1,
                              fontSize: "2rem",
                              fontWeight: "bold",
                              color: "text.secondary",
                              height: "auto",
                              padding: "0.5rem 0.75rem",
                            },
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <VoucherDescriptionSection formik={formik} />
                    </Grid>
                  </Grid>
                </CardContent>
              </StyledMuiCard>
              <VoucherDetailSection formik={formik} />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={formik.handleSubmit}
                sx={{
                  fontWeight: "bold",
                  width: 200,
                  height: 40,
                  mt: 3,
                }}
                disabled={loading}
              >
                {params?.id ? "Update Voucher" : "Save Voucher"}
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default memo(VoucherForm);
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required("Required*")
    .min(5, "Name should be at least 5 characters long")
    .max(50, "Name should not exceed 50 characters"),
  status: Yup.string().required("Required*"),
  quantity: Yup.number()
    .required("Required*")
    .integer("Must be an integer")
    .max(9999, "Quantity should not exceed 4 digits"),
  limit: Yup.number()
    .required("Required*")
    .integer("Must be an integer")
    .min(1, "Limit must be greater than 0")
    .max(9, "Only 1 digit number allowed"),
  numOfVouchers: Yup.number()
    .required("Required*")
    .integer("Must be an integer")
    .max(9999, "Number should not exceed 4 digits"),
  description: Yup.string()
    .trim()
    .required("Required*")
    .min(80, "Description must be at least 80 characters long")
    .max(2000, "Description is too long"),
  discountType: Yup.string().required("Required*"),
  voucherType: Yup.string().required("Required*"),
  amount: Yup.number()
    .required("Required*")
    .when("discountType", {
      is: "percentage",
      then: Yup.number()
        .required("Required*")
        .min(1, "Percentage must be at least 1")
        .max(99, "Percentage cannot exceed 99"),
      otherwise: Yup.number().required("Required*"),
    }),

  voucherCode: Yup.string()
    .required("Required*")
    .min(4, "Voucher Code should be at least 4 characters long")
    .max(8, "Voucher Code should not exceed 8 characters"),
  startDate: Yup.date().when("id", {
    is: (id) => id,
    then: Yup.date().notRequired(),
    otherwise: Yup.date()
      .required("Start Date Required*")
      .min(new Date(), "Start date must be greater than current date."),
  }),
  endDate: Yup.date()
    .required("End date Required*")
    .min(Yup.ref("startDate"), "End date can't be before Start date."),
  productId: Yup.string().when("voucherType", {
    is: (type) => type === "coupon",
    then: Yup.string().required(),
    otherwise: Yup.string(),
  }),
});
