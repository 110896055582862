import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import BusinessIcon from "@mui/icons-material/Business";
import EmojiFlagsIcon from "@mui/icons-material/EmojiFlags";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import WebIcon from "@mui/icons-material/Web";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardTitle from "components/CardTitle";
import NumberFormatter from "components/NumberFormatter";
import PropTypes from "prop-types";
import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardInsights,
  getRevenueByDate,
} from "store/slices/dashboardSlice";
import {
  KEY_NUMBERS_BACKGROUND_COLOR,
  STATS_NUMBER_FORMATTER_COLOR,
} from "styles/colors";
import useQueryParams from "utils/hooks/useQueryParams";

const ANALYTICS = [
  {
    label: "Event Count",
    value: "eventCount",
    type: "metrics",
    icon: <EmojiFlagsIcon />,
  },
  {
    label: "Impressions",
    value: "screenPageViews",
    type: "metrics",
    icon: <WebIcon />,
  },
  {
    label: "Sessions",
    value: "sessions",
    type: "metrics",
    icon: <TroubleshootIcon />,
  },
  {
    label: "Add to Cart",
    value: "add_to_cart",
    type: "events",
    icon: <AddShoppingCartIcon />,
  },
  {
    label: "Checkouts",
    value: "InitiateCheckout",
    type: "events",
    icon: <ShoppingCartCheckoutIcon />,
  },
  {
    label: "Shipping Info Added",
    value: "add_shipping_info",
    type: "events",
    icon: <BusinessIcon />,
  },
  {
    label: "Purchases",
    value: "purchase",
    type: "events",
    icon: <ShoppingBagIcon />,
  },
];

function DashboardAnalytics() {
  const dispatch = useDispatch();
  const params = useQueryParams();
  const { insights } = useSelector((state) => state.dashboard.insights);
  const avgBasketValue = useSelector(
    (state) =>
      state.dashboard.revenueByDate?.data?.order?.[0]?.averageOrders ?? 0
  );

  useEffect(() => {
    const events = [];
    const metrics = [];

    ANALYTICS.forEach((item) => {
      if (item.type === "metrics") {
        metrics.push({ name: item.value });
      } else if (item.type === "events") {
        events.push(item.value);
      }
    });

    const data = {
      ...(params?.from && { from: params.from }),
      ...(params?.to && { to: params.to }),
      events,
      metrics,
    };

    dispatch(getDashboardInsights(data));
  }, [dispatch, params.from, params.to]);

  useEffect(() => {
    const revenueParams = {
      ...(params?.from && { from: params.from }),
      ...(params?.to && { to: params.to }),
    };
    dispatch(getRevenueByDate(revenueParams));
  }, [dispatch, params]);

  return (
    <Card
      sx={{
        p: 2,
        height: "100%",
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="space-between"
        gap={3}
      >
        <CardTitle title="Analytics" />

        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="space-around"
          gap={3}
        >
          {ANALYTICS.map(({ label, value, icon }) => (
            <Tile
              key={value}
              name={label}
              value={insights?.[value] ?? 0}
              icon={icon}
              // showTime={value === "averageSessionDuration"}
              // showPercentage={value === "bounceRate"}
            />
          ))}
          <Tile
            key={"avgBasketValue"}
            name={"Avg Basket Value"}
            value={avgBasketValue ?? 0}
            icon={<PointOfSaleIcon />}
          />
        </Box>
      </Box>
    </Card>
  );
}

export default memo(DashboardAnalytics);

function Tile({ name, value, icon, showTime, showPercentage }) {
  const isOpen = useSelector((state) => state.UI.isSidebarOpen);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    let str = "";

    if (hours) str += `${hours}h `;
    if (minutes) str += `${minutes}m `;
    if (remainingSeconds) str += `${remainingSeconds}s`;

    return str;
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        backgroundColor={KEY_NUMBERS_BACKGROUND_COLOR}
        borderRadius="50%"
        height="auto"
        width="60px"
        sx={{
          aspectRatio: "1/1",
          "& .MuiSvgIcon-root": {
            color: "primary.light",
            fontSize: {
              xs: "1.5rem",
              md: isOpen ? "1.375rem" : "1.25rem",
              xl: "1.5rem",
              xxl: isOpen ? "1.75rem" : "2.125rem",
            },
          },
        }}
      >
        {icon}
      </Box>
      {!showTime && !showPercentage && (
        <NumberFormatter
          sx={{
            color: STATS_NUMBER_FORMATTER_COLOR,
            fontSize: { md: "1.25rem", xxl: "1.813rem" },
            fontWeight: "bold",
          }}
          number={Math.round(Number(value))}
        />
      )}
      {showTime && (
        <Typography
          fontSize={{ md: "1.25rem", xxl: "1.813rem" }}
          fontWeight="bold"
          color={STATS_NUMBER_FORMATTER_COLOR}
          sx={{
            whiteSpace: "nowrap",
          }}
        >
          {Math.round(Number(value))
            ? formatTime(Math.round(Number(value)))
            : 0}
        </Typography>
      )}
      {showPercentage && (
        <Typography
          fontSize={{ md: "1.25rem", xxl: "1.813rem" }}
          fontWeight="bold"
          color={STATS_NUMBER_FORMATTER_COLOR}
        >
          {`${Math.round(Number(value))}%`}
        </Typography>
      )}
      <Typography
        fontSize={{ xl: isOpen ? "0.851rem" : "0.875rem" }}
        color="#6e6b7b"
        sx={{
          whiteSpace: "nowrap",
        }}
      >
        {name}
      </Typography>
    </Box>
  );
}

Tile.propTypes = {
  icon: PropTypes.any,
  name: PropTypes.any,
  value: PropTypes.any,
  showTime: PropTypes.bool,
  showPercentage: PropTypes.bool,
};

Tile.defaultProps = {
  name: "",
  value: 0,
  icon: null,
  showTime: false,
  showPercentage: false,
};
