import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import productPlaceholder from "assets/images/imagePlaceholder.png";
import StyledGrid from "components/StyledGrid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAliexpressProductsByCollection,
  importAliExpressProducts,
} from "store/slices/productsSlice";
import useQueryParams from "utils/hooks/useQueryParams";
function AliExpressDataGrid() {
  const dispatch = useDispatch();
  const queryParams = useQueryParams();
  const { totalPages } = useSelector((state) => state.products);
  const activeFeedName = useSelector((state) => state.products.activeFeedName);
  const res = useSelector(
    (state) => state.products.aliexpressProductsByCollection
  );
  const isLoading = useSelector(
    (state) => state.products.aliExpressProductsLoading
  );
  const [products, setProducts] = useState([]);

  useEffect(() => {
    dispatch(
      getAliexpressProductsByCollection({
        ...queryParams,
        feedName: activeFeedName,
      })
    );
  }, [dispatch, queryParams, activeFeedName]);

  useEffect(() => {
    if (res.products) {
      setProducts(res.products);
    }
  }, [res]);

  const handleImportClick = () => {
    if (activeFeedName) {
      dispatch(importAliExpressProducts(activeFeedName));
    }
  };

  const columns = [
    {
      field: "product_main_image_url",
      headerName: "Image",
      headerAlign: "center",
      align: "center",
      minWidth: 125,
      flex: 0.8,
      sortable: false,
      renderCell: (params) => (
        <Box mx="auto">
          <img
            src={params?.row?.product_main_image_url || productPlaceholder}
            width={50}
            style={{
              borderRadius: "10px",
              marginTop: 6,
              objectFit: "contain",
            }}
            alt="product_image"
          />
        </Box>
      ),
    },
    {
      field: "product_title",
      headerName: "Name/Details",
      minWidth: 200,
      flex: 2,
      renderCell: (params) => (
        <Typography
          sx={{
            textTransform: "capitalize",
            color: "#000000",
            textOverflow: "ellipsis",
            overflow: "hidden",
            display: "-webkit-box !important",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            whiteSpace: "normal",
          }}
        >
          {params?.row?.product_title}
        </Typography>
      ),
    },
    {
      field: "target_original_price",
      headerName: "Price",
      minWidth: 90,
      flex: 1,
      renderCell: (params) => (
        <Typography>{params?.row?.target_original_price}</Typography>
      ),
    },
  ];

  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        <Button variant="contained" onClick={handleImportClick}>
          Import
        </Button>
      </Box>
      <StyledGrid
        rows={products}
        columns={columns}
        loading={isLoading}
        totalPages={totalPages}
        checkboxSelection={false}
        customRowId="product_id"
      />
    </>
  );
}

export default AliExpressDataGrid;
