const ReturnStatusOptions = [
  { label: "Requested", value: "requested", isEnabled: [] },
  {
    label: "Approved",
    value: "approved",
    isEnabled: ["requested"],
  },
  { label: "Rejected", value: "rejected", isEnabled: ["requested"] },
  {
    label: "Refunded",
    value: "refunded",
    isEnabled: ["received"],
  },
  {
    label: "Replacement",
    value: "replacement",
    isEnabled: ["received"],
  },
  { label: "Returned", value: "returned", isEnabled: ["approved"] },
  {
    label: "Received",
    value: "received",
    isEnabled: ["refunded", "replacement", "returned"],
  },
];

export default ReturnStatusOptions;

export const refundReasons = ["I have changed my mind"];
export const replacementReasons = [
  "I received a damaged product",
  "I received a wrong product",
  "Product quality is not as shown in the product image",
];
