import { Box, Stack, Typography } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { CURRENCY } from "utils/constants/constants";

function PricingSummary() {
  const pricingSummary = useSelector((state) => state.products.pricingSummary);
  const premiumPercentage = pricingSummary?.premium ?? 0;
  const { values } = useFormikContext();

  const price =
    values.onSale &&
    values.salePrice !== "0" &&
    values.salePrice !== 0 &&
    values.salePrice !== "";

  const calculateVAT = (basePrice) => {
    const price = parseFloat(basePrice || 0);
    const premium = (basePrice * parseFloat(premiumPercentage || 0)) / 100;
    const vat = parseFloat(pricingSummary.vat || 0);
    return ((price + premium) * (vat / (100 - vat))).toFixed(2);
  };

  return (
    <StyledMuiCard title="Pricing Summary">
      <CardContent>
        <Box display="flex" flexDirection="column" gap={1} padding="0 10px">
          <Stack direction="row" justifyContent="space-between">
            <Typography>Premium Percentage</Typography>
            <Typography>
              {premiumPercentage ?? 0}% (
              {price
                ? `${(values.salePrice * (premiumPercentage / 100)).toFixed(2)}`
                : `${(values.price * (premiumPercentage / 100)).toFixed(2)}` ??
                  0}
              )
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography>Shipment Charges</Typography>
            <Typography>
              {CURRENCY}{" "}
              {pricingSummary.shippment ?? pricingSummary.shipment ?? 0}
            </Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Typography>Forex Adjustment</Typography>
            <Typography>
              {pricingSummary.forex ?? 0}% (
              {price
                ? `${(values.salePrice * (pricingSummary.forex / 100)).toFixed(
                    2
                  )}`
                : `${(values.price * (pricingSummary.forex / 100)).toFixed(
                    2
                  )}` ?? 0}
              )
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography>VAT</Typography>
            <Typography>
              {pricingSummary.vat ?? 0}% (
              {price
                ? calculateVAT(values.salePrice)
                : calculateVAT(values.price) ?? 0}
              )
            </Typography>
          </Stack>
        </Box>
      </CardContent>
    </StyledMuiCard>
  );
}

export default PricingSummary;
