export const CATEGORIES =
  process.env.REACT_APP_ENV === "production"
    ? {
        FASHION: "66fa5e0756711740c0637a58",
        ELECTRONICS: "66fa5e0756711740c0637ada",
        HOME_APPLIANCES: "66fa5e0756711740c0637b08",
        LAPTOPS_AND_COMPUTERS: "66fa5e0756711740c0637b34",
        HOME_AND_LIFESTYLE: "66fa5e0756711740c0637b5e",
        LIGHTS_AND_LIGHTING: "66fa5e0756711740c0637c1c",
        FOOTWEAR: "66fa5e0756711740c0637c3c",
        WATCHES: "66fa5e0756711740c0637c6e",
        HEALTH_AND_NUTRITION: "66fa5e0756711740c0637cf2",
        BEAUTY_PRODUCTS: "66fa5e0756711740c0637c96",
        JEWELRY_AND_ACCESSORIES: "66fa5e0756711740c0637d18",
        PARTIES_AND_WEDDINGS: "66fa5e0756711740c0637d52",
        BABY_PRODUCTS_AND_TOYS: "66fa5e0756711740c0637dae",
        EYE_WEAR: "66fa5e0756711740c0637dce",
        TRAVEL_AND_LUGGAGE: "66fa5e0856711740c0637e24",
        TOYS_AND_HOBBIES: "66fa5e0856711740c0637ed8",
        SPORTS_AND_FITNESS: "66fa5e0856711740c0637ef2",
        ART_AND_CALLIGRAPHY: "66fa5e0856711740c0637f08",
        GARDEN_AND_OUTDOOR: "66fa5e0856711740c0637ef2",
        BOOKS_AND_STATIONERY: "66fa5e0856711740c0637f08",
        GROCERIES: "66fa5e0856711740c0637f70",
        SAFETY_SECURITY_AND_PROTECTION: "66fa5e0856711740c0637fe2",
        TOOLS_AND_INDUSTRIALS: "66fa5e0856711740c0638014",
        MUSICAL_INSTRUMENTS: "66fa5e0856711740c06380a0",
        PET: "66fa5e0856711740c06380b2",
        MOBILES_AND_TABLETS: "66fa5e0856711740c06380d0",
        FRAGRANCES: "66fa5e0956711740c063810e",
        CAR_CARE: "66fa5e0956711740c0638126",
      }
    : {
        FASHION: "66fa5e0756711740c0637a58",
        ELECTRONICS: "66fa5e0756711740c0637ada",
        HOME_APPLIANCES: "66fa5e0756711740c0637b08",
        LAPTOPS_AND_COMPUTERS: "66fa5e0756711740c0637b34",
        HOME_AND_LIFESTYLE: "66fa5e0756711740c0637b5e",
        LIGHTS_AND_LIGHTING: "66fa5e0756711740c0637c1c",
        FOOTWEAR: "66fa5e0756711740c0637c3c",
        WATCHES: "66fa5e0756711740c0637c6e",
        HEALTH_AND_NUTRITION: "66fa5e0756711740c0637cf2",
        BEAUTY_PRODUCTS: "66fa5e0756711740c0637c96",
        JEWELRY_AND_ACCESSORIES: "66fa5e0756711740c0637d18",
        PARTIES_AND_WEDDINGS: "66fa5e0756711740c0637d52",
        BABY_PRODUCTS_AND_TOYS: "66fa5e0756711740c0637dae",
        EYE_WEAR: "66fa5e0756711740c0637dce",
        TRAVEL_AND_LUGGAGE: "66fa5e0856711740c0637e24",
        TOYS_AND_HOBBIES: "66fa5e0856711740c0637ed8",
        SPORTS_AND_FITNESS: "66fa5e0856711740c0637ef2",
        ART_AND_CALLIGRAPHY: "66fa5e0856711740c0637f08",
        GARDEN_AND_OUTDOOR: "66fa5e0856711740c0637ef2",
        BOOKS_AND_STATIONERY: "66fa5e0856711740c0637f08",
        GROCERIES: "66fa5e0856711740c0637f70",
        SAFETY_SECURITY_AND_PROTECTION: "66fa5e0856711740c0637fe2",
        TOOLS_AND_INDUSTRIALS: "66fa5e0856711740c0638014",
        MUSICAL_INSTRUMENTS: "66fa5e0856711740c06380a0",
        PET: "66fa5e0856711740c06380b2",
        MOBILES_AND_TABLETS: "66fa5e0856711740c06380d0",
        FRAGRANCES: "66fa5e0956711740c063810e",
        CAR_CARE: "66fa5e0956711740c0638126",
      };

//Product variant option categories
export const colorOptionsCategories = [
  CATEGORIES.FASHION,
  CATEGORIES.ELECTRONICS,
  CATEGORIES.HOME_APPLIANCES,
  CATEGORIES.LAPTOPS_AND_COMPUTERS,
  CATEGORIES.MOBILES_AND_TABLETS,
  CATEGORIES.HOME_AND_LIFESTYLE,
  CATEGORIES.LIGHTS_AND_LIGHTING,
  CATEGORIES.FOOTWEAR,
  CATEGORIES.WATCHES,
  CATEGORIES.BEAUTY_PRODUCTS,
  CATEGORIES.HEALTH_AND_NUTRITION,
  CATEGORIES.JEWELRY_AND_ACCESSORIES,
  CATEGORIES.PARTIES_AND_WEDDINGS,
  CATEGORIES.BABY_PRODUCTS_AND_TOYS,
  CATEGORIES.EYE_WEAR,
  CATEGORIES.TRAVEL_AND_LUGGAGE,
  CATEGORIES.TOYS_AND_HOBBIES,
  CATEGORIES.SPORTS_AND_FITNESS,
  CATEGORIES.ART_AND_CALLIGRAPHY,
  CATEGORIES.GARDEN_AND_OUTDOOR,
  CATEGORIES.BOOKS_AND_STATIONERY,
  CATEGORIES.SAFETY_SECURITY_AND_PROTECTION,
  CATEGORIES.TOOLS_AND_INDUSTRIALS,
  CATEGORIES.MUSICAL_INSTRUMENTS,
  CATEGORIES.PET,
  CATEGORIES.FRAGRANCES,
  CATEGORIES.CAR_CARE,
];

export const sizeOptionsCategories = [
  CATEGORIES.FASHION,
  CATEGORIES.ELECTRONICS,
  CATEGORIES.HOME_APPLIANCES,
  CATEGORIES.LAPTOPS_AND_COMPUTERS,
  CATEGORIES.MOBILES_AND_TABLETS,
  CATEGORIES.HOME_AND_LIFESTYLE,
  CATEGORIES.LIGHTS_AND_LIGHTING,
  CATEGORIES.FOOTWEAR,
  CATEGORIES.WATCHES,
  CATEGORIES.BEAUTY_PRODUCTS,
  CATEGORIES.HEALTH_AND_NUTRITION,
  CATEGORIES.JEWELRY_AND_ACCESSORIES,
  CATEGORIES.PARTIES_AND_WEDDINGS,
  CATEGORIES.BABY_PRODUCTS_AND_TOYS,
  CATEGORIES.EYE_WEAR,
  CATEGORIES.TRAVEL_AND_LUGGAGE,
  CATEGORIES.TOYS_AND_HOBBIES,
  CATEGORIES.SPORTS_AND_FITNESS,
  CATEGORIES.ART_AND_CALLIGRAPHY,
  CATEGORIES.GARDEN_AND_OUTDOOR,
  CATEGORIES.BOOKS_AND_STATIONERY,
  CATEGORIES.GROCERIES,
  CATEGORIES.SAFETY_SECURITY_AND_PROTECTION,
  CATEGORIES.TOOLS_AND_INDUSTRIALS,
  CATEGORIES.MUSICAL_INSTRUMENTS,
  CATEGORIES.PET,
  CATEGORIES.FRAGRANCES,
  CATEGORIES.CAR_CARE,
];

export const weightOptionsCategories = [
  CATEGORIES.PET,
  CATEGORIES.BOOKS_AND_STATIONERY,
  CATEGORIES.GROCERIES,
];

export const RAMOptionsCategories = [
  CATEGORIES.LAPTOPS_AND_COMPUTERS,
  CATEGORIES.MOBILES_AND_TABLETS,
];

export const ROMOptionsCategories = [
  CATEGORIES.LAPTOPS_AND_COMPUTERS,
  CATEGORIES.MOBILES_AND_TABLETS,
];

export const LCDSizeOptionsCategories = [CATEGORIES.LAPTOPS_AND_COMPUTERS];

export const packOptionsCategories = [
  CATEGORIES.FASHION,
  CATEGORIES.BEAUTY_PRODUCTS,
  CATEGORIES.EYE_WEAR,
  CATEGORIES.GROCERIES,
  CATEGORIES.SAFETY_SECURITY_AND_PROTECTION,
  CATEGORIES.TOOLS_AND_INDUSTRIALS,
  CATEGORIES.MUSICAL_INSTRUMENTS,
  CATEGORIES.PET,
  CATEGORIES.FRAGRANCES,
  CATEGORIES.CAR_CARE,
];

export const waistOptionsCategories = [CATEGORIES.FASHION, CATEGORIES.EYE_WEAR];

export const piecesOptionsCategories = [
  CATEGORIES.HOME_APPLIANCES,
  CATEGORIES.HOME_AND_LIFESTYLE,
  CATEGORIES.LIGHTS_AND_LIGHTING,
  CATEGORIES.WATCHES,
  CATEGORIES.BEAUTY_PRODUCTS,
  CATEGORIES.GROCERIES,
  CATEGORIES.SAFETY_SECURITY_AND_PROTECTION,
  CATEGORIES.TOOLS_AND_INDUSTRIALS,
  CATEGORIES.MUSICAL_INSTRUMENTS,
  CATEGORIES.PET,
  CATEGORIES.FRAGRANCES,
  CATEGORIES.CAR_CARE,
];

export const strapOptionsCategories = [CATEGORIES.WATCHES];

export const mAhOptionsCategories = [CATEGORIES.MOBILES_AND_TABLETS];

export const lengthOptionsCategories = [
  CATEGORIES.FASHION,
  CATEGORIES.ELECTRONICS,
  CATEGORIES.HOME_AND_LIFESTYLE,
  CATEGORIES.LIGHTS_AND_LIGHTING,
  CATEGORIES.HEALTH_AND_NUTRITION,
  CATEGORIES.JEWELRY_AND_ACCESSORIES,
  CATEGORIES.PARTIES_AND_WEDDINGS,
  CATEGORIES.BABY_PRODUCTS_AND_TOYS,
  CATEGORIES.EYE_WEAR,
  CATEGORIES.TRAVEL_AND_LUGGAGE,
  CATEGORIES.TOYS_AND_HOBBIES,
  CATEGORIES.SPORTS_AND_FITNESS,
  CATEGORIES.ART_AND_CALLIGRAPHY,
  CATEGORIES.GARDEN_AND_OUTDOOR,
  CATEGORIES.BOOKS_AND_STATIONERY,
  CATEGORIES.SAFETY_SECURITY_AND_PROTECTION,
  CATEGORIES.TOOLS_AND_INDUSTRIALS,
  CATEGORIES.MUSICAL_INSTRUMENTS,
  CATEGORIES.PET,
  CATEGORIES.FRAGRANCES,
  CATEGORIES.CAR_CARE,
];

export const heightOptionsCategories = [
  CATEGORIES.ELECTRONICS,
  CATEGORIES.HOME_AND_LIFESTYLE,
  CATEGORIES.LIGHTS_AND_LIGHTING,
  CATEGORIES.HEALTH_AND_NUTRITION,
  CATEGORIES.JEWELRY_AND_ACCESSORIES,
  CATEGORIES.PARTIES_AND_WEDDINGS,
  CATEGORIES.BABY_PRODUCTS_AND_TOYS,
  CATEGORIES.TRAVEL_AND_LUGGAGE,
  CATEGORIES.TOYS_AND_HOBBIES,
  CATEGORIES.SPORTS_AND_FITNESS,
  CATEGORIES.ART_AND_CALLIGRAPHY,
  CATEGORIES.GARDEN_AND_OUTDOOR,
  CATEGORIES.BOOKS_AND_STATIONERY,
  CATEGORIES.SAFETY_SECURITY_AND_PROTECTION,
  CATEGORIES.TOOLS_AND_INDUSTRIALS,
  CATEGORIES.MUSICAL_INSTRUMENTS,
  CATEGORIES.PET,
  CATEGORIES.FRAGRANCES,
  CATEGORIES.CAR_CARE,
];

export const widthOptionsCategories = [
  CATEGORIES.ELECTRONICS,
  CATEGORIES.HOME_AND_LIFESTYLE,
  CATEGORIES.LIGHTS_AND_LIGHTING,
  CATEGORIES.HEALTH_AND_NUTRITION,
  CATEGORIES.JEWELRY_AND_ACCESSORIES,
  CATEGORIES.PARTIES_AND_WEDDINGS,
  CATEGORIES.BABY_PRODUCTS_AND_TOYS,
  CATEGORIES.TRAVEL_AND_LUGGAGE,
  CATEGORIES.TOYS_AND_HOBBIES,
  CATEGORIES.SPORTS_AND_FITNESS,
  CATEGORIES.ART_AND_CALLIGRAPHY,
  CATEGORIES.GARDEN_AND_OUTDOOR,
  CATEGORIES.BOOKS_AND_STATIONERY,
  CATEGORIES.SAFETY_SECURITY_AND_PROTECTION,
  CATEGORIES.TOOLS_AND_INDUSTRIALS,
  CATEGORIES.MUSICAL_INSTRUMENTS,
  CATEGORIES.PET,
  CATEGORIES.FRAGRANCES,
  CATEGORIES.CAR_CARE,
];

export const litreOptionsCategories = [
  CATEGORIES.HOME_APPLIANCES,
  CATEGORIES.GROCERIES,
];

export const mlOptionsCategories = [
  CATEGORIES.BEAUTY_PRODUCTS,
  CATEGORIES.GROCERIES,
  CATEGORIES.SAFETY_SECURITY_AND_PROTECTION,
  CATEGORIES.TOOLS_AND_INDUSTRIALS,
  CATEGORIES.FRAGRANCES,
  CATEGORIES.CAR_CARE,
];

// Product Options Constants
export const productOptions = [
  { name: "Color", categories: colorOptionsCategories },
  { name: "Weight", categories: weightOptionsCategories },
  { name: "Waist", categories: waistOptionsCategories },
  { name: "Size", categories: sizeOptionsCategories },
  { name: "RAM", categories: RAMOptionsCategories },
  { name: "ROM", categories: ROMOptionsCategories },
  { name: "Pack", categories: packOptionsCategories },
  { name: "Pieces", categories: piecesOptionsCategories },
  { name: "Strap", categories: strapOptionsCategories },
  { name: "mAh", categories: mAhOptionsCategories },
  { name: "Length", categories: lengthOptionsCategories },
  { name: "Height", categories: heightOptionsCategories },
  { name: "Width", categories: widthOptionsCategories },
  { name: "Litre", categories: litreOptionsCategories },
  { name: "ML", categories: mlOptionsCategories },
];

export const colorOptions = [
  "Black",
  "Jet Black",
  "Dark Grey",
  "Grey",
  "Yellow",
  "Red",
  "Orange",
  "Purple",
  "Green",
  "Sky Blue",
  "Blue",
  "White",
  "Off White",
  "Brown",
  "Beige",
  "Indigo",
  "Violet",
  "Maroon",
  "Camel",
  "Moss",
  "Golden",
  "Burgundy",
  "Pink",
  "Baby Pink",
  "Shocking Pink",
  "Tea Pink",
  "Silver",
  "Tan",
  "Teal",
  "Mustard",
  "Khaki",
  "Peach",
  "Aqua",
  "Orchid",
  "Magenta",
  "Hot Pink",
  "Light Blue",
  "Dark Blue",
  "Turquoise",
  "Light Green",
  "Crimson",
  "Lavender",
  "Royal Blue",
  "Chocolate",
  "Coral",
  "Plum",
  "Cyan",
  "Transparent",
  "Olive",
  "Skin",
];

export const weightOptions = [
  "10 g",
  "50 g",
  "100 g",
  "250 g",
  "500 g",
  "1 kg",
  "1.5 kg",
  "2 kg",
  "2.5 kg",
  "3 kg",
  "3.5 kg",
  "4 kg",
  "4.5 kg",
  "5 kg",
  "8 kg",
  "10 kg",
  "15 kg",
  "20 kg",
  "25 kg",
  "30 kg",
  "35 kg",
  "40 kg",
  "1 tonne",
  "1.25 tonnes",
  "1.5 tonnes",
  "1.75 tonnes",
  "2 tonnes",
];

export const fashionSizeOptions = [
  "Small",
  "Medium",
  "Large",
  "XL",
  "2XL",
  "3XL",
  "4XL",
  "5XL",
  "XS",
  "XXS",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "36",
  "38",
  "40",
  "42",
  "44",
  "46",
  "48",
];

export const footwearSizeOptions = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
];

export const LCDSizeOptions = [
  "18 inch",
  "19 inch",
  "20 inch",
  "21 inch",
  "24 inch",
  "27 inch",
  "30 inch",
  "32 inch",
  "40 inch",
  "42 inch",
  "43 inch",
  "48 inch",
  "50 inch",
  "55 inch",
  "60 inch",
  "65 inch",
  "70 inch",
  "75 inch",
  "80 inch",
  "85 inch",
  "90 inch",
  "100 inch",
  "105 inch",
];

export const RamOptions = [
  "512 MB",
  "1 GB",
  "2 GB",
  "3 GB",
  "4 GB",
  "6 GB",
  "8 GB",
  "12 GB",
  "16 GB",
  "32 GB",
  "64 GB",
];

export const RomOptions = [
  "2 GB",
  "4 GB",
  "8 GB",
  "16 GB",
  "32 GB",
  "64 GB",
  "128 GB",
  "256 GB",
  "512 GB",
  "1 TB",
  "2 TB",
];

export const piecesOptions = [
  "2 pcs",
  "3 pcs",
  "4 pcs",
  "5 pcs",
  "6 pcs",
  "7 pcs",
  "8 pcs",
  "9 pcs",
  "10 pcs",
  "11 pcs",
  "12 pcs",
  "13 pcs",
  "14 pcs",
  "15 pcs",
  "16 pcs",
  "17 pcs",
  "18 pcs",
  "19 pcs",
  "20 pcs",
  "21 pcs",
  "22 pcs",
  "23 pcs",
  "24 pcs",
  "25 pcs",
  "50 pcs",
  "100 pcs",
];

export const strapOptions = [
  "rubber",
  "leather",
  "silicon",
  "suede",
  "braided",
  "steel",
  "stainless steel",
  "gold",
  "rose gold",
  "silver",
  "platinum",
  "titanium",
  "aluminum",
  "chain",
];

export const mAhOptions = [
  "1000 mAh",
  "1500 mAh",
  "2000 mAh",
  "2500 mAh",
  "3000 mAh",
  "3500 mAh",
  "4000 mAh",
  "4500 mAh",
  "5000 mAh",
  "5500 mAh",
  "6000 mAh",
  "6500 mAh",
  "7000 mAh",
  "7500 mAh",
  "8000 mAh",
  "10000 mAh",
  "12000 mAh",
  "14000 mAh",
  "15000 mAh",
  "20000 mAh",
];

export const waistOptions = [
  "16",
  "18",
  "20",
  "22",
  "24",
  "26",
  "28",
  "30",
  "32",
  "34",
  "36",
  "38",
  "40",
  "42",
  "44",
  "46",
  "48",
  "50",
  "52",
  "54",
  "56",
  "58",
  "60",
];

export const lengthOptions = [
  "standard",
  "long",
  "extra long",
  "short",
  "ankle length",
  "thigh length",
  "knee length",
];

export const heightOrWidthOptions = [
  "1 inch",
  "2 inch",
  "3 inch",
  "4 inch",
  "5 inch",
  "6 inch",
  "7 inch",
  "8 inch",
  "9 inch",
  "10 inch",
  "11 inch",
  "12 inch",
  "13 inch",
  "14 inch",
  "15 inch",
  "16 inch",
  "17 inch",
  "18 inch",
  "19 inch",
  "20 inch",
  "21 inch",
  "22 inch",
  "23 inch",
  "24 inch",
  "25 inch",
  "26 inch",
  "27 inch",
  "28 inch",
  "29 inch",
  "30 inch",
  "31 inch",
  "32 inch",
  "33 inch",
  "34 inch",
  "35 inch",
  "36 inch",
  "37 inch",
  "38 inch",
  "39 inch",
  "40 inch",
  "41 inch",
  "42 inch",
  "43 inch",
  "44 inch",
  "45 inch",
  "46 inch",
  "47 inch",
  "48 inch",
  "49 inch",
  "50 inch",
  "51 inch",
  "52 inch",
  "53 inch",
  "54 inch",
  "55 inch",
  "56 inch",
  "57 inch",
  "58 inch",
  "59 inch",
  "60 inch",
  "61 inch",
  "62 inch",
  "63 inch",
  "64 inch",
  "65 inch",
  "66 inch",
  "67 inch",
  "68 inch",
  "69 inch",
  "70 inch",
  "71 inch",
  "72 inch",
  "73 inch",
  "74 inch",
  "75 inch",
  "76 inch",
  "77 inch",
  "78 inch",
  "79 inch",
  "80 inch",
  "81 inch",
  "82 inch",
  "83 inch",
  "84 inch",
  "85 inch",
  "86 inch",
  "87 inch",
  "88 inch",
  "89 inch",
  "90 inch",
  "91 inch",
  "92 inch",
  "93 inch",
  "94 inch",
  "95 inch",
  "96 inch",
  "97 inch",
  "98 inch",
  "99 inch",
  "100 inch",
];

export const litreOptions = [
  "1 ltr",
  "2 ltr",
  "2.25 ltr",
  "2.5 ltr",
  "5 ltr",
  "8 ltr",
  "10 ltr",
  "12 ltr",
  "15 ltr",
  "20 ltr",
  "25 ltr",
];

export const mlOptions = [
  "5 ml",
  "10 ml",
  "15 ml",
  "25 ml",
  "50 ml",
  "100 ml",
  "150 ml",
  "200 ml",
  "250 ml",
  "500 ml",
  "750 ml",
];

export const packOptions = [
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "12",
  "15",
  "20",
  "24",
  "30",
  "36",
  "40",
  "50",
  "60",
  "72",
  "100",
  "Family Pack",
  "Value Pack",
  "Mega Pack",
  "Mixed Pack",
  "Gift Pack",
];

// Delivery Section
export const volumeUnits = ["cm", "m", "inch", "ft"];

export const weightUnits = ["g", "kg"];

// Service Section
export const warrantyTypes = ["none", "seller", "brand"];

export const warrantyPeriodUnits = ["day", "month", "year"];

export const VouchersOptions = ["product", "order"];
