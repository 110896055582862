import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CurrencyFormatter from "components/CurrencyFormatter";
import dayjs from "dayjs";
import { memo, useMemo } from "react";
import { useSelector } from "react-redux";

function BasicInfoSection() {
  const order = useSelector((state) => state.orders.order);
  const date = useMemo(
    () => dayjs(order?.createdAt).format("DD MMM, YYYY [at] hh:mm A"),
    [order?.createdAt]
  );

  return (
    <Card>
      <CardContent
        sx={{
          "&:last-child": {
            paddingBottom: "1rem",
          },
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography variant="body1" fontWeight="bold">
              {`Order # ${order?.orderId ?? ""}`}
            </Typography>
            <Typography variant="body1">{`Placed on ${date}`}</Typography>
          </Box>
          <Stack direction="row" gap={1}>
            <Typography variant="body1" fontWeight="bold">
              Payable:
            </Typography>
            <CurrencyFormatter number={order?.payable ?? 0} fontWeight="bold" />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default memo(BasicInfoSection);
