import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import StatusAgeDonutChart from "components/Insights/StatusAgeDonutChart";
import LogisticsStatusCards from "components/Logistics/LogisticsStatusCards";
import OrderStatusBarChart from "components/Logistics/OrderStatusBarChart";
import OrderStatusPieChart from "components/Logistics/OrderStatusPieChart";
import DateFilter from "components/Pickers/DateRange";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import { getOrderStats } from "store/slices/reportSlice";
import useQueryParams from "utils/hooks/useQueryParams";

function Logistics() {
  const dispatch = useDispatch();
  const params = useQueryParams();
  const navigate = useNavigate();
  const statusAge = useSelector((state) => state.reports.statusAge);

  useEffect(() => {
    dispatch(getOrderStats(params));
  }, [dispatch, params]);

  const handleChange = useCallback(
    (range) => {
      const _params = {
        ...params,
        from: range.startDate.toDateString(),
        to: range.endDate.toDateString(),
      };
      navigate({
        search: createSearchParams(_params).toString(),
      });
    },
    [navigate, params]
  );

  return (
    <Box py={2}>
      <Box paddingTop={2} paddingBottom={2} px={1}>
        <CustomBreadcrumbs />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography
          variant="h1"
          color="primary"
          fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.6rem" }}
          fontWeight={600}
          paddingTop={1}
          paddingBottom={1}
        >
          Logistics
        </Typography>
      </Box>
      <Box display="flex" justifyContent="end">
        <DateFilter onChange={handleChange} />
      </Box>
      <LogisticsStatusCards />
      <Grid container spacing={2}>
        <Grid item sm={6} md={6}>
          <OrderStatusPieChart />
        </Grid>
        <Grid item sm={6} md={6}>
          <OrderStatusBarChart />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item sm={6} md={6}>
          {Object.keys(statusAge).length ? (
            <StatusAgeDonutChart data={statusAge} />
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
}

export default Logistics;
