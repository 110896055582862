import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import { Form, Formik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generateTransaction, setFinanceTab } from "store/slices/reportSlice";
import { getStores } from "store/slices/storesSlice";
import { getErrorMessage } from "utils/helpers/apiDataHelpers";
import useDebounce from "utils/hooks/useDebounceValue";
import { toast } from "utils/hooks/useToast";
import * as Yup from "yup";
import ManualTransactionMediaSection from "./ManualTransactionMediaSection";

function ManualTransactionForm() {
  const dispatch = useDispatch();
  const { loading, results } = useSelector((state) => state?.stores);
  const [searchValue, setSearchValue] = useState("");
  const deferredValue = useDebounce(searchValue, 500);

  const handleChange = useCallback((value) => {
    setSearchValue(value);
  }, []);

  const initialValues = useMemo(
    () => ({
      store: null,
      amount: "",
      transactionImages: [],
    }),
    []
  );

  const handleSubmit = useCallback(
    (values) => {
      const formData = new FormData();
      formData.append("sellerDetailId", "628c8dd4e750fe033ed65baf");
      formData.append("amount", values.amount);
      values?.transactionImages?.forEach((image) => {
        formData.append(`transactionImages`, image);
      });

      dispatch(generateTransaction(formData))
        .unwrap()
        .then(() => {
          dispatch(setFinanceTab("TRANSACTIONS"));
          toast.success("Transaction created successfully");
        })
        .catch((e) => {
          toast.error(getErrorMessage(e));
        });
    },
    [dispatch]
  );

  useEffect(() => {
    const data = {};
    if (deferredValue) {
      data.name = "brandName";
      data.value = deferredValue;
    }
    dispatch(getStores(data));
  }, [deferredValue, dispatch]);

  return (
    <Formik
      enableReinitialize={true}
      validateOnBlur={true}
      validateOnChange={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
              <StyledMuiCard
                title="Basic Information"
                subTitle="Please enter the basic information of the seller such as seller Id and amount"
              >
                <CardContent>
                  <Stack direction="row" gap={3} p={{ xs: 1, md: 1.5 }}>
                    <Grid container spacing={3}>
                      <Grid item xs={6} lg={6}>
                        <Stack direction="column" gap={1}>
                          <Typography
                            variant="h3"
                            fontWeight="bold"
                            fontSize="1.25rem"
                            color="text.secondary"
                          >
                            Store
                          </Typography>
                          <Autocomplete
                            name="store"
                            options={results}
                            loading={loading}
                            value={formik.values.store}
                            noOptionsText="No Options Available"
                            onChange={(_, store) => {
                              formik.setFieldValue("store", store);
                            }}
                            getOptionLabel={(stores) => stores.brandName}
                            isOptionEqualToValue={(option, value) =>
                              option.brandName === value.brandName
                            }
                            onBlur={formik.handleBlur}
                            renderInput={(params) => (
                              <StyledTextField
                                {...params}
                                autoFocus
                                fullWidth
                                onChange={(e) => {
                                  handleChange(e.target.value);
                                }}
                                label="Select Store"
                                error={Boolean(
                                  formik.touched.store && formik.errors.store
                                )}
                                helperText={
                                  formik.touched.store && !!formik.errors.store
                                    ? formik.errors.store
                                    : "Select the store to generate transaction"
                                }
                                InputProps={{
                                  ...params.InputProps,
                                }}
                              />
                            )}
                            renderOption={(props, option) => (
                              <li {...props} key={option.seller}>
                                {option.brandName}
                              </li>
                            )}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <Stack direction="column" gap={1}>
                          <Typography
                            variant="h3"
                            fontWeight="bold"
                            fontSize="1.25rem"
                            color="text.secondary"
                          >
                            Amount
                          </Typography>
                          <StyledTextField
                            fullWidth
                            type="number"
                            {...formik.getFieldProps("amount")}
                            error={Boolean(
                              formik.touched.amount && formik.errors.amount
                            )}
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                              min: 0,
                              onInput: (e) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                              },
                            }}
                            helperText={
                              formik.touched.amount && !!formik.errors.amount
                                ? formik.errors.amount
                                : "Enter the amount for seller"
                            }
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </Stack>
                </CardContent>
              </StyledMuiCard>
            </Grid>
            <Grid item xs={12} lg={12}>
              <ManualTransactionMediaSection formik={formik} />
            </Grid>
            <Grid item xs={12} lg={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={formik.handleSubmit}
                disableElevation
                sx={{
                  fontWeight: "bold",
                  minWidth: { md: 100, xl: 250 },
                  height: { xs: 50, xl: 55 },
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default ManualTransactionForm;

const validationSchema = Yup.object().shape({
  store: Yup.object().nullable().required("Store is required*"),
  amount: Yup.number()
    .typeError("Amount must be a number")
    .required("Amount is required*")
    .min(1, "Amount must be greater than 0"),
  transactionImages: Yup.array()
    .min(1, "Please select atleast one image")
    .required("Transaction images are required*"),
});
