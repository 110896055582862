import { Box } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import { FastField } from "formik";
import { memo } from "react";
import StyledMuiCard from "./StyledMuiCard";
import StyledTextField from "./StyledTextField";

function BrandDetailsSection() {
  return (
    <Box sx={{ mt: 3 }}>
      <StyledMuiCard
        title="Brand & Model Information"
        subTitle="Please provide the brand name, model, and model number to complete product details."
      >
        <CardContent>
          <Stack direction="row" gap={2} p={{ xs: 1, md: 1.5 }}>
            <FastField name="brand">
              {({ field, meta }) => (
                <StyledTextField
                  {...field}
                  fullWidth
                  type="string"
                  label="Brand Name"
                  placeholder="brand name"
                  py={2}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  error={Boolean(meta.touched && meta.error)}
                  helperText={
                    meta.touched && meta.error
                      ? meta.error
                      : "Enter the brand name."
                  }
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      lineHeight: 1,
                      fontSize: "1rem",
                      fontWeight: "normal",
                      color: "text.secondary",
                    },
                  }}
                />
              )}
            </FastField>

            <FastField name="model">
              {({ field, meta }) => (
                <StyledTextField
                  {...field}
                  fullWidth
                  type="string"
                  label="Model Name"
                  placeholder="model name"
                  py={2}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  error={Boolean(meta.touched && meta.error)}
                  helperText={
                    meta.touched && meta.error
                      ? meta.error
                      : "Enter the model name."
                  }
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      lineHeight: 1,
                      fontSize: "1rem",
                      fontWeight: "normal",
                      color: "text.secondary",
                    },
                  }}
                />
              )}
            </FastField>

            <FastField name="modelNumber">
              {({ field, meta }) => (
                <StyledTextField
                  {...field}
                  fullWidth
                  type="string"
                  label="Model Number"
                  placeholder="model number"
                  py={2}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  error={Boolean(meta.touched && meta.error)}
                  helperText={
                    meta.touched && meta.error
                      ? meta.error
                      : "Enter the model number."
                  }
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      lineHeight: 1,
                      fontSize: "1rem",
                      fontWeight: "normal",
                      color: "text.secondary",
                    },
                  }}
                />
              )}
            </FastField>
          </Stack>
        </CardContent>
      </StyledMuiCard>
    </Box>
  );
}

export default memo(BrandDetailsSection);
