import { useField, useFormikContext } from "formik";
import { memo, useCallback, useMemo, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import { uploadDescriptionImage } from "services/Product";
import { getErrorMessage } from "utils/helpers/apiDataHelpers";
import { toast } from "utils/hooks/useToast";

function DescriptionEditor() {
  const editorRef = useRef(null);
  const id = useParams()?.id ?? "";
  const { setFieldValue, setFieldTouched, setFieldError } = useFormikContext();
  const [, meta] = useField("description");
  const [isUploading, setIsUploading] = useState(false);

  const imageHandler = useCallback(async () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");

    // File size limit (25MB)
    const MAX_FILE_SIZE = 25 * 1024 * 1024;
    // Accepted file types
    const ACCEPTED_TYPES = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/webp",
    ];

    input.onchange = async () => {
      const file = input.files?.[0];
      if (!file) return;

      // Validate file size
      if (file.size > MAX_FILE_SIZE) {
        toast.error("Image size should be less than 5MB");
        return;
      }

      // Validate file type
      if (!ACCEPTED_TYPES.includes(file.type)) {
        toast.error(
          "Please upload a valid image file (JPEG, PNG, GIF, or WEBP)"
        );
        return;
      }

      const quillObj = editorRef.current?.getEditor();
      if (!quillObj) {
        toast.error("Editor not initialized");
        return;
      }

      const range = quillObj.getSelection();
      if (!range) {
        toast.error("Please select where to insert the image");
        return;
      }

      const formData = new FormData();
      formData.append("productId", id);
      formData.append("descriptionImage", file);

      setIsUploading(() => true);
      try {
        // Insert a placeholder while uploading
        const placeholder = `<span class="image-uploading">Uploading image...</span>`;
        quillObj.clipboard.dangerouslyPasteHTML(range.index, placeholder);

        const response = await uploadDescriptionImage(formData);

        // Remove the placeholder
        const [leaf] = quillObj.getLeaf(range.index);
        const length = leaf.text?.length ?? 0;
        quillObj.deleteText(range.index, length);

        // Insert the actual image
        quillObj.insertEmbed(range.index, "image", response?.data?.data?.link);

        // Move cursor after the image
        quillObj.setSelection(range.index + 1);

        toast.success("Image uploaded successfully");
      } catch (error) {
        const message = getErrorMessage(error);
        toast.error(`Failed to upload image: ${message}`);

        // Remove placeholder on error
        const [leaf] = quillObj.getLeaf(range.index);
        const length = leaf.text?.length ?? 0;
        quillObj.deleteText(range.index, length);
      } finally {
        setIsUploading(() => false);
      }
    };

    input.click();
  }, [id]);

  const modules = useMemo(
    () => ({
      toolbar: {
        handlers: {
          image: imageHandler,
        },
        container: [
          [{ header: "1" }, { header: "2" }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image"],
        ],
      },
      clipboard: {
        matchVisual: false,
      },
    }),
    [imageHandler]
  );

  const validateContent = useCallback(
    (text, content) => {
      if (text.length === 0) {
        setFieldError("description", "Description is required");
        setFieldValue("description", "");
        return false;
      }
      if (text.length < 80) {
        setFieldError(
          "description",
          "Description must be at least 80 characters long"
        );
        return false;
      }
      if (text.length > 5000) {
        setFieldError(
          "description",
          "Description cannot exceed 5000 characters"
        );
        return false;
      }
      return true;
    },
    [setFieldError, setFieldValue]
  );

  return (
    <div className="relative">
      {isUploading && (
        <div className="absolute top-2 right-2 bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm">
          Uploading image...
        </div>
      )}
      <ReactQuill
        ref={editorRef}
        theme="snow"
        value={meta.value}
        onChange={(content, delta, source, editor) => {
          const text = editor.getText().replace(/\n/g, " ");
          if (validateContent(text, content)) {
            setFieldValue("description", content.trimStart());
          }
        }}
        onBlur={() => setFieldTouched("description", true)}
        placeholder="Write description here..."
        formats={[
          "header",
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          "list",
          "bullet",
          "indent",
          "link",
          "image",
        ]}
        modules={modules}
        disabled={isUploading}
      />
    </div>
  );
}

export default memo(DescriptionEditor);
