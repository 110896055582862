import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DateFilter from "components/Pickers/DateRange";
import FilterByCountry from "components/UI/FilterByCountry";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import { resetState } from "store/slices/filtersSlice";
import { FILTER_TEXT_COLOR, PRIMARY } from "styles/colors";
import useQueryParams from "utils/hooks/useQueryParams";
import AddActions from "./AddActions";
import ExportProductsCsv from "./ExportProductsCsv";
import FilterByCategory from "./FilterByCategory";
import FilterByPlatform from "./FilterByPlatform";
import FilterByStore from "./FilterByStore";

export default function ProductFilters() {
  const navigate = useNavigate();
  const params = useQueryParams();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const userRole = useSelector((state) => state.auth?.userRole);

  const handleClear = useCallback(() => {
    setOpen(() => false);
    navigate(`/products`);
  }, [navigate]);

  const handleChange = useCallback(
    (range) => {
      let _params = params;
      _params.from = dayjs(range.startDate).format("YYYY-MM-DD");
      _params.to = dayjs(range.endDate).format("YYYY-MM-DD");
      navigate({
        search: createSearchParams(_params).toString(),
      });
    },
    [navigate, params]
  );

  useEffect(() => {
    (params?.from ||
      params?.to ||
      params?.user ||
      params?.category ||
      params?.platform) &&
      setOpen(() => true);
  }, [params]);

  useEffect(() => () => dispatch(resetState()), [dispatch]);

  return (
    <Box pt={1} sx={containerStyles}>
      <Box
        display={{
          xl: userRole === "admin" ? "flex" : "inline-block",
          xxl: "flex",
        }}
      >
        <AddActions />
      </Box>
      {userRole === "admin" && <ExportProductsCsv />}
      <Box
        display="flex"
        justifyContent="flex-end"
        gap={2}
        my={{ xl: userRole === "admin" ? 0.5 : 4, xxl: 0.5 }}
        minHeight={46}
      >
        {!open && (
          <Button
            variant="text"
            size="small"
            onClick={() => setOpen(() => true)}
            className="Filter-toggle-btn"
            sx={{
              borderBottom: "2px solid",
              borderColor: PRIMARY,
              borderRadius: 0,
              textTransform: "none",
            }}
          >
            Filter
          </Button>
        )}
        {open && (
          <>
            <DateFilter onChange={handleChange} hideDefaultRange />
            {userRole === "admin" && <FilterByCountry queryKey="origin" />}
            <FilterByPlatform />
            {userRole === "admin" && <FilterByStore />}
            <FilterByCategory />
            <Button
              onClick={handleClear}
              variant="text"
              className="Filter-toggle-btn"
              sx={{
                borderBottom: "2px solid #707070",
                borderRadius: 0,
                color: FILTER_TEXT_COLOR,
                textTransform: "none",
              }}
            >
              Clear All
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
}
const containerStyles = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: 2,
  "& .MuiFormControl-root": {
    borderColor: "#fff",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#069ddd80",
    },
    "&:hover": {
      backgroundColor: "#edf8fd",
      "& fieldset": {
        borderColor: "#02b4fffc",
      },
    },
  },
  "& .MuiSelect-select": { height: "29px !important" },
  "& .Filter-toggle-btn": {
    height: 42,
    textTransform: "none",
    fontSize: "1rem",
  },
};
