import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import InsightCard from "../UI/InsightCard";
import InsightsLineChart from "./InsightsLineChart";

function UsersInsights({ insights }) {
  const isOpen = useSelector((state) => state.UI.isSidebarOpen);
  const insightsData = useSelector((state) => state.insights.insights);
  const usersGraph = useSelector((state) => state.insights.usersGraph);
  const sessionsGraph = useSelector((state) => state.insights.sessionsGraph);
  const viewsGraph = useSelector((state) => state.insights.viewsGraph);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Grid container spacing={2}>
        <Grid item sm={12} xl={isOpen ? 12 : 6} xxl={6}>
          <Grid container spacing={2}>
            {insights.slice(0, 4).map(({ label, value, icon }) => (
              <Grid item xs={6} xl={6} key={value}>
                <InsightCard
                  keyword={label}
                  value={insightsData?.[value]}
                  icon={icon}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item sm={12} xl={isOpen ? 12 : 6} xxl={6}>
          <InsightsLineChart
            title="Users"
            keyword={"totalUsers"}
            data={usersGraph}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item sm={12} xl={isOpen ? 12 : 6} xxl={6}>
          <InsightsLineChart
            title="Sessions"
            keyword="sessions"
            data={sessionsGraph}
          />
        </Grid>
        <Grid item sm={12} xl={isOpen ? 12 : 6} xxl={6}>
          <Grid container spacing={2}>
            {insights.slice(4).map(({ label, value, icon }) => (
              <Grid item xs={6} xl={6} key={value}>
                <InsightCard
                  keyword={label}
                  value={insightsData?.[value]}
                  icon={icon}
                  showPercentage={value === "bounceRate"}
                  showTime={value === "averageSessionDuration"}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <InsightsLineChart
        title="Views"
        keyword="screenPageViews"
        data={viewsGraph}
      />
    </Box>
  );
}

UsersInsights.propTypes = {
  insights: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default UsersInsights;
