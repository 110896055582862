import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import PendingApprovalImage from "assets/images/PendingApprovalImage.png";
import * as React from "react";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "store/slices/authSlice";

export default function PendingApproval() {
  const dispatch = useDispatch();
  const { userRole } = useSelector((state) => state.auth);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isEmailVerified = useSelector(
    (state) => state.auth.data?.user?.isEmailVarified
  );
  const isPhoneVerified = useSelector(
    (state) => state.auth.data?.user?.isPhoneVarified
  );
  const isVerified = isEmailVerified && isPhoneVerified;

  useEffect(() => {
    if (userRole === "requestedSeller" && isVerified) {
      setOpen(true);
    }
    return () => setOpen(false);
  }, [isVerified, userRole]);

  const handleRedirect = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      aria-labelledby="responsive-dialog-title"
    >
      <Paper elevation={4} sx={{ p: 3, bgcolor: "#f8f8f8" }}>
        <Stack spacing={3} justifyContent="center" alignItems="center">
          <img
            src={PendingApprovalImage}
            alt="Pending Approval"
            width={200}
            height="auto"
          />
          <Typography
            variant="h5"
            component="h1"
            align="center"
            color="text.secondary"
            textAlign="justify"
          >
            Your request to become a seller is pending. Our representative will
            give you a call within 48 hours to confirm your registration. You
            can also call our Vendor management team on{" "}
            <span style={{ whiteSpace: "nowrap", display: "inline-block" }}>
              +966-111mysouq
            </span>
            . For any further assistance.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<ArrowBackIcon />}
            onClick={() => handleRedirect()}
            sx={{
              textTransform: "capitalize",
            }}
          >
            Logout
          </Button>
        </Stack>
      </Paper>
    </Dialog>
  );
}
