import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import {
  getDateRangeFormat,
  getErrorMessage,
  getFormattedDateParams,
  getResponseData,
} from "utils/helpers/apiDataHelpers";
import { toast } from "utils/hooks/useToast";
import http from "utils/httpRequest/http";

export const getAnalytics = createAsyncThunk(
  "dashboard/getAnalytics",
  async (_, { rejectWithValue }) => {
    try {
      const response = await http.get(`/analytics`);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getRevenue = createAsyncThunk(
  "dashboard/getRevenue",
  async (_, { rejectWithValue }) => {
    try {
      const response = await http.get(`/analytics/revenue`);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getRevenueByDate = createAsyncThunk(
  "dashboard/getRevenueByDate",
  async (data, { rejectWithValue }) => {
    const { from, to } = data || {};
    const formattedDateParams = getFormattedDateParams({ from, to });

    try {
      const response = await http.get(`/analytics/revenue`, {
        params: {
          ...formattedDateParams,
        },
      });
      const responseData = getResponseData(response);
      return responseData[0];
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getDashboardInsights = createAsyncThunk(
  "dashboard/getDashboardInsights",
  async (data, { rejectWithValue }) => {
    const { from, to, ...keywords } = data;
    const _from = from ?? dayjs().subtract(1, "month").format("YYYY-MM-DD");
    const _to = to ?? dayjs().format("YYYY-MM-DD");

    try {
      const response = await http.post("/google-analytics", {
        from: _from,
        to: _to,
        ...keywords,
      });
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getMonthlyAnalytics = createAsyncThunk(
  "dashboard/getMonthlyAnalytics",
  async (date, { rejectWithValue }) => {
    try {
      const response = await http.get(`/analytics/monthly`, {
        params: {
          date: date ?? new Date().toDateString(),
        },
      });
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getOrdersChart = createAsyncThunk(
  "dashboard/getOrdersChart",
  async (data, { rejectWithValue }) => {
    const { from, to } = data;

    const formattedOrdersGraphDateParams = getFormattedDateParams(
      { from, to },
      true // Uses startDate/endDate naming convention
    );
    const dateRangeFormat = getDateRangeFormat({ from, to });

    try {
      const response = await http.get(`/analytics/ordersChart`, {
        params: {
          ...formattedOrdersGraphDateParams,
          format: dateRangeFormat,
        },
      });
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getFunnelChartData = createAsyncThunk(
  "dashboard/getFunnelChartData",
  async (data, { rejectWithValue }) => {
    const { from, to, ...keywords } = data;
    const _from = from ?? dayjs().subtract(1, "month").format("YYYY-MM-DD");
    const _to = to ?? dayjs().format("YYYY-MM-DD");

    try {
      const response = await http.post("/google-analytics", {
        from: _from,
        to: _to,
        ...keywords,
      });
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getTopSearchedKeywords = createAsyncThunk(
  "dashboard/getTopSearchedKeywords",
  async (data = {}, { rejectWithValue }) => {
    const { from: _from = "", to: _to = "" } = data;
    const from = _from || dayjs().subtract(1, "month").format("YYYY-MM-DD");
    const to = _to || dayjs().format("YYYY-MM-DD");

    try {
      const response = await http.get(`/google-analytics/key-words`, {
        params: {
          from: from,
          to: to,
          siteUrl: "sc-domain:bazaarghar.com",
          limit: 20,
        },
      });
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

const initialState = {
  loading: true,
  results: [],
  error: null,
  monthlyAnalytics: {
    loading: true,
    data: [],
    error: "",
  },
  ordersChart: {
    loading: true,
    data: [],
    error: "",
  },
  topSearchedKeywords: {
    loading: true,
    keywords: [],
    error: "",
  },
  insights: {
    loading: true,
    insights: null,
    error: "",
  },
  revenue: {
    loading: true,
    data: null,
    error: "",
  },
  revenueByDate: {
    loading: true,
    data: null,
    error: "",
  },
  funnelChart: {
    loading: true,
    data: null,
    error: "",
  },
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    // Actions
    resetDashboardState: () => initialState,
    resetTopSearchedKeywords: (state) => {
      state.topSearchedKeywords = initialState.topSearchedKeywords;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAnalytics.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAnalytics.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.results = action.payload;
      })
      .addCase(getAnalytics.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(getMonthlyAnalytics.pending, (state) => {
        state.monthlyAnalytics.loading = true;
        state.monthlyAnalytics.error = null;
      })
      .addCase(getMonthlyAnalytics.fulfilled, (state, action) => {
        state.monthlyAnalytics.loading = false;
        state.monthlyAnalytics.error = null;
        state.monthlyAnalytics.data = action.payload;
      })
      .addCase(getMonthlyAnalytics.rejected, (state, action) => {
        state.monthlyAnalytics.loading = false;
        state.monthlyAnalytics.error = action?.payload;
      })
      .addCase(getOrdersChart.pending, (state) => {
        state.ordersChart.loading = true;
        state.ordersChart.error = null;
      })
      .addCase(getOrdersChart.fulfilled, (state, action) => {
        state.ordersChart.loading = false;
        state.ordersChart.error = null;
        state.ordersChart.data = action.payload;
      })
      .addCase(getOrdersChart.rejected, (state, action) => {
        state.ordersChart.loading = false;
        state.ordersChart.error = action?.payload;
      })
      // Top Searched Keywords
      .addCase(getTopSearchedKeywords.pending, (state) => {
        state.topSearchedKeywords.loading = true;
        state.topSearchedKeywords.error = "";
      })
      .addCase(getTopSearchedKeywords.fulfilled, (state, action) => {
        state.topSearchedKeywords.loading = false;
        state.topSearchedKeywords.error = "";
        state.topSearchedKeywords.keywords = action.payload?.queryData ?? [];
      })
      .addCase(getTopSearchedKeywords.rejected, (state, action) => {
        state.topSearchedKeywords.loading = false;
        state.topSearchedKeywords.error = action?.payload;
      })
      .addCase(getDashboardInsights.pending, (state) => {
        state.insights.loading = true;
        state.insights.error = "";
      })
      .addCase(getDashboardInsights.fulfilled, (state, action) => {
        state.insights.loading = false;
        state.insights.error = "";
        state.insights.insights = action.payload;
      })
      .addCase(getDashboardInsights.rejected, (state, action) => {
        state.insights.loading = false;
        state.insights.error = action?.payload;
      })
      .addCase(getRevenue.pending, (state) => {
        state.revenue.loading = true;
        state.revenue.error = "";
      })
      .addCase(getRevenue.fulfilled, (state, action) => {
        state.revenue.loading = false;
        state.revenue.error = "";
        state.revenue.data = action.payload;
      })
      .addCase(getRevenue.rejected, (state, action) => {
        state.revenue.loading = false;
        state.revenue.error = action?.payload;
      })
      .addCase(getRevenueByDate.pending, (state) => {
        state.revenueByDate.loading = true;
        state.revenueByDate.error = "";
      })
      .addCase(getRevenueByDate.fulfilled, (state, action) => {
        state.revenueByDate.loading = false;
        state.revenueByDate.error = "";
        state.revenueByDate.data = action.payload;
      })
      .addCase(getRevenueByDate.rejected, (state, action) => {
        state.revenueByDate.loading = false;
        state.revenueByDate.error = action?.payload;
      })
      .addCase(getFunnelChartData.pending, (state) => {
        state.funnelChart.loading = true;
        state.funnelChart.error = "";
      })
      .addCase(getFunnelChartData.fulfilled, (state, action) => {
        state.funnelChart.loading = false;
        state.funnelChart.error = "";
        state.funnelChart.data = action.payload;
      })
      .addCase(getFunnelChartData.rejected, (state, action) => {
        state.funnelChart.loading = false;
        state.funnelChart.error = action?.payload;
      });
  },
});

// Action creators generated for each case reducer function
export const { resetDashboardState, resetTopSearchedKeywords } =
  dashboardSlice.actions;

// Exporting default reducer
export default dashboardSlice.reducer;
