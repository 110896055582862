import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import deleteIcon from "assets/icons/deleteIcon.svg";
import WritingIcon from "assets/icons/WritingIcon.svg";
import ConfirmDelete from "components/Dialog/ConfirmDelete";
import StyledDataGrid from "components/StyledGrid";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setConfirmDeletionDialogOpen,
  setDeleteId,
} from "store/slices/uiSlice";
import { deleteSeller, getSellers } from "store/slices/usersSlice";
import useQueryParams from "utils/hooks/useQueryParams";

export default function DataGrid() {
  const params = useQueryParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sellers, loading, totalPages } = useSelector((state) => state.users);

  const fetchUsers = useCallback(() => {
    dispatch(getSellers(params));
  }, [dispatch, params]);

  const handleClickOpen = useCallback(
    (userId) => {
      dispatch(setConfirmDeletionDialogOpen());
      dispatch(setDeleteId(userId));
    },
    [dispatch]
  );

  useEffect(() => {
    const timeOut = setTimeout(() => {
      fetchUsers();
    }, 500);
    return () => clearTimeout(timeOut);
  }, [dispatch, fetchUsers]);

  const columns = useMemo(
    () => [
      {
        field: "serialNumber",
        headerName: "Sr",
        minWidth: 40,
        flex: 0.2,
        filterable: false,
      },
      {
        field: "fullname",
        headerName: "Name",
        minWidth: 200,
        flex: 1.5,
        sortable: false,
        renderCell: (params) => (
          <Typography textTransform="capitalize">
            {params?.row?.fullname}
          </Typography>
        ),
      },
      {
        field: "role",
        headerName: "Role",
        minWidth: 150,
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <Typography textTransform="capitalize">
            {params?.row?.role}
          </Typography>
        ),
      },
      {
        field: "phone",
        headerName: "Phone",
        minWidth: 200,
        flex: 1.5,
        sortable: false,
        renderCell: (params) => (
          <Typography textTransform="capitalize">
            {params?.row?.phone}
          </Typography>
        ),
      },
      {
        field: "email",
        headerName: "Email",
        minWidth: 275,
        flex: 1.75,
        sortable: false,
        renderCell: (params) => (
          <Typography
            sx={{
              overflow: "hidden",
              textOverflow: " ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {params?.row?.email}
          </Typography>
        ),
      },
      // {
      //   field: "wallet",
      //   headerName: "Wallet",
      //   minWidth: 100,
      //   flex: 0.75,
      //   sortable: false,
      //   renderCell: (params) => (
      //     <CurrencyFormatter number={params?.row?.wallet?.balance ?? 0} />
      //   ),
      // },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        headerAlign: "right",
        align: "right",
        minWidth: 100,
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <Box display="flex">
            <IconButton
              size="small"
              onClick={() => navigate(`update/${params.id}`)}
            >
              <img src={WritingIcon} alt="edit" />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => handleClickOpen(params?.id)}
            >
              <img src={deleteIcon} alt="delete" />
            </IconButton>
          </Box>
        ),
      },
    ],
    [handleClickOpen, navigate]
  );
  return (
    <>
      <StyledDataGrid
        rows={sellers}
        columns={columns}
        loading={loading}
        totalPages={totalPages}
      />
      <ConfirmDelete deleteAction={deleteSeller} />
    </>
  );
}
