import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import CardTitle from "components/CardTitle";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { DASHBOARD_CHART_ROW_COLOR, PRIMARY } from "styles/colors";

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  padding: "1rem",
  display: "flex",
  height: "100%",
  flexDirection: "column",
  justifyContent: "space-between",
}));

function InsightsLineChart({ title, data, isAnalyticalData }) {
  const isOpen = useSelector((state) => state.UI.isSidebarOpen);
  const [render, setRender] = useState(false);

  const processedData = data?.map((item) => {
    const dateObj = isAnalyticalData
      ? new Date(item?.date)
      : new Date(item?.x?.year, item?.x?.month, item?.x?.date);
    const options = { month: "short", day: "numeric" };
    return {
      date: dateObj.toLocaleString("en-US", options),
      value: isAnalyticalData ? parseInt(item.count) : item?.y,
    };
  });

  const formatTooltipLabel = useCallback((label) => {
    // Parse the date string
    const [month, day] = label.split(" ");
    const year = new Date().getFullYear(); // Assume current year if not provided
    const date = new Date(`${month} ${day}, ${year}`);

    // Format the date as desired (e.g., "Jan 01, 2023")
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
  }, []);

  useEffect(() => {
    if (isOpen === !render) {
      const timeOut = setTimeout(() => {
        setRender(isOpen);
      }, 500);
      return () => clearTimeout(timeOut);
    }
  }, [isOpen, render]);

  if (!data) return null;

  return (
    <StyledPaper>
      <Box mb={3}>
        <CardTitle title={title} />
      </Box>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={processedData}
          margin={{
            right: 30,
            bottom: 5,
          }}
        >
          <CartesianGrid
            strokeDasharray="3 3"
            vertical={false}
            stroke={DASHBOARD_CHART_ROW_COLOR}
          />
          <XAxis dataKey="date" stroke="#000000" tick={{ fill: "#000000" }} />
          <YAxis stroke="#000000" tick={{ fill: "#000000" }} />
          <Tooltip
            labelFormatter={formatTooltipLabel}
            contentStyle={{ color: "#000000" }}
          />
          <Line
            type="linear"
            dataKey="value"
            stroke={PRIMARY}
            strokeWidth={4}
            activeDot={{ r: 8 }}
            dot={{
              strokeWidth: 0,
              r: 0,
              strokeColor: PRIMARY,
              fill: "#fff",
            }}
            name={title}
          />
        </LineChart>
      </ResponsiveContainer>
    </StyledPaper>
  );
}

InsightsLineChart.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  isAnalyticalData: PropTypes.bool,
};

InsightsLineChart.defaultProps = {
  title: "",
  data: [],
  isAnalyticalData: true,
};

export default InsightsLineChart;
