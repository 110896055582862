import { FormControlLabel, Radio, RadioGroup, Skeleton } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
  getAliexpressCollections,
  setActiveFeedName,
} from "store/slices/productsSlice";

const skeletonsCount = 12;

function CheckboxContent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const aliExpressCollections = useSelector(
    (state) => state.products.aliexpressCollections?.feeds
  );
  const activeFeedName = useSelector((state) => state.products.activeFeedName);
  const isLoading = useSelector(
    (state) => state.products.aliExpressCollectionLoading
  );
  const [categories, setCategories] = useState([]);
  const loading = isLoading || categories?.length === 0;

  useEffect(() => {
    dispatch(getAliexpressCollections());
  }, [dispatch]);

  useEffect(() => {
    if (aliExpressCollections && aliExpressCollections.length > 0) {
      if (!activeFeedName) {
        dispatch(activeFeedName(aliExpressCollections[0]?.promo_name));
      }
      setCategories(aliExpressCollections);
    }
  }, [activeFeedName, aliExpressCollections, dispatch]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    dispatch(setActiveFeedName(newValue));
    const updatedParams = {
      page: 1,
      limit: 20,
    };

    navigate({
      pathname: "/products/imports",
      search: createSearchParams(updatedParams).toString(),
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: "white",
        padding: 2,
        borderRadius: "10px",
      }}
    >
      <RadioGroup value={activeFeedName} onChange={handleChange}>
        {loading
          ? Array.from({ length: skeletonsCount }).map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                width="100%"
                height="50px"
                animation="wave"
                sx={{
                  my: 1,
                }}
              />
            ))
          : categories.map((category) => (
              <FormControlLabel
                key={category.promo_name}
                value={category.promo_name}
                control={<Radio />}
                label={
                  <Typography
                    sx={{
                      py: 1,
                      textTransform: "capitalize",
                      color: "#000000",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      display: "-webkit-box !important",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      whiteSpace: "normal",
                      wordBreak: "break-all",
                    }}
                  >
                    {category.promo_name}
                  </Typography>
                }
              />
            ))}
      </RadioGroup>
    </Box>
  );
}

export default CheckboxContent;
