import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllMarkets, resetState } from "store/slices/filtersSlice";
import { FILTER_TEXT_COLOR, FILTERS_BACKGROUND_COLOR } from "styles/colors";

function FilterByMarket() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { markets, loading } = useSelector((state) => state.filters);

  useEffect(() => {
    dispatch(getAllMarkets());
    return () => dispatch(resetState());
  }, [dispatch]);

  return (
    <Autocomplete
      sx={{
        minWidth: 170,
        maxWidth: 180,
      }}
      size="small"
      getOptionLabel={(market) => market.name}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      options={markets}
      noOptionsText="No Options Available"
      onChange={(_, newValue) => {
        newValue?.id
          ? navigate(`/stores?market=${newValue.id}`)
          : navigate(`/stores`);
      }}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select Market"
          fullWidth
          sx={{
            "& .MuiInputLabel-root": {
              fontSize: "1.063rem",
              height: 44,
            },
            "& .MuiInputBase-root": {
              height: 44,
              backgroundColor: FILTERS_BACKGROUND_COLOR,
              cursor: "pointer",
            },
            input: {
              color: FILTER_TEXT_COLOR,
              "&::placeholder": {
                opacity: 1,
              },
            },
          }}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  );
}

export default memo(FilterByMarket);
