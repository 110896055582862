import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "store/slices/authSlice";
import bannersReducer from "store/slices/bannersSlice";
import cartSlice from "store/slices/cartSlice";
import categoriesReducer from "store/slices/categoriesSlice";
import checkoutSlice from "store/slices/checkoutSlice";
import collectionReducer from "store/slices/collectionSlice";
import dashboardReducer from "store/slices/dashboardSlice";
import filtersReducer from "store/slices/filtersSlice";
import fullOrdersReducer from "store/slices/fullOrdersSlice";
import groupBuyReducer from "store/slices/groupBuySlice";
import masterTableReducer from "store/slices/masterTableSlice";
import notificationsReducer from "store/slices/notificationsSlice";
import ordersReducer from "store/slices/ordersSlice";
import productsReducer from "store/slices/productsSlice";
import reportSlice from "store/slices/reportSlice";
import requestedSellersReducer from "store/slices/requestedSellersSlice";
import returnsReducer from "store/slices/returnsSlice";
import sellersReducer from "store/slices/sellersSlice";
import storesReducer from "store/slices/storesSlice";
import uiReducer from "store/slices/uiSlice";
import usersReducer from "store/slices/usersSlice";
import videosReducer from "store/slices/videosSlice";
import vouchersSlice from "store/slices/vouchersSlice";
import chatReducer from "./slices/chatSlice";
import insightsReducer from "./slices/insightsSlice";
import languageTranslatorSlice from "./slices/languageTranslatorSlice";
import variantsSlice from "./slices/variantsSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  UI: uiReducer,
  dashboard: dashboardReducer,
  banners: bannersReducer,
  chat: chatReducer,
  categories: categoriesReducer,
  collection: collectionReducer,
  fullOrders: fullOrdersReducer,
  groupBuy: groupBuyReducer,
  notifications: notificationsReducer,
  orders: ordersReducer,
  products: productsReducer,
  variants: variantsSlice,
  requestedSellers: requestedSellersReducer,
  returns: returnsReducer,
  sellers: sellersReducer,
  stores: storesReducer,
  filters: filtersReducer,
  users: usersReducer,
  videos: videosReducer,
  vouchers: vouchersSlice,
  checkout: checkoutSlice,
  cart: cartSlice,
  languageTranslation: languageTranslatorSlice,
  table: masterTableReducer,
  insights: insightsReducer,
  reports: reportSlice,
});

export default rootReducer;
