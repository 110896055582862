import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import HomeIcon from "assets/icons/HomeIcon.svg";
import BannersCustomBreadCrumb from "components/authentication/BannersCustomBreadCrumb.jsx";
import ReportsBreadCrumbs from "components/authentication/ReportsBreadCrumbs";
import { memo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useLocation, useParams } from "react-router-dom";
import { getCategories } from "store/slices/filtersSlice";
import { PRIMARY } from "styles/colors";
import isValidMongoObjectID from "utils/helpers/isValidMongoObjectID";

const CustomBreadcrumbs = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { id, slug } = useParams();
  const flatCategories = useSelector(
    (state) => state.filters?.flattenedCategories
  );
  useEffect(() => {
    if (!flatCategories) {
      dispatch(getCategories());
    }
  }, [dispatch, flatCategories]);

  const pathnames = location.pathname.split("/").filter((path) => path);
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const findCategoryById = useCallback(
    (categoryId) => {
      const foundCategory = flatCategories.find(
        (category) => category.value === categoryId
      );

      if (foundCategory && foundCategory.label?.includes("-->")) {
        const parts = foundCategory.label.split("-->");
        return {
          ...foundCategory,
          label: parts[parts.length - 1].trim(),
        };
      }
      return foundCategory;
    },
    [flatCategories]
  );

  let filteredPathnames = [];
  if (id) {
    filteredPathnames = filteredPathnames = pathnames.filter((item) =>
      isNaN(parseInt(item))
    );
  } else {
    filteredPathnames = pathnames;
  }

  const bannerSet = pathnames.includes("banner-set");
  if (bannerSet) {
    return <BannersCustomBreadCrumb />;
  }

  const isReportRoute = pathnames.includes("reports");
  if (isReportRoute) {
    return <ReportsBreadCrumbs />;
  }

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNextIcon fontSize="small" sx={{ color: "#000" }} />}
    >
      <Link color="inherit" component={RouterLink} to="/">
        <img src={HomeIcon} alt="home" />
      </Link>
      {filteredPathnames.map((name, index) => {
        let category = null;
        const routeTo = `/${filteredPathnames.slice(0, index + 1).join("/")}`;
        let formattedName = capitalizeFirstLetter(name.replace(/-/g, " "));

        if (slug) {
          const lastHyphenIndex = name.lastIndexOf("-");
          if (lastHyphenIndex !== -1) {
            formattedName = capitalizeFirstLetter(
              name.substring(0, lastHyphenIndex)
            );
          }
        }
        if (isValidMongoObjectID(name)) {
          category = findCategoryById(name);
        }
        if (index === filteredPathnames.length - 1 || slug) {
          return (
            <span key={name} style={{ color: PRIMARY }}>
              {category ? category?.label : formattedName}
            </span>
          );
        } else {
          return (
            <Link
              color={PRIMARY}
              key={name}
              component={RouterLink}
              to={routeTo}
              sx={{
                margin: " 0 -.25rem",
                padding: "0 .25rem",
                "&:hover": {
                  backgroundColor: "#068ECF",
                  color: "#fff",
                  textDecoration: "none",
                },
              }}
            >
              {category ? category?.label : formattedName}
            </Link>
          );
        }
      })}
    </Breadcrumbs>
  );
};

export default memo(CustomBreadcrumbs);
