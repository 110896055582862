import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import InsightCard from "../UI/InsightCard";
import InsightsBarChart from "./InsightsBarChart";
import InsightsDonutChart from "./InsightsDonutChart";

function RevenueInsights({ insights }) {
  const isOpen = useSelector((state) => state.UI.isSidebarOpen);
  const revenue = useSelector((state) => state.insights.revenue);
  const insightsData = useSelector((state) => state.insights.insights);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Grid container spacing={2}>
        {insights.map(({ label, value, icon }) => (
          <Grid item xs={6} xl={isOpen ? 6 : 3} xxl={3} key={icon}>
            <InsightCard
              isSingleRowed
              icon={icon}
              keyword={label}
              value={insightsData?.[value]}
            />
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} xl={6}>
          <InsightsBarChart
            title="Revenue By Markets"
            data={{
              saudia: revenue.saudia[0] ?? {},
              pakistan: revenue.pakistan[0] ?? {},
              china: revenue.china[0] ?? {},
            }}
          />
        </Grid>
        <Grid item xs={12} xl={6}>
          <InsightsDonutChart
            title="Top Categories"
            data={revenue?.categoriesRevenue}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

RevenueInsights.propTypes = {
  insights: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default RevenueInsights;
