import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { exportProductsCsv } from "store/slices/productsSlice";
import useQueryParams from "utils/hooks/useQueryParams";

function ExportProductsCsv() {
  const params = useQueryParams();
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.products.exportCsv);

  const dateValidation = useMemo(() => {
    if (!params?.from || !params?.to) return true;

    const fromDate = new Date(params.from);
    const toDate = new Date(params.to);
    const diffDays =
      Math.abs(toDate.getTime() - fromDate.getTime()) / (1000 * 3600 * 24);

    return diffDays > 15;
  }, [params]);

  const handleClick = () => {
    if (params?.from && params.to && !dateValidation) {
      const _params = { ...params };
      delete _params.name;
      delete _params.value;

      dispatch(exportProductsCsv(_params));
    }
  };

  return (
    <Box display="flex" gap={2} alignItems="center">
      <Button
        size="large"
        variant="contained"
        disabled={dateValidation || loading}
        sx={{ textTransform: "none" }}
        onClick={handleClick}
      >
        {loading ? "Exporting CSV..." : "Export CSVs"}
      </Button>
      <Alert severity={dateValidation || error ? "error" : "info"}>
        {error
          ? error
          : dateValidation
          ? "The selected date range exceeds 15 days. Please select a valid range."
          : "You can generate a CSV for products by selecting a date range. The date range cannot exceed 15 days. The CSV will reflect the applied filters, except the search filter."}
      </Alert>
    </Box>
  );
}

export default ExportProductsCsv;
