import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import cnicPlaceholder from "assets/icons/cnicPlaceholder.svg";
import PropTypes from "prop-types";
import { memo, useEffect, useRef, useState } from "react";

function AccountMediaSection({ formik }) {
  const frontImageRef = useRef(null);
  const backImageRef = useRef(null);
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLarge = useMediaQuery((theme) => theme.breakpoints.down("xl"));
  const [frontImage, setFrontImage] = useState(formik.values.cnicFront ?? "");
  const [backImage, setBackImage] = useState(formik.values.cnicBack ?? "");

  useEffect(() => {
    setFrontImage(
      typeof formik.values.cnicFront === "object"
        ? URL.createObjectURL(formik.values.cnicFront)
        : formik.values.cnicFront
    );
    setBackImage(
      typeof formik.values.cnicBack === "object"
        ? URL.createObjectURL(formik.values.cnicBack)
        : formik.values.cnicBack
    );
  }, [formik.values.cnicBack, formik.values.cnicFront]);

  return (
    <Stack direction="row" gap={2}>
      <Stack direction="column" gap={2}>
        <Typography
          variant="h3"
          fontWeight="bold"
          fontSize="1.25rem"
          color="text.secondary"
        >
          Front Side CNIC/CR Image
        </Typography>
        <Box>
          <img
            src={formik.values?.cnicFront ? frontImage : cnicPlaceholder}
            alt="Category Main Media"
            height="auto"
            width={isSmall ? "150px" : isLarge ? "350px" : "400px"}
            style={{ cursor: "pointer" }}
            onClick={() => frontImageRef.current.click()}
          />
          <input
            type="file"
            ref={frontImageRef}
            style={{ display: "none" }}
            id="update-category-image"
            hidden
            onChange={(e) =>
              formik.setFieldValue("cnicFront", e.target.files[0])
            }
            accept="ima ge/x-png,image/png,image/jpeg,image/jpg"
          />
          {Boolean(formik.errors.frontImage) && (
            <Typography color="#ef5350">
              {formik.errors.frontImage ?? ""}*
            </Typography>
          )}
        </Box>
      </Stack>
      <Stack direction="column" gap={2}>
        <Typography
          variant="h3"
          fontWeight="bold"
          fontSize="1.25rem"
          color="text.secondary"
        >
          Back Side CNIC/CR Image
        </Typography>
        <Box>
          <img
            src={formik.values?.cnicBack ? backImage : cnicPlaceholder}
            alt="Category Main Media"
            height="auto"
            width={isSmall ? "150px" : isLarge ? "350px" : "400px"}
            style={{
              cursor: "pointer",
            }}
            onClick={() => backImageRef.current.click()}
          />
          <input
            type="file"
            ref={backImageRef}
            style={{ display: "none" }}
            id="update-category-image"
            hidden
            onChange={(e) =>
              formik.setFieldValue("cnicBack", e.target.files[0])
            }
            accept="image/x-png,image/png,image/jpeg,image/jpg"
          />
          {Boolean(formik.errors.backImage) && (
            <Typography color="#ef5350">
              {formik.errors.backImage ?? ""}*
            </Typography>
          )}
        </Box>
      </Stack>
    </Stack>
  );
}
AccountMediaSection.propTypes = {
  formik: PropTypes.object.isRequired,
};
export default memo(AccountMediaSection);
