import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import { FILTER_TEXT_COLOR, FILTERS_BACKGROUND_COLOR } from "styles/colors";
import useQueryParams from "utils/hooks/useQueryParams";

const COUNTRY_OPTIONS = [
  { label: "KSA", value: "ksa" },
  { label: "China", value: "china" },
  { label: "Pakistan", value: "pak" },
];

function FilterByCountry({ queryKey = "country", hideChinaCountry = false }) {
  const navigate = useNavigate();
  const params = useQueryParams();
  const loading = useSelector((state) => state.filters.loading);
  const [selectedCountry, setSelectedCountry] = useState(null);

  useEffect(() => {
    const selectedCountry = params?.[queryKey];
    const matchedCountry = COUNTRY_OPTIONS.find(
      (country) => country.value === selectedCountry
    );

    if (matchedCountry) {
      setSelectedCountry(matchedCountry);
    } else {
      setSelectedCountry(null);
    }
  }, [queryKey, params]);

  const handleCountryChange = (newValue) => {
    let _params = { ...params };

    if (!newValue) {
      setSelectedCountry(null);
      delete _params[queryKey];
    } else {
      setSelectedCountry(newValue);
      _params[queryKey] = newValue.value;
    }

    navigate({ search: createSearchParams(_params).toString() });
  };

  return (
    <Autocomplete
      sx={{ minWidth: 170, maxWidth: 180 }}
      size="small"
      value={selectedCountry}
      onChange={(_, newValue) => handleCountryChange(newValue)}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      noOptionsText="No Options Available"
      options={
        hideChinaCountry
          ? [COUNTRY_OPTIONS[0], COUNTRY_OPTIONS[2]]
          : COUNTRY_OPTIONS
      }
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Country"
          fullWidth
          sx={{
            "& .MuiInputLabel-root": {
              fontSize: "1.063rem",
              height: 44,
            },
            "& .MuiInputBase-root": {
              height: 44,
              backgroundColor: FILTERS_BACKGROUND_COLOR,
              cursor: "pointer",
            },
            input: {
              color: FILTER_TEXT_COLOR,
              "&::placeholder": {
                opacity: 1,
              },
            },
          }}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  );
}

export default memo(FilterByCountry);

FilterByCountry.propTypes = {
  queryKey: PropTypes.string,
  hideChinaCountry: PropTypes.bool,
};
