import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import PropTypes from "prop-types";
import { memo } from "react";
import { CURRENCY } from "utils/constants/constants";
import isValidNumber from "utils/helpers/isValidNumber";

function PriceSection({ formik }) {
  return (
    <StyledMuiCard title="Pricing" subTitle="Set the price details.">
      <CardContent>
        <Stack
          direction="column"
          justifyContent="center"
          gap={2}
          py={{ xs: 1, md: 1.5 }}
          paddingLeft={1.5}
        >
          <Typography
            variant="h3"
            fontWeight="bold"
            fontSize="1.25rem"
            color="text.secondary"
          >
            Regular Price
          </Typography>
          <StyledTextField
            fullWidth
            name="price"
            type="text"
            value={formik?.values?.price}
            disabled={true}
            placeholder="0"
            onChange={(e) => {
              isValidNumber(e.target.value) && formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            error={Boolean(formik.touched.price && formik.errors.price)}
            helperText={
              formik.touched.price && !!formik.errors.price
                ? formik.errors.price
                : "Regular product price."
            }
            py={2}
            sx={{
              "& .MuiOutlinedInput-input": {
                lineHeight: 1,
                fontSize: "2rem",
                fontWeight: "bold",
                color: "text.secondary",
                height: "auto",
                padding: "0.5rem 0.75rem",
                textAlign: "right",
              },
            }}
            InputProps={{
              startAdornment: (
                <Typography
                  color="text.disabled"
                  sx={{
                    fontSize: "1.25rem",
                    borderRight: "1px solid #00000038",
                    paddingRight: "1rem",
                  }}
                >
                  {CURRENCY}
                </Typography>
              ),
            }}
          />
          {/* Commented out the code as instructions provided by QA Kazim as the product pricing is handled by Backend itself */}
          {/* <Stack direction="row" justifyContent="space-between">
            <Typography
              variant="body1"
              fontWeight="bold"
              color="text.secondary"
            >
              Grand Total:
            </Typography>
            <Typography
              variant="body1"
              fontWeight="bold"
              color="text.secondary"
            >
              {CURRENCY}{" "}
              {formik?.values?.price === ""
                ? "0"
                : calculatedPrice(formik?.values?.price, pricingSummary)}
            </Typography>
          </Stack> */}
          <Stack direction="row" py={1} justifyContent="space-between">
            <Box>
              <Typography
                variant="h3"
                fontWeight="bold"
                fontSize="1.25rem"
                color="text.secondary"
              >
                Group Sale Price
              </Typography>
            </Box>
          </Stack>
          <StyledTextField
            fullWidth
            name="groupSalePrice"
            type="text"
            placeholder="0"
            value={formik?.values?.groupSalePrice}
            onChange={(e) => {
              isValidNumber(e.target.value) && formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            disabled={formik.values.id || !formik?.values?.productId}
            error={Boolean(
              formik.touched.groupSalePrice && formik.errors.groupSalePrice
            )}
            helperText={
              formik.touched.groupSalePrice && !!formik.errors.groupSalePrice
                ? formik.errors.groupSalePrice
                : "Deal sale price should be less than regular price."
            }
            py={2}
            sx={{
              "& .MuiOutlinedInput-input": {
                lineHeight: 1,
                fontSize: "2rem",
                fontWeight: "bold",
                color: "text.secondary",
                height: "auto",
                padding: "0.5rem 0.75rem",
                textAlign: "right",
              },
            }}
            InputProps={{
              startAdornment: (
                <Typography
                  color="text.disabled"
                  sx={{
                    fontSize: "1.25rem",
                    borderRight: "1px solid #00000038",
                    paddingRight: "1rem",
                  }}
                >
                  {CURRENCY}
                </Typography>
              ),
            }}
          />
          {/* Commented out the code as instructions provided by QA Kazim as the product pricing is handled by Backend itself */}
          {/* <Stack direction="row" justifyContent="space-between">
            <Typography
              variant="body1"
              fontWeight="bold"
              color="text.secondary"
            >
              Grand Total:
            </Typography>
            <Typography
              variant="body1"
              fontWeight="bold"
              color="text.secondary"
            >
              {CURRENCY}{" "}
              {formik?.values?.groupSalePrice === ""
                ? "0"
                : calculatedPrice(
                    formik?.values?.groupSalePrice,
                    pricingSummary
                  )}
            </Typography>
          </Stack> */}
        </Stack>
      </CardContent>
    </StyledMuiCard>
  );
}

PriceSection.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(PriceSection);
