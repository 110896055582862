import grey from "@mui/material/colors/grey";
import GlobalStyles from "@mui/material/GlobalStyles";
import Paper from "@mui/material/Paper";
import styled from "@mui/material/styles/styled";
import CardTitle from "components/CardTitle";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  padding: "1rem",
  display: "flex",
  height: "100%",
  width: "100%",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: 1,
}));

function InsightsDonutChart({ title, data }) {
  const chartRef = useRef(null);
  const [chartWidth, setChartWidth] = useState("100%");
  const isOpen = useSelector((state) => state.UI.isSidebarOpen);

  const options = {
    chart: {
      type: "pie",
    },
    labels: data.map((category) => category.name),
    stroke: {
      colors: ["#fff"],
    },
    fill: {
      opacity: 0.9,
    },
    responsive: [
      {
        breakpoint: 1000,
        options: {
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    plotOptions: {
      polarArea: {
        rings: {
          strokeWidth: 0,
        },
        spokes: {
          strokeWidth: 0,
        },
      },
    },
    yaxis: {
      show: false,
    },
    legend: {
      position: "left",
    },
    tooltip: {
      custom: function ({ series, seriesIndex, w }) {
        const categoryName = w.globals.labels[seriesIndex];
        const value = series[seriesIndex];
        const profit = data[seriesIndex].profit;

        return (
          '<div class="custom-tooltip">' +
          '<span class="tooltip-label">' +
          categoryName +
          "</span>" +
          '<span class="tooltip-value">Total Sale: ' +
          parseInt(value).toLocaleString() +
          "</span>" +
          '<span class="tooltip-value">Profit: ' +
          parseInt(profit).toLocaleString() +
          "</span>" +
          "</div>"
        );
      },
    },
    series: data.map((category) => category.totalSale),
  };

  const series = data.map((category) => category.totalSale);

  useEffect(() => {
    if (chartRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const { width } = entry.contentRect;
          setChartWidth(() => width);
        }
      });

      resizeObserver.observe(chartRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  if (!data) return null;

  return (
    <StyledPaper ref={chartRef}>
      <CardTitle title={title} color={grey[700]} />
      <ReactApexChart
        options={options}
        series={series}
        type="pie"
        height={isOpen ? 378 : 361}
        width={chartWidth}
      />
      <GlobalStyles
        styles={{
          ".custom-tooltip": {
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            padding: "8px",
            borderRadius: "4px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          },
          ".tooltip-label": {
            display: "block",
            fontWeight: "bold",
            marginBottom: "5px",
            color: "#333",
          },
          ".tooltip-value": {
            display: "block",
            color: "#666",
          },
        }}
      />
    </StyledPaper>
  );
}

InsightsDonutChart.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

InsightsDonutChart.defaultProps = {
  title: "",
  data: [],
};

export default InsightsDonutChart;
