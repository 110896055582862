import CardContent from "@mui/material/CardContent";
import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPricingSummary } from "store/slices/productsSlice";
import { getAdminSellerDetailById } from "store/slices/storesSlice";
import ProductFormTabs from "../ProductFormTabs";
import StyledMuiCard from "../StyledMuiCard";
import AddVariantSection from "./AddVariantSection";
import ManageVariantsSection from "./ManageVariantsSection";

function VariantProductForm() {
  const dispatch = useDispatch();
  const variants = useSelector(
    (state) => state.products.product?.variants ?? []
  );
  const product = useSelector((state) => state.products?.product);

  useEffect(() => {
    const data = {
      origin: product.origin,
      sellerId: product.sellerDetail,
    };
    dispatch(getPricingSummary(data));
    if (variants.sellerDetail) {
      dispatch(getAdminSellerDetailById(variants.sellerDetail));
    }
  }, [dispatch, product.origin, product.sellerDetail, variants]);

  return (
    <>
      <ProductFormTabs />
      {/* Add Variants */}
      <StyledMuiCard
        title="Add Variants"
        subTitle="Add variant like color, size, etc"
      >
        <CardContent>
          <AddVariantSection />
        </CardContent>
      </StyledMuiCard>

      {/* Manage Variants */}
      {variants.length ? (
        <StyledMuiCard
          title="Manage Variants"
          subTitle="You can view, update or delete variants"
        >
          <CardContent>
            <ManageVariantsSection />
          </CardContent>
        </StyledMuiCard>
      ) : null}
    </>
  );
}

export default memo(VariantProductForm);
