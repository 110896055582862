import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { memo, useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { FILTER_TEXT_COLOR, FILTERS_BACKGROUND_COLOR } from "styles/colors";
import useQueryParams from "utils/hooks/useQueryParams";

const platformArray = [
  { code: "aliExpress", label: "Ali Express" },
  { code: "bazaarGhar", label: "My Souq" },
  { code: "shopify", label: "Shopify" },
  { code: "ginkgo", label: "Ginkgo" },
];

function FilterByPlatform() {
  const navigate = useNavigate();
  const params = useQueryParams();
  const [selectedPlatform, setSelectedPlatform] = useState(null);

  useEffect(() => {
    const platformCode = params.platform;
    const matchedPlatform = platformArray.find(
      (platform) => platform.code === platformCode
    );

    if (matchedPlatform) {
      setSelectedPlatform(matchedPlatform);
    } else {
      setSelectedPlatform(null);
    }
  }, [params]);

  const handlePlatformChange = (newValue) => {
    let _params = { ...params };

    if (!newValue) {
      setSelectedPlatform(null);
      delete _params.platform;
    } else {
      setSelectedPlatform(newValue);
      _params.platform = newValue.code;
    }

    navigate({ search: createSearchParams(_params).toString() });
  };

  return (
    <Box display="flex" gap={1}>
      <Autocomplete
        size="small"
        options={platformArray}
        value={selectedPlatform}
        noOptionsText="No Options Available"
        sx={{ minWidth: 170, maxWidth: 180 }}
        getOptionLabel={(option) => option.label}
        onChange={(_, newValue) => handlePlatformChange(newValue)}
        isOptionEqualToValue={(option, value) => option.code === value.code}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Platform"
            fullWidth
            sx={{
              "& .MuiInputLabel-root": {
                fontSize: "1.063rem",
                height: 44,
              },
              "& .MuiInputBase-root": {
                height: 44,
                backgroundColor: FILTERS_BACKGROUND_COLOR,
                cursor: "pointer",
              },
              input: {
                color: FILTER_TEXT_COLOR,
                "&::placeholder": {
                  opacity: 1,
                },
              },
            }}
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
      />
    </Box>
  );
}

export default memo(FilterByPlatform);
