import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import eyeIcon from "assets/icons/eyeIcon.svg";
import WritingNote from "assets/images/WritingNote.svg";
import CurrencyFormatter from "components/CurrencyFormatter";
import StyledDataGrid from "components/StyledGrid";
import dayjs from "dayjs";
import { memo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getFullOrders } from "store/slices/fullOrdersSlice";
import useQueryParams from "utils/hooks/useQueryParams";

function FullOrdersDataGrid() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useQueryParams();
  const { results, loading, totalPages } = useSelector(
    (state) => state.fullOrders
  );

  const fetchOrders = useCallback(() => {
    dispatch(getFullOrders(params));
  }, [dispatch, params]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      fetchOrders();
    }, 500);
    return () => clearTimeout(timeOut);
  }, [dispatch, fetchOrders]);

  const columns = [
    {
      field: "serialNumber",
      headerName: "Sr",
      minWidth: 60,
      flex: 0.2,
    },
    {
      field: "OrderDetailId",
      headerName: "Order ID",
      minWidth: 160,
      flex: 1.25,
      renderCell: (params) => (
        <Typography>{params?.row?.OrderDetailId}</Typography>
      ),
    },
    {
      field: "customer",
      headerName: "Customer Name",
      minWidth: 100,
      flex: 1.25,
      sortable: false,
      renderCell: (params) => (
        <Typography
          textTransform="capitalize"
          sx={{
            textTransform: "capitalize",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {params?.row?.customer?.fullname}
        </Typography>
      ),
    },
    {
      field: "orderAddress",
      headerName: "City",
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Typography
          sx={{
            textTransform: "capitalize",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {params?.row?.orderAddress?.city}
        </Typography>
      ),
    },
    {
      field: "createdAt",
      headerName: "Order Date",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <Typography>
          {dayjs(params?.row?.createdAt).format("DD/MM/YYYY")}
        </Typography>
      ),
    },

    {
      field: "payable",
      headerName: "Payable",
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) =>
        row?.payable ? (
          <CurrencyFormatter
            number={row?.payable ?? 0}
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          />
        ) : (
          <Typography
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            Paid
          </Typography>
        ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      minWidth: 100,
      flex: 0.75,
      sortable: false,
      align: "right",
      renderCell: (params) => (
        <Box display="flex" sx={actionStyles}>
          {params?.row?.orderNote && (
            <Tooltip title={params.row.orderNote}>
              <IconButton variant="text" className="button-styles">
                <img src={WritingNote} alt="edit" className="icon-size" />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="View">
            <IconButton
              size="small"
              onClick={() => navigate(params.row?.id ?? params.row?._id)}
            >
              <img src={eyeIcon} alt="view" className="icon-size" />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  return (
    <StyledDataGrid
      rows={results}
      columns={columns}
      loading={loading}
      totalPages={totalPages}
      customRowId="OrderDetailId"
      checkboxSelection={false}
    />
  );
}

export default memo(FullOrdersDataGrid);

const actionStyles = {
  "& .icon-size": { height: "25px", width: "25px" },
};
