import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import PaidIcon from "@mui/icons-material/Paid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { CURRENCY } from "utils/constants/constants";

function ReportsCards() {
  const stats = useSelector((state) => state.reports.stats);
  function add14Days(dateString) {
    const date = new Date(dateString);
    date.setDate(date.getDate() + 14);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={4}>
        <Card
          sx={{
            p: "1.5rem",
          }}
        >
          <Box display="flex" flexDirection="column" gap={1}>
            <PaidIcon color="primary" fontSize="large" />
            <Typography>Total Paid Revenue</Typography>
            <Typography variant="h5" fontWeight="bold">
              {CURRENCY} {stats.baseTotal?.toFixed(2) ?? 0}
            </Typography>
          </Box>
        </Card>
      </Grid>
      <Grid item md={4}>
        <Card
          sx={{
            p: "1.5rem",
          }}
        >
          <Box display="flex" flexDirection="column" gap={1}>
            <MoneyOffIcon color="primary" fontSize="large" />
            <Typography>Total Unpaid</Typography>
            <Typography variant="h5" fontWeight="bold">
              {CURRENCY} {stats.unPaid?.toFixed(2) ?? 0}
            </Typography>
          </Box>
        </Card>
      </Grid>
      <Grid item md={4}>
        <Card
          sx={{
            p: "1.5rem",
          }}
        >
          <Box display="flex" flexDirection="column" gap={1}>
            <DoubleArrowIcon color="primary" fontSize="large" />
            <Typography>Next Payment</Typography>
            <Typography variant="h5" fontWeight="bold">
              {stats.lastPayment ? add14Days(stats.lastPayment) : "____"}
            </Typography>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}

export default ReportsCards;
