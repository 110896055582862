import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ProductList from "components/Collection/AddCollectionProducts/ProductList";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import { memo, useCallback, useState } from "react";
import UploadCollectionCsv from "./UploadCollectionCsv";

function AddCollectionProducts() {
  const [value, setValue] = useState("productListUpload");

  const handleChange = useCallback((event) => {
    const newValue = event.target.value;
    setValue(newValue);
  }, []);

  return (
    <Box py={2} px={1}>
      <Box display="flex" alignItems="center">
        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            fontSize: { md: "1rem", lg: "1.5rem" },
            paddingRight: "1rem",
          }}
        >
          COLLECTIONS
        </Typography>
        <Box paddingTop={2} paddingBottom={1}>
          <CustomBreadcrumbs />
        </Box>
      </Box>
      <Typography
        variant="h1"
        color="primary"
        fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.6rem" }}
        fontWeight={500}
        mb={1}
      >
        Collection Products
      </Typography>
      <StyledMuiCard
        title="Select Upload Method"
        subTitle="Please select at least one option to upload products"
      >
        <CardContent>
          <Stack
            direction="column"
            justifyContent="center"
            gap={3}
            p={{ xs: 1, md: 1.5 }}
          >
            <FormControl>
              <RadioGroup
                value={value}
                onChange={handleChange}
                sx={{ display: "flex", flexDirection: "row" }}
              >
                <FormControlLabel
                  value="productListUpload"
                  control={<Radio />}
                  label="Select Product"
                />
                <FormControlLabel
                  value="csvUpload"
                  control={<Radio />}
                  label="Upload CSV File"
                />
              </RadioGroup>
            </FormControl>
          </Stack>
        </CardContent>
      </StyledMuiCard>
      {value === "csvUpload" ? (
        <UploadCollectionCsv />
      ) : value === "productListUpload" ? (
        <ProductList />
      ) : null}
    </Box>
  );
}

export default memo(AddCollectionProducts);
