import grey from "@mui/material/colors/grey";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import CardTitle from "components/CardTitle";
import { capitalize } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  padding: "1rem",
  display: "flex",
  height: "100%",
  width: "100%",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: 1,
}));

function InsightsBarChart({ title, data }) {
  const chartRef = useRef(null);
  const [chartWidth, setChartWidth] = useState("100%");
  const isOpen = useSelector((state) => state.UI.isSidebarOpen);

  const countries = Object.keys(data);
  const totalSales = countries.map(
    (country) => parseInt(data[country].totalSale) ?? 0
  );
  const profits = countries.map(
    (country) => parseInt(data[country].profit) ?? 0
  );

  const options = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        borderRadius: 6,
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "last",
      },
    },
    xaxis: {
      categories: countries.map((country) => capitalize(country)),
      labels: {
        style: {
          fontWeight: 500,
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return parseInt(value).toLocaleString();
        },
      },
    },
    dataLabels: {
      formatter: function (value) {
        return parseInt(value).toLocaleString();
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetY: 10,
      itemMargin: {
        horizontal: 10,
        vertical: 5,
      },
    },
    fill: {
      opacity: 1,
    },
    responsive: [
      {
        breakpoint: 1000,
        options: {
          plotOptions: {
            bar: {
              columnWidth: "70%",
            },
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const series = [
    {
      name: "Total Sale",
      data: totalSales,
    },
    {
      name: "Profit",
      data: profits,
    },
  ];

  useEffect(() => {
    if (chartRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const { width } = entry.contentRect;
          setChartWidth(() => width);
        }
      });

      resizeObserver.observe(chartRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  if (!data) return null;

  return (
    <StyledPaper ref={chartRef}>
      <CardTitle title={title} color={grey[700]} />
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={isOpen ? 378 : 361}
        width={chartWidth}
      />
    </StyledPaper>
  );
}

InsightsBarChart.propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

export default InsightsBarChart;
