import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import { memo, useCallback, useLayoutEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { FILTERS_BACKGROUND_COLOR } from "styles/colors";
import useQueryParams from "utils/hooks/useQueryParams";

const TabFilters = ({ queryKey, tabOptions, defaultTab }) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const [value, setValue] = useState(defaultTab.value);

  useLayoutEffect(() => {
    const currentQueryValue = queryParams?.[queryKey];
    if (currentQueryValue) {
      const matchedTab = tabOptions.find(
        (tab) => String(tab.value) === currentQueryValue
      );
      setValue(matchedTab ? matchedTab?.value : defaultTab.value);
    } else {
      setValue(defaultTab.value);
    }
  }, [queryKey, tabOptions, defaultTab, queryParams]);

  const handleChange = useCallback(
    (e, newValue) => {
      setValue(newValue);
      const params = { ...queryParams };

      if (params[queryKey]) delete params[queryKey];

      if (newValue !== defaultTab?.value) {
        params[queryKey] = newValue;
      }

      navigate({
        search: createSearchParams(params).toString(),
      });
    },
    [defaultTab, navigate, queryKey, queryParams]
  );

  return (
    <Box id="tab-filters" py={1} display="flex" alignItems="baseline">
      <Box
        sx={{
          marginBottom: { xs: 2, md: 0 },
          backgroundColor: FILTERS_BACKGROUND_COLOR,
          "& .MuiTab-root": { textTransform: "capitalize" },
          maxWidth: "100%",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label={`${defaultTab.label} filter`}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          <Tab label={defaultTab.label} value={defaultTab.value} />
          {tabOptions.map(({ label, value }) => (
            <Tab key={value} label={label} value={value} />
          ))}
        </Tabs>
      </Box>
    </Box>
  );
};

TabFilters.propTypes = {
  queryKey: PropTypes.string.isRequired,
  tabOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  defaultTab: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
};

export default memo(TabFilters);
