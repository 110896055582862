import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CustomProductSearch from "components/DetailedDataGrid/CustomProductSearch";
import StyledMuiCard from "components/Product/BulkUploadCard/StyledMuiCard";
import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getCollectionById,
  resetCollection,
} from "store/slices/collectionSlice";
import DeleteCollectionProducts from "../DeleteCollectionProducts/DeleteCollectionProducts";
import CollectionProductsDataGrid from "./CollectionProductsDataGrid";

function ProductList() {
  const params = useParams();
  const dispatch = useDispatch();
  const collectionProducts = useSelector(
    (state) => state.collection?.collection?.products ?? []
  );

  useEffect(() => {
    const id = setTimeout(() => {
      dispatch(getCollectionById(params?.id));
    }, 250);

    return () => {
      if (id) {
        clearTimeout(id);
        dispatch(resetCollection());
      }
    };
  }, [dispatch, params?.id]);

  return (
    <Box py={2} px={2}>
      <Typography variant="h4" fontWeight="700" color="primary.main" py={2}>
        Product Upload
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <StyledMuiCard
            title="Select Products"
            subTitle="Add required products for collection"
          >
            <CardContent>
              <Stack
                direction="row"
                gap={3}
                p={{ xs: 1, md: 1.5 }}
                maxHeight={600}
                sx={{ overflowY: "scroll", overflowX: "hidden" }}
              >
                <Grid item xs={12} lg={12}>
                  <CustomProductSearch />
                  <CollectionProductsDataGrid />
                </Grid>
              </Stack>
            </CardContent>
          </StyledMuiCard>
        </Grid>
      </Grid>
      {params?.id && (
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            {/*Remove Collection Products Section */}
            <DeleteCollectionProducts collectionProducts={collectionProducts} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export default memo(ProductList);
