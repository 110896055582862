import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import StyledSwitch from "components/ToggleButton";
import { FastField, useFormikContext } from "formik";
import { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { CURRENCY } from "utils/constants/constants";
import { calculatedPrice } from "utils/helpers/calculatedPremiumPrice";
import isValidNumber from "utils/helpers/isValidNumber";

function PriceSection() {
  const pricingSummary = useSelector((state) => state.products.pricingSummary);

  const { values, touched, errors, setFieldValue, setFieldTouched } =
    useFormikContext();

  const SalePriceField = useMemo(
    () => (
      <StyledTextField
        fullWidth
        type="number"
        name="salePrice"
        value={values.salePrice}
        onChange={(e) => {
          isValidNumber(e.target.value) &&
            setFieldValue("salePrice", e.target.value);
        }}
        onBlur={() => setFieldTouched("salePrice", true)}
        disabled={!values?.onSale}
        error={Boolean(touched?.salePrice && errors?.salePrice)}
        helperText={
          touched?.salePrice && !!errors?.salePrice
            ? errors.salePrice
            : "Sales price must be lower than the regular price"
        }
        py={2}
        sx={{
          "& .MuiOutlinedInput-input": {
            lineHeight: 1,
            fontSize: "2rem",
            fontWeight: "bold",
            color: "text.secondary",
            height: "auto",
            padding: "0.5rem 0.75rem",
            textAlign: "right",
          },
        }}
        InputProps={{
          startAdornment: (
            <Typography
              color="text.disabled"
              sx={{
                fontSize: "1.25rem",
                borderRight: "1px solid #00000038",
                paddingRight: "1rem",
              }}
            >
              {CURRENCY}
            </Typography>
          ),
        }}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errors.salePrice, touched?.salePrice, values.salePrice, values.onSale]
  );

  return (
    <StyledMuiCard
      title="Pricing"
      subTitle="Enter price and sale price of the product"
    >
      <CardContent>
        <Stack
          direction="column"
          justifyContent="center"
          gap={1}
          p={{ xs: 1, md: 1.5 }}
        >
          <Typography
            variant="h3"
            fontWeight="bold"
            fontSize="1.25rem"
            color="text.secondary"
          >
            Price
          </Typography>
          <Box>
            <FastField name="price">
              {({ field, meta }) => (
                <StyledTextField
                  {...field}
                  fullWidth
                  type="number"
                  onChange={(e) => {
                    isValidNumber(e.target.value) && field.onChange(e);
                  }}
                  error={Boolean(meta.touched && meta.error)}
                  helperText={
                    meta.touched && !!meta.error
                      ? meta.error
                      : "Please enter the product price"
                  }
                  py={0}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      lineHeight: 1,
                      fontSize: "2rem",
                      fontWeight: "bold",
                      color: "text.secondary",
                      height: "auto",
                      padding: "0.5rem 0.75rem",
                      textAlign: "right",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <Typography
                        color="text.disabled"
                        sx={{
                          fontSize: "1.25rem",
                          borderRight: "1px solid #00000038",
                          paddingRight: "1rem",
                        }}
                      >
                        {CURRENCY}
                      </Typography>
                    ),
                  }}
                />
              )}
            </FastField>

            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ marginTop: "0.2rem" }}
            >
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.secondary"
                padding={0}
              >
                Grand Total:
              </Typography>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.secondary"
              >
                {CURRENCY}{" "}
                {values.price === 0 || values.price === ""
                  ? 0
                  : calculatedPrice(values.price, pricingSummary)}
              </Typography>
            </Stack>
          </Box>

          <Stack direction="row" py={1} justifyContent="space-between" mt={1}>
            <Box>
              <Typography
                variant="h3"
                fontWeight="bold"
                fontSize="1.25rem"
                color="text.secondary"
              >
                Sales Price
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                pt={1}
                fontSize="1rem"
              >
                Enable this option if you want to put product on sales
              </Typography>
            </Box>
            <FastField name="onSale">
              {({ field, meta }) => (
                <StyledSwitch
                  {...field}
                  onChange={field.onChange}
                  checked={meta.value}
                />
              )}
            </FastField>
          </Stack>
          <Box>
            {SalePriceField}
            <Stack direction="row" justifyContent="space-between">
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.secondary"
              >
                Grand Total:
              </Typography>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.secondary"
              >
                {CURRENCY}{" "}
                {values.salePrice === 0 ||
                values.salePrice === "" ||
                values.salePrice === "0" ||
                !values.onSale
                  ? "0"
                  : calculatedPrice(values.salePrice, pricingSummary)}
              </Typography>
            </Stack>
          </Box>
        </Stack>
      </CardContent>
    </StyledMuiCard>
  );
}
export default memo(PriceSection);
