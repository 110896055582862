import { Card, CardContent, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts";

const OrderStatusPieChart = () => {
  const stats = useSelector((state) => state.reports.stats);

  const keysToMap = [
    "new",
    "completed",
    "confirmed",
    "ready",
    "cancel",
    "delivered",
  ];

  const chartData = Object.entries(stats)
    .filter(([key]) => keysToMap.includes(key))
    .map(([key, value]) => ({
      name: key.charAt(0).toUpperCase() + key.slice(1),
      value: parseFloat(((value / stats.total) * 100).toFixed(1)), // Convert to number
    }));

  const COLORS = [
    "#36A2EB",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#FF6384",
    "#951be7",
  ];
  return (
    <Card sx={{ maxWidth: 1000, margin: "auto", padding: 2 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Orders
        </Typography>
        <PieChart width={600} height={450}>
          <Pie
            data={chartData}
            cx={300}
            cy={200}
            innerRadius={80}
            outerRadius={160}
            fill="#8884d8"
            dataKey="value"
            label={({ value }) => `${value}%`}
            paddingAngle={5}
          >
            {chartData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
          <Legend verticalAlign="bottom" height={36} />
        </PieChart>
      </CardContent>
    </Card>
  );
};

export default OrderStatusPieChart;
