import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { uploadGalleryImages, uploadMainImage } from "services/Product";
import { getErrorMessage, getResponseData } from "utils/helpers/apiDataHelpers";
import { toast } from "utils/hooks/useToast";
import http from "utils/httpRequest/http";
import httpv2 from "utils/httpRequest/httpv2";

export const getAdminProducts = createAsyncThunk(
  "products/getAdminProducts",
  async (data, { getState, rejectWithValue }) => {
    const { page, limit } = getState().products;
    const { userRole } = getState().auth;
    const url = userRole === "admin" ? "/products/admin" : `/products/seller`;
    try {
      const response = await httpv2.get(url, {
        params: {
          page: data?.page ?? page,
          limit: data?.limit ?? limit,
          sortBy: "-createdAt",
          ...data,
        },
      });
      const responseData = getResponseData(response);
      if (responseData?.results) {
        responseData.results?.forEach((item, index) => {
          const pageStart = (responseData.page - 1) * responseData.limit;
          item.serialNumber = pageStart + index + 1;
        });
      }
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const addFeaturedProduct = createAsyncThunk(
  "products/addFeaturedProduct",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.post(`/products/featuredProducts`, {
        productId: data.id,
      });
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const removeFeaturedProduct = createAsyncThunk(
  "products/removeFeaturedProduct",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.delete(
        `/products/featuredProducts/${data.id}`
      );
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const deleteBulkProducts = createAsyncThunk(
  "products/bulk",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.delete("/products/bulk", {
        data: data,
      });
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const bulkUploadProducts = createAsyncThunk(
  "products/bulkUploadProducts",
  async ({ data, storeId }, { getState, rejectWithValue }) => {
    try {
      const id = storeId || getState().auth.id;
      const response = await http.post(`products/bulk-upload/${id}`, data);
      const responseData = getResponseData(response);
      if (responseData?.status >= 400) {
        toast.error(responseData?.message);
        return rejectWithValue(responseData?.message);
      }
      toast.success("CSV file uploaded successfully");
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getShopifyToken = createAsyncThunk(
  "products/getShopifyToken",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.get(`shopify`);
      const responseData = getResponseData(response);
      if (responseData?.status >= 400) {
        toast.error(responseData?.message);
        return rejectWithValue(responseData?.message);
      }
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      // toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const createShopifyToken = createAsyncThunk(
  "products/createShopifyToken",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.post(`/shopify`, data);
      const responseData = getResponseData(response);
      if (responseData?.status >= 400) {
        toast.error(responseData?.message);
        return rejectWithValue(responseData?.message);
      }
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const updateShopifyToken = createAsyncThunk(
  "products/updateShopifyToken",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.patch(
        `shopify/${data.shopifyId}`,
        data?.updatedValues
      );
      const responseData = getResponseData(response);
      if (responseData?.status >= 400) {
        toast.error(responseData?.message);
        return rejectWithValue(responseData?.message);
      }
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getShopifyProducts = createAsyncThunk(
  "products/getShopifyProducts",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.post(`/shopify/products`, data);
      const responseData = getResponseData(response);
      if (responseData?.status >= 400) {
        toast.error(responseData?.message);
        return rejectWithValue(responseData?.message);
      }
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getProductById = createAsyncThunk(
  "products/getProductById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await http.get(`/products/${id}`);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const updateBulkProducts = createAsyncThunk(
  "products/updateBulkProducts",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.patch(`/products/bulk`, data);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

const transformPricingSummary = (responseData) => {
  return responseData?.reduce((prev, curr) => {
    prev[curr.label] = curr.keyValue;
    return prev;
  }, {});
};

export const getPricingSummary = createAsyncThunk(
  "products/getPricingSummary",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.get("/setting/prices", {
        params: {
          origin: data.origin,
          sellerId: data.sellerId,
        },
      });
      const responseData = getResponseData(response);
      const pricingSummaryObject = transformPricingSummary(responseData);
      return pricingSummaryObject;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const updateProductAmountSeller = createAsyncThunk(
  "products/updateProductAmountSeller",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.patch("/products/premium", data);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const getProductAfterVariantUpdate = createAsyncThunk(
  "products/getProductAfterVariantUpdate",
  async (id, { rejectWithValue }) => {
    try {
      const response = await http.get(`/products/${id}`);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      // toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const addProduct = createAsyncThunk(
  "products/addProduct",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.post(`/products`, data);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(
        message === "User not found" ? "The store does not exist" : message
      );
      return rejectWithValue(message);
    }
  }
);

export const updateProduct = createAsyncThunk(
  "products/updateProduct",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.patch(
        `/products/${data.productId}`,
        data.body
      );
      const responseData = getResponseData(response);
      toast.success("Product updated successfully");
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const quickUpdateProduct = createAsyncThunk(
  "products/quickUpdateProduct",
  async (data, { getState, rejectWithValue }) => {
    const products = getState().products.results;
    try {
      const response = await http.patch(
        `/products/${data.productId}`,
        data.body
      );
      const responseData = getResponseData(response);
      const _products = products.map((product) => {
        if (product.id === responseData.id) {
          const [key] = Object.keys(data.body);
          return { ...product, [key]: responseData[key] };
        }
        return product;
      });
      toast.success("Product updated successfully");
      return _products;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "products/deleteProduct",
  async (productId, { getState, rejectWithValue }) => {
    const { results, page, limit } = getState().products;
    try {
      await http.delete(`/products/${productId}`);
      toast.success("Deleted successfully");
      let returnedProducts = results.filter((data) => data.id !== productId);
      if (returnedProducts) {
        returnedProducts = returnedProducts?.map((item, index) => {
          const pageStart = (page - 1) * limit;
          return {
            ...item,
            serialNumber: pageStart + index + 1,
          };
        });
      }
      return returnedProducts;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const addProductVariant = createAsyncThunk(
  "products/addVariant",
  async (
    { mainImage = "", gallery = [], ...data },
    { getState, rejectWithValue }
  ) => {
    const product = getState().products.product;
    try {
      let response = await http.post(`/products/variants/${product?.id}`, {
        sellerId: product?.user?.id ?? "",
        variants: [
          {
            ...data,
            productName: product?.productName,
            description: product?.description,
            weight: product?.packageInfo?.weight,
          },
        ],
      });
      const id = getResponseData(response)?.[0]?.id;
      if (typeof mainImage === "object") {
        response = await uploadMainImage(id, mainImage);
      }
      let galleryMedia = [];
      gallery?.forEach((image) => {
        if (typeof image === "object") {
          galleryMedia.push(image);
        }
      });
      if (galleryMedia?.length) {
        response = await uploadGalleryImages(id, galleryMedia);
      }

      const responseData = getResponseData(response);

      toast.success("Product variant added successfully");
      return [...(product?.variants ?? []), responseData];
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const updateVariantProduct = createAsyncThunk(
  "products/updateVariantProduct",
  async (
    { id, mainImage = "", gallery = [], ...data },
    { getState, rejectWithValue }
  ) => {
    const product = getState().products.product;
    try {
      let response = null;
      if (Object.keys(data).length) {
        response = await http.patch(`/products/${id}`, {
          ...data,
        });
      }
      if (typeof mainImage === "object") {
        response = await uploadMainImage(id, mainImage);
      }
      let galleryMedia = [];
      gallery?.forEach((image) => {
        if (typeof image === "object") {
          response = galleryMedia.push(image);
        }
      });
      if (galleryMedia?.length) {
        uploadGalleryImages(id, galleryMedia);
      }

      toast.success("Product variant updated successfully");

      const responseData = getResponseData(response);

      const variants = product.variants.map((variant) => {
        if (variant.id === responseData?.id) {
          return responseData;
        }
        return variant;
      });
      return variants;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const deleteVariantProduct = createAsyncThunk(
  "products/deleteVariantProduct",
  async (variantId, { getState, rejectWithValue }) => {
    const product = getState().products.product;
    try {
      await http.delete(`/products/${variantId}`);
      toast.success("Product variant deleted");
      let variantProducts = product.variants.filter(
        (variant) => variant.id !== variantId
      );
      return variantProducts ?? [];
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getAliexpressCollections = createAsyncThunk(
  "products/getAliexpressCollections",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      let res = await http.get("/shop-china/feed-names");
      const responseData = getResponseData(res);
      const feedName = responseData?.feeds[0].promo_name || "3SproductsUS";
      dispatch(setActiveFeedName(feedName));
      dispatch(
        getAliexpressProductsByCollection({ page: 1, limit: 20, feedName })
      );
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const getAliexpressProductsByCollection = createAsyncThunk(
  "products/getAliexpressProductsByCollection",
  async (data, { rejectWithValue, getState }) => {
    const { page, limit } = getState().products;
    const url = "/shop-china/feed";
    try {
      const response = await http.get(url, {
        params: {
          page: data.page ?? page,
          limit: data.limit ?? limit,
          feed_name: data.feedName || "3SproductsUS",
        },
      });
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const importAliExpressProducts = createAsyncThunk(
  "products/importAliExpressProducts",
  async (feedName, { rejectWithValue }) => {
    const url = "ae-feed";
    try {
      const response = await http.post(url, {
        name: feedName,
      });
      const responseData = getResponseData(response);
      toast.success("Successfully Imported");
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const exportProductsCsv = createAsyncThunk(
  "products/exportProductsCsv",
  async (params, { rejectWithValue }) => {
    try {
      const response = await http.get("/csv-exports", {
        params: {
          csvType: "products",
          ...params,
        },
      });
      const responseData = getResponseData(response);
      toast.success("Products CSV has been successfully sent to your email");
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

const productsSlice = createSlice({
  name: "products",
  initialState: {
    loading: true,
    shopifyProducts: false,
    productLoading: false,
    productVariantLoading: false,
    pricingSummary: {},
    product: null,
    sellerId: "",
    results: [],
    bulkProducts: [],
    page: 1,
    limit: 20,
    totalPages: 0,
    totalResults: 0,
    error: "",
    productError: "",
    shopify: {},
    selectedAttributes: null,
    variantIndex: "-1",
    activeFeedName: "",
    aliExpressProductsLoading: false,
    aliExpressCollectionLoading: false,
    aliexpressCollectionsError: "",
    aliexpressCollections: [],
    aliexpressProductsByCollection: [],
    aliexpressProductsByCollectionError: "",
    exportCsv: {
      loading: false,
      success: false,
      error: "",
    },
  },
  reducers: {
    // Actions
    resetState: (state) => {
      state.loading = true;
      state.productLoading = false;
      state.results = [];
      state.page = 1;
      state.limit = 20;
      state.totalPages = 0;
      state.totalResults = 0;
      state.error = null;
      state.shopify = {};
      state.shopify = false;
    },
    resetProductState: (state) => {
      state.productLoading = false;
      state.product = null;
      state.productError = null;
    },
    setStoreId: (state, action) => {
      state.product = { ...state.product, userId: action.payload };
      state.sellerId = action.payload;
    },
    resetShopify: (state) => {
      state.shopify = {};
      state.shopifyProducts = false;
    },
    setSelectedAttributes: (state, action) => {
      state.selectedAttributes = action.payload;
    },
    setVariantIndex: (state, action) => {
      state.variantIndex = action.payload;
    },
    setActiveFeedName: (state, action) => {
      state.activeFeedName = action.payload;
    },
    resetExportCsvState: (state) => {
      state.exportCsv.loading = false;
      state.exportCsv.success = false;
      state.exportCsv.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdminProducts.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getAdminProducts.fulfilled, (state, action) => {
        state.results = action.payload.results;
        state.totalResults = action.payload.totalResults;
        state.totalPages = action.payload.totalPages;
        state.page = action.payload.page;
        state.limit = action.payload.limit;
        state.loading = false;
      })
      .addCase(getAdminProducts.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(bulkUploadProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(bulkUploadProducts.fulfilled, (state, action) => {
        state.bulkProducts = action.payload;
        state.loading = false;
      })
      .addCase(bulkUploadProducts.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.results = action.payload;
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(addProduct.pending, (state) => {
        state.productLoading = true;
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        state.productLoading = false;
        state.product = action.payload;
      })
      .addCase(addProduct.rejected, (state, action) => {
        state.productLoading = false;
        state.productError = action.payload;
      })
      .addCase(getPricingSummary.pending, (state) => {
        state.productLoading = true;
      })
      .addCase(getPricingSummary.fulfilled, (state, action) => {
        state.productLoading = false;
        state.pricingSummary = action.payload;
      })
      .addCase(getPricingSummary.rejected, (state, action) => {
        state.productLoading = false;
        state.productError = action.payload;
      })
      .addCase(updateBulkProducts.pending, (state) => {
        state.productLoading = true;
      })
      .addCase(updateBulkProducts.fulfilled, (state, action) => {
        state.productLoading = false;
        state.product = action.payload;
      })
      .addCase(updateBulkProducts.rejected, (state, action) => {
        state.productLoading = false;
        state.productError = action.payload;
      })
      .addCase(updateProduct.pending, (state) => {
        state.productLoading = true;
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.productLoading = false;
        state.product = action.payload;
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.productLoading = false;
        state.productError = action.payload;
      })
      .addCase(getProductById.pending, (state) => {
        state.productLoading = true;
        state.productError = null;
      })
      .addCase(getProductById.fulfilled, (state, action) => {
        state.productLoading = false;
        state.product = action.payload;
      })
      .addCase(getProductById.rejected, (state, action) => {
        state.productLoading = false;
        state.productError = action.payload;
      })
      .addCase(quickUpdateProduct.pending, (state) => {
        state.error = null;
      })
      .addCase(quickUpdateProduct.fulfilled, (state, action) => {
        state.results = action.payload;
      })
      .addCase(quickUpdateProduct.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(getShopifyToken.pending, (state) => {
        state.loading = true;
        state.productError = null;
      })
      .addCase(getShopifyToken.fulfilled, (state, action) => {
        state.loading = false;
        state.shopify = action.payload;
      })
      .addCase(getShopifyToken.rejected, (state, action) => {
        state.loading = false;
        state.productError = action.payload;
      })
      .addCase(getShopifyProducts.pending, (state) => {
        state.shopifyProducts = true;
      })
      .addCase(getShopifyProducts.fulfilled, (state) => {
        state.shopifyProducts = false;
      })
      .addCase(getShopifyProducts.rejected, (state) => {
        state.shopifyProducts = false;
      })
      .addCase(getProductAfterVariantUpdate.pending, (state) => {
        state.productLoading = true;
        state.productError = null;
      })
      .addCase(getProductAfterVariantUpdate.fulfilled, (state, action) => {
        state.productLoading = false;
        state.product.variants = action.payload?.variants ?? [];
      })
      .addCase(getProductAfterVariantUpdate.rejected, (state, action) => {
        state.productLoading = false;
        state.productError = action.payload;
      })
      .addCase(addProductVariant.pending, (state) => {
        state.productVariantLoading = true;
        state.productError = "";
      })
      .addCase(addProductVariant.fulfilled, (state, action) => {
        state.productVariantLoading = false;
        state.product.variants = action.payload ?? [];
        state.selectedAttributes = null;
      })
      .addCase(addProductVariant.rejected, (state, action) => {
        state.productVariantLoading = false;
        state.productError = action.payload;
      })
      .addCase(updateVariantProduct.pending, (state) => {
        state.productVariantLoading = true;
        state.productError = "";
      })
      .addCase(updateVariantProduct.fulfilled, (state, action) => {
        state.productVariantLoading = false;
        state.product.variants = action.payload;
        state.variantIndex = "-1";
      })
      .addCase(updateVariantProduct.rejected, (state, action) => {
        state.productVariantLoading = false;
        state.productError = action.payload;
      })
      .addCase(deleteVariantProduct.pending, (state) => {
        state.productVariantLoading = true;
        state.productError = "";
      })
      .addCase(deleteVariantProduct.fulfilled, (state, action) => {
        state.productVariantLoading = false;
        state.product.variants = action.payload;
      })
      .addCase(deleteVariantProduct.rejected, (state, action) => {
        state.productVariantLoading = false;
        state.productError = action.payload;
      })
      .addCase(getAliexpressCollections.pending, (state, action) => {
        state.aliExpressCollectionLoading = true;
        state.aliexpressCollectionsError = action.payload;
      })
      .addCase(getAliexpressCollections.fulfilled, (state, action) => {
        state.aliExpressCollectionLoading = false;
        state.aliexpressCollections = action.payload;
      })
      .addCase(getAliexpressCollections.rejected, (state, action) => {
        state.aliExpressCollectionLoading = false;
        state.aliexpressCollectionsError = action.payload;
      })
      .addCase(getAliexpressProductsByCollection.pending, (state, action) => {
        state.aliExpressProductsLoading = true;
        state.aliexpressProductsByCollectionError = action.payload;
      })
      .addCase(getAliexpressProductsByCollection.fulfilled, (state, action) => {
        state.aliExpressProductsLoading = false;
        state.aliexpressProductsByCollection = action.payload;
        state.totalPages = action.payload.totalProducts;
        state.page = action.payload.page;
        state.limit = action.payload.limit;
      })
      .addCase(getAliexpressProductsByCollection.rejected, (state, action) => {
        state.aliExpressProductsLoading = false;
        state.aliexpressProductsByCollectionError = action.payload;
      })
      .addCase(importAliExpressProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(importAliExpressProducts.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(importAliExpressProducts.rejected, (state) => {
        state.loading = false;
      })
      .addCase(exportProductsCsv.pending, (state) => {
        state.exportCsv.loading = true;
        state.exportCsv.success = false;
        state.exportCsv.error = "";
      })
      .addCase(exportProductsCsv.fulfilled, (state) => {
        state.exportCsv.loading = false;
        state.exportCsv.success = true;
      })
      .addCase(exportProductsCsv.rejected, (state, action) => {
        state.exportCsv.loading = false;
        state.exportCsv.success = false;
        state.exportCsv.error = action.payload;
      });
  },
});

// Action creators generated for each case reducer function
export const {
  resetState,
  resetProductState,
  setStoreId,
  resetShopify,
  setSelectedAttributes,
  setVariantIndex,
  setActiveFeedName,
  resetExportCsvState,
} = productsSlice.actions;

// Exporting default reducer
export default productsSlice.reducer;
