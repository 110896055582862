import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import deleteIcon from "assets/icons/deleteIcon.svg";
import WritingIcon from "assets/icons/WritingIcon.svg";
import placeholderImg from "assets/images/imagePlaceholder.png";
import ConfirmDelete from "components/Dialog/ConfirmDelete";
import StyledDataGrid from "components/StyledGrid";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteStore, getStores } from "store/slices/storesSlice";

import {
  setConfirmDeletionDialogOpen,
  setDeleteId,
} from "store/slices/uiSlice";
import useQueryParams from "utils/hooks/useQueryParams";

function StoreDataGrid() {
  const params = useQueryParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { results, loading, totalPages } = useSelector((state) => state.stores);

  useEffect(() => {
    dispatch(getStores(params));
  }, [dispatch, params]);

  const handleClickOpen = useCallback(
    (storeId) => {
      dispatch(setConfirmDeletionDialogOpen());
      dispatch(setDeleteId(storeId));
    },
    [dispatch]
  );

  const columns = useMemo(
    () => [
      {
        field: "serialNumber",
        headerName: "Sr",
        minWidth: 45,
        flex: 0.3,
        filterable: false,
        renderCell: (params) => (
          <Typography>{params?.row?.serialNumber}</Typography>
        ),
      },
      {
        field: "image",
        headerName: "Image",
        minWidth: 100,
        flex: 0.4,
        sortable: false,
        renderCell: (params) => (
          <Box>
            <img
              src={params?.row?.logo ? params?.row?.logo : placeholderImg}
              height={50}
              width={50}
              style={{
                marginTop: 7,
                objectFit: "contain",
              }}
              alt={params?.row?.brandName}
            />
          </Box>
        ),
      },
      {
        field: "brandName",
        headerName: "Name",
        minWidth: 100,
        flex: 0.6,
        renderCell: (params) => (
          <Typography>{params?.row?.brandName}</Typography>
        ),
      },
      {
        field: "address",
        headerName: "Address",
        minWidth: 150,
        flex: 1.25,
        renderCell: (params) => (
          <>
            <Box>
              <Typography textTransform="capitalize">
                {params?.row?.address}
              </Typography>
              <Typography textTransform="capitalize">
                Province: {params?.row?.province}
              </Typography>
              <Typography textTransform="capitalize">
                City: {params?.row?.city}
              </Typography>
              <Typography textTransform="capitalize">
                Area: {params?.row?.area}
              </Typography>
            </Box>
          </>
        ),
      },
      {
        field: "createdAt",
        headerName: "Date",
        minWidth: 30,
        flex: 0.5,
        renderCell: (params) => (
          <Typography>{params?.row?.createdAt?.substring(0, 10)}</Typography>
        ),
      },
      {
        field: "market",
        headerName: "Market",
        minWidth: 30,
        flex: 0.5,
        sortable: false,
        renderCell: (params) => (
          <Typography>
            {params?.row?.marketObj && params?.row?.marketObj?.name}
          </Typography>
        ),
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        minWidth: 30,
        flex: 0.5,
        sortable: false,
        renderCell: (params) => (
          <Box display="flex" sx={actionStyles}>
            <IconButton
              variant="text"
              className="button-styles"
              onClick={() => handleClickOpen(params?.id)}
            >
              <img src={deleteIcon} alt="delete" className="icon-size" />
            </IconButton>
            <IconButton
              variant="text"
              className="button-styles"
              onClick={() => navigate(`update/${params?.id}`)}
            >
              <img src={WritingIcon} alt="edit" className="icon-size" />
            </IconButton>
          </Box>
        ),
      },
    ],
    [handleClickOpen, navigate]
  );

  return (
    <>
      <StyledDataGrid
        rows={results}
        columns={columns}
        loading={loading}
        totalPages={totalPages}
      />
      <ConfirmDelete deleteAction={deleteStore} />
    </>
  );
}

export default memo(StoreDataGrid);

const actionStyles = {
  "& .button-styles": { minWidth: "20px", marginLeft: "4px" },
  "& .icon-size": { height: "25px", width: "25px" },
};
