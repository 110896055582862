import dayjs from "dayjs";

// function to get response data
export const getResponseData = (response) =>
  response?.data?.data ?? response?.data ?? response;

export const getErrorData = (error) =>
  error?.response?.data?.data ??
  error?.response?.data ??
  error?.response ??
  error;

export const getErrorMessage = (error) =>
  error?.response?.data?.userMessage?.userMessage ??
  error?.response?.data?.userMessage ??
  error?.response?.userMessage ??
  error?.response?.data?.data?.message ??
  error?.response?.data?.message ??
  error?.response?.message ??
  error?.message ??
  "Something went wrong, please try again later";

/**
 * Formats date parameters for API requests with flexible parameter naming
 * @param {Object} data - The input data object
 * @param {string} [data.from] - Start date when using from/to convention
 * @param {string} [data.to] - End date when using from/to convention
 * @param {boolean} [useStartEndDate=false] - If true, uses startDate/endDate naming convention. If false, uses from/to
 * @returns {Object} Formatted parameters object with dates in required format
 */
export const getFormattedDateParams = (data, useStartEndDate = false) => {
  const { from, to } = data;
  let params = {};
  const today = dayjs();

  // Set parameter keys based on naming convention
  const fromKey = useStartEndDate ? "startDate" : "from";
  const toKey = useStartEndDate ? "endDate" : "to";

  // Case 1: No dates provided - default to last 30 days
  if (!from && !to) {
    params = {
      [fromKey]: today.subtract(1, "month").format("YYYY-MM-DD"),
      [toKey]: today.format("YYYY-MM-DD"),
    };
  }
  // Case 2: Start date is of today - return only start date
  else if (dayjs(from).isSame(today, "day")) {
    params = {
      [fromKey]: dayjs(from).startOf("day").format("YYYY-MM-DD HH:mm"),
      ...(useStartEndDate && {
        [toKey]: dayjs(to).endOf("day").format("YYYY-MM-DD HH:mm"),
      }),
    };
  }
  // Case 3: Same date for start and end - return full day range with time
  else if (from && to && dayjs(from).isSame(dayjs(to), "day")) {
    params = {
      [fromKey]: dayjs(from).startOf("day").format("YYYY-MM-DD HH:mm"),
      [toKey]: dayjs(to).endOf("day").format("YYYY-MM-DD HH:mm"),
    };
  }
  // Case 4: Different dates - return in YYYY-MM-DD format
  else if (from && to) {
    params = {
      [fromKey]: dayjs(from).format("YYYY-MM-DD"),
      [toKey]: dayjs(to).format("YYYY-MM-DD"),
    };
  }

  // Ensure end date never exceeds current date
  if (params?.[toKey] && dayjs(params[toKey]).isAfter(today, "day")) {
    params[toKey] = today.format("YYYY-MM-DD");
  }

  return params;
};

/**
 * Determines the date format based on the time range between dates
 * @param {Object} data - Object containing from/to or startDate/endDate
 * @returns {Object} Object with format key set to either 'month' or 'day'
 */
export const getDateRangeFormat = (data) => {
  const { from, to } = data;

  const fromDate = dayjs(from ?? dayjs());
  const toDate = dayjs(to ?? dayjs());

  // Calculate the difference in months
  const monthsDiff = toDate.diff(fromDate, "month");

  return monthsDiff >= 18 ? "month" : "day";
};
