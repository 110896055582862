import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MarketingInsights from "components/Marketing/MarketingInsights";
import DateRange from "components/Pickers/DateRange";
import dayjs from "dayjs";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import { getInsights } from "store/slices/insightsSlice";
import useQueryParams from "utils/hooks/useQueryParams";

const userInsightsKeyValues = {
  activeUsers: "activeUsers",
  avgSessionDuration: "averageSessionDuration",
  totalUsers: "totalUsers",
  checkouts: "checkouts",
};

function Marketing() {
  const params = useQueryParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { insights } = useSelector((state) => state.insights);

  useEffect(() => {
    const data = {
      keywords: [...Object.values(userInsightsKeyValues)].map((keyword) => ({
        name: keyword,
      })),

      ...(params?.from && { from: params.from }),
      ...(params?.to && { to: params.to }),
    };

    dispatch(getInsights(data));
  }, [dispatch, params.from, params.to]);

  const handleDateChange = useCallback(
    (range) => {
      navigate({
        search: createSearchParams({
          ...params,
          from: dayjs(range.startDate).format("YYYY-MM-DD"),
          to: dayjs(range.endDate).format("YYYY-MM-DD"),
        }).toString(),
      });
    },
    [navigate, params]
  );

  return (
    <Box py={2}>
      <Box paddingTop={2} paddingBottom={2} px={1}>
        <CustomBreadcrumbs />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography
          variant="h1"
          color="primary"
          fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.6rem" }}
          fontWeight={600}
          paddingTop={1}
          paddingBottom={1}
        >
          Marketing
        </Typography>
      </Box>
      <Box display="flex" justifyContent="end" pb={2}>
        <DateRange onChange={handleDateChange} />
      </Box>

      <MarketingInsights insights={insights} />
    </Box>
  );
}

export default Marketing;
