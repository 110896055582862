import grey from "@mui/material/colors/grey";
import GlobalStyles from "@mui/material/GlobalStyles";
import Paper from "@mui/material/Paper";
import styled from "@mui/material/styles/styled";
import CardTitle from "components/CardTitle";
import PropTypes from "prop-types";
import { useEffect, useMemo, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";

const STATUS_ORDER = [
  "new",
  "received",
  "confirmed",
  "inProcess",
  "ready",
  "delivered",
  "shipped",
  "completed",
  "refunded",
  "replacement",
  "returned",
  "cancel",
];

const TIME_UNITS = {
  MONTH: 60 * 24 * 30,
  DAY: 60 * 24,
  HOUR: 60,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  padding: "1rem",
  display: "flex",
  height: "100%",
  width: "100%",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: 1,
}));

const capitalizeWords = (str) =>
  str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

const transformMinutes = (minutes) => {
  const months = Math.floor(minutes / TIME_UNITS.MONTH);
  const days = Math.floor((minutes % TIME_UNITS.MONTH) / TIME_UNITS.DAY);
  const hours = Math.floor((minutes % TIME_UNITS.DAY) / TIME_UNITS.HOUR);
  const mins = Math.floor(minutes % TIME_UNITS.HOUR);

  const timeUnits = [
    { value: months, unit: "month" },
    { value: days, unit: "day" },
    { value: hours, unit: "h" },
    { value: mins, unit: "m" },
  ];

  return timeUnits
    .filter(({ value }) => value)
    .map(({ value, unit }) =>
      unit === "h" || unit === "m"
        ? `${value}${unit}`
        : `${value} ${unit}${value > 1 ? "s" : ""}`
    )
    .join(" ");
};

const rearrangeAndTransformData = (data) => {
  return STATUS_ORDER.reduce((acc, key) => {
    if (data[key] !== undefined) {
      const transformedKey = key === "inProcess" ? "in process" : key;
      acc[transformedKey] = data[key];
    }
    return acc;
  }, {});
};

const CustomTooltip = ({ name, value }) => `
  <div class="custom-tooltip">
    <span class="tooltip-label">${capitalizeWords(name)}</span>
    <span class="tooltip-value">${value}</span>
  </div>
`;

function StatusAgeDonutChart({ data }) {
  const chartRef = useRef(null);
  const [chartWidth, setChartWidth] = useState("100%");
  const isOpen = useSelector((state) => state.UI.isSidebarOpen);
  const [statusAge] = useState(rearrangeAndTransformData(data));

  const { statuses, series, transformedValues } = useMemo(() => {
    const statuses = Object.keys(statusAge);
    const series = Object.values(statusAge);
    const transformedValues = statuses.map((status) => ({
      name: status,
      transformedValue: transformMinutes(statusAge[status]),
    }));
    return { statuses, series, transformedValues };
  }, [statusAge]);

  const chartOptions = useMemo(
    () => ({
      chart: { type: "pie" },
      labels: statuses,
      stroke: { colors: ["#fff"] },
      fill: { opacity: 0.9 },
      responsive: [
        {
          breakpoint: 1000,
          options: { legend: { position: "bottom" } },
        },
      ],
      legend: {
        position: "left",
        formatter: capitalizeWords,
      },
      tooltip: {
        custom: ({ seriesIndex }) =>
          CustomTooltip({
            name: transformedValues[seriesIndex].name,
            value: transformedValues[seriesIndex].transformedValue,
          }),
      },
      dataLabels: {
        enabled: true,
        formatter: (_, { seriesIndex }) =>
          capitalizeWords(statuses[seriesIndex]),
        dropShadow: { enabled: false },
      },
    }),
    [statuses, transformedValues]
  );

  useEffect(() => {
    const element = chartRef.current;
    if (!element) return;

    const resizeObserver = new ResizeObserver((entries) => {
      const { width } = entries[0].contentRect;
      setChartWidth(width);
    });

    resizeObserver.observe(element);
    return () => resizeObserver.disconnect();
  }, []);

  if (!Object.keys(statusAge).length) return null;

  return (
    <StyledPaper ref={chartRef}>
      <CardTitle title="Status Age" color={grey[700]} />
      <ReactApexChart
        options={chartOptions}
        series={series}
        type="pie"
        height={isOpen ? 378 : 361}
        width={chartWidth}
      />
      <GlobalStyles
        styles={{
          ".custom-tooltip": {
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            padding: "8px",
            borderRadius: "4px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          },
          ".tooltip-label": {
            display: "block",
            fontWeight: "bold",
            marginBottom: "5px",
            color: "#333",
          },
          ".tooltip-value": {
            display: "block",
            color: "#666",
          },
        }}
      />
    </StyledPaper>
  );
}

StatusAgeDonutChart.propTypes = {
  data: PropTypes.object.isRequired,
};

export default StatusAgeDonutChart;
