import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import HomeIcon from "assets/icons/HomeIcon.svg";
import { memo } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { PRIMARY } from "styles/colors";

function ReportsBreadCrumbs() {
  const location = useLocation();
  const pathnames = location.pathname
    .split("/")
    .filter((path) => path && path !== "reports");

  let reportsArray = [];
  if (pathnames.includes("manual-transactions")) {
    reportsArray.push({
      name: "Manual Transactions",
      route: "/reports/manual-transactions",
    });
  }
  if (pathnames.includes("logistics")) {
    reportsArray.push({ name: "Logistics", route: "/reports/logistics" });
  }
  if (pathnames.includes("transactions")) {
    reportsArray.push({ name: "Transactions", route: "/reports/transactions" });
  }
  if (pathnames.includes("vmt")) {
    reportsArray.push({ name: "VMT", route: "/reports/vmt" });
  }
  if (pathnames.includes("finance")) {
    reportsArray.push({ name: "Finance", route: "/reports/finance" });
  }
  if (pathnames.includes("marketing")) {
    reportsArray.push({ name: "Marketing", route: "/reports/marketing" });
  }

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNextIcon fontSize="small" sx={{ color: "#000" }} />}
    >
      <Link color="inherit" component={RouterLink} to="/">
        <img src={HomeIcon} alt="home" />
      </Link>
      {reportsArray.map((link, index) => {
        if (index === reportsArray.length - 1) {
          return (
            <span key={link.name} style={{ color: PRIMARY }}>
              {link?.name}
            </span>
          );
        } else {
          return (
            <Link
              key={link.name}
              color={PRIMARY}
              component={RouterLink}
              to={link?.route}
              sx={{
                margin: " 0 -.25rem",
                padding: "0 .25rem",
                "&:hover": {
                  backgroundColor: "#068ECF",
                  color: "#fff",
                  textDecoration: "none",
                },
              }}
            >
              {link?.name}
            </Link>
          );
        }
      })}
    </Breadcrumbs>
  );
}

export default memo(ReportsBreadCrumbs);
