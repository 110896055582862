import { RemoveCircle } from "@mui/icons-material";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import ProductAddImagePlaceholder from "assets/icons/ProductAddImagePlaceholder.svg";
import GalleryImagePlaceholder from "components/Product/ProductForm/GalleryImagePlaceholder";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import PropTypes from "prop-types";
import { useCallback, useRef, useState } from "react";

function ManualTransactionMediaSection({ formik }) {
  const transactionImagesRef = useRef(null);
  const [transactionImages, setTransactionImages] = useState(
    formik.values.transactionImages ?? []
  );
  const transactionImageHandler = useCallback(
    (e) => {
      const selectedFiles = Array.from(e.target.files);
      const currentGalleryLength = formik.values.transactionImages?.length ?? 0;

      if (currentGalleryLength + selectedFiles.length > 5) {
        formik.setFieldError(
          "transactionImages",
          "You can upload a maximum of 5 images"
        );
        return;
      }

      const newImages = selectedFiles.map((file) => URL.createObjectURL(file));
      const updatedImages = [...transactionImages, ...newImages];
      setTransactionImages(updatedImages);
      formik.setFieldValue("transactionImages", selectedFiles);
      e.target.value = "";
    },
    [formik, transactionImages]
  );

  const handleRemoveGalleryImage = (index) => {
    const updatedImages = transactionImages.filter((_, i) => i !== index);
    setTransactionImages(updatedImages);
    formik.setFieldValue("transactionImages", updatedImages);
  };

  return (
    <StyledMuiCard
      title="Media Section"
      subTitle="Upload image relevant to the transaction"
    >
      <CardContent>
        <Box display="flex" flexWrap="wrap" gap={4}>
          <Stack direction="column" gap={2} pl={2}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Transaction Images
            </Typography>

            <Box display="flex" flexDirection="row" gap={2}>
              <input
                multiple
                type="file"
                ref={transactionImagesRef}
                style={{ display: "none" }}
                id="upload-product-gallery-images"
                accept="image/x-png,image/png,image/jpeg,image/jpg"
                onChange={transactionImageHandler}
              />

              {transactionImages.length < 5 ? (
                <img
                  src={ProductAddImagePlaceholder}
                  alt="Add Product Gallery Placeholder"
                  height="auto"
                  width="150px"
                  style={{ cursor: "pointer" }}
                  onClick={() => transactionImagesRef.current.click()}
                />
              ) : null}

              {transactionImages.length === 0
                ? [...Array(2)].map((_, i) => (
                    <Box key={i}>
                      <GalleryImagePlaceholder />
                    </Box>
                  ))
                : transactionImages.map((image, index) => (
                    <Stack
                      position="relative"
                      key={index}
                      direction="row"
                      alignItems="flex-start"
                    >
                      <img
                        src={image}
                        alt={`Uploaded ${index + 1}`}
                        height="auto"
                        width="150px"
                      />
                      <Tooltip title="Remove Image">
                        <IconButton
                          onClick={() => handleRemoveGalleryImage(index)}
                          sx={{ position: "absolute", top: 0, right: 0 }}
                        >
                          <RemoveCircle sx={{ color: "#ff0000" }} />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  ))}
            </Box>
            {formik.errors.transactionImages && (
              <Typography variant="subtitle1" color="error">
                {formik.errors.transactionImages}
              </Typography>
            )}
          </Stack>
        </Box>
      </CardContent>
    </StyledMuiCard>
  );
}

export default ManualTransactionMediaSection;

ManualTransactionMediaSection.propTypes = {
  formik: PropTypes.object.isRequired,
};
