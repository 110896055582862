import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DateFilter from "components/Pickers/DateRange";
import FilterByStore from "components/Product/FilterByStore";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import { resetState } from "store/slices/reportSlice";
import { FILTER_TEXT_COLOR, PRIMARY } from "styles/colors";
import useQueryParams from "utils/hooks/useQueryParams";
import TransactionMethodFilter from "./TransactionMethodFilters";
import AddOnTypeAutocomplete from "./VmtAddOnFilter";
import PaymentFilter from "./VmtPaymentFilter";

function VmtFilters() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useQueryParams();
  const [open, setOpen] = useState(false);
  const { vmtTab } = useSelector((state) => state.reports);
  const userRole = useSelector((state) => state.auth?.userRole);

  const handleChange = useCallback(
    (range) => {
      const startDate = dayjs(range.startDate);
      const endDate = dayjs(range.endDate);

      let from, to;

      if (startDate.isSame(endDate, "day")) {
        // Same day scenario
        if (startDate.isSame(dayjs(), "day")) {
          // Today's date
          from = startDate.startOf("day");
          to = dayjs();
        } else {
          // Other same day (e.g., yesterday)
          from = startDate.startOf("day");
          to = startDate.endOf("day");
        }
      } else {
        from = startDate;
        to = endDate;
      }

      const _params = {
        ...params,
        from: dayjs(from).toISOString(),
        to: dayjs(to).toISOString(),
        page: 1,
        limit: 20,
      };

      navigate({
        search: createSearchParams(_params).toString(),
      });
    },
    [navigate, params]
  );

  const handleClear = useCallback(() => {
    setOpen(() => false);
    navigate(`/reports/vmt`);
  }, [navigate]);

  useEffect(() => {
    (params?.from ||
      params?.to ||
      params?.method ||
      params?.type ||
      params.addOnType) &&
      setOpen(() => true);
  }, [params]);

  useEffect(() => () => dispatch(resetState()), [dispatch]);

  useEffect(() => {
    setOpen(false);
  }, [vmtTab]);

  return (
    <>
      <Box
        marginY={2}
        display="flex"
        justifyContent="end"
        alignItems="center"
        gap={1}
      >
        <Box
          display="flex"
          justifyContent="flex-end"
          gap={2}
          my={0.5}
          minHeight={46}
        >
          {!open && (
            <Button
              variant="text"
              size="small"
              onClick={() => setOpen(() => true)}
              className="Filter-toggle-btn"
              sx={{
                borderBottom: "2px solid",
                borderColor: PRIMARY,
                borderRadius: 0,
                textTransform: "none",
              }}
            >
              Filter
            </Button>
          )}
          {open && (
            <>
              {vmtTab !== "ORDERS_FEES" && (
                <Box display="flex" gap={1}>
                  <TransactionMethodFilter />
                  <PaymentFilter />
                  <AddOnTypeAutocomplete />
                  {userRole === "admin" && <FilterByStore isVmtFilter={true} />}
                </Box>
              )}
              <DateFilter onChange={handleChange} />
            </>
          )}
          {open && (
            <Button
              onClick={handleClear}
              variant="text"
              className="Filter-toggle-btn"
              sx={{
                borderBottom: "2px solid #707070",
                borderRadius: 0,
                color: FILTER_TEXT_COLOR,
                textTransform: "none",
              }}
            >
              Clear All
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
}

export default VmtFilters;
