export const downloadCSV = (data, name) => {
  if (!data || !data.length) return;

  const csvContent = [
    Object.keys(data[0]).join(","), // Header row
    ...data.map((row) => Object.values(row).join(",")),
  ].join("\n");

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = name ?? "export_csv";
  link.click();
};
