/**
 * Checks if the provided number is valid based on the given criteria.
 * @param {string} number - The number to validate.
 * @returns {boolean} - Returns true if the number is valid, otherwise false.
 */
const isValidNumber = (number) => {
  const regex = /^[0-9]+(\.[0-9]{0,2})?$/;
  if (number === "" || regex.test(number)) {
    return true;
  }
  return false;
};

export default isValidNumber;
