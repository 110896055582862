import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import DatePicker from "components/Pickers/DatePicker";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import ProductSelect from "components/Vouchers/VoucherForm/ProductSelect";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { memo } from "react";
import { useParams } from "react-router-dom";
import { CURRENCY } from "utils/constants/constants";
import isValidNumber from "utils/helpers/isValidNumber";

function VoucherDetailSection({ formik }) {
  const _params = useParams();
  return (
    <Box mt={3}>
      <StyledMuiCard
        title="Voucher Details"
        subTitle="Please enter the basic information of the voucher such as name , quantity , description and number of vouchers"
      >
        <CardContent>
          <Grid container spacing={2} p={{ xs: 1, md: 1.5 }}>
            <Grid item xs={4} lg={4}>
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  fontSize="1.25rem"
                  color="text.secondary"
                >
                  Discount Type
                </Typography>
                <StyledTextField
                  name="discountType"
                  fullWidth
                  select
                  type="text"
                  value="amount"
                  disabled={_params.id}
                  {...formik.getFieldProps("discountType")}
                  error={Boolean(
                    formik.touched.discountType && formik.errors.discountType
                  )}
                  helperText={
                    formik.touched.discountType && !!formik.errors.discountType
                      ? formik.errors.discountType
                      : "Enter the discount type in percentage or amount here"
                  }
                >
                  <MenuItem value="amount">Amount</MenuItem>
                  <MenuItem value="percentage">Percentage</MenuItem>
                </StyledTextField>
              </Box>
            </Grid>
            <Grid item xs={4} lg={4}>
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  fontSize="1.25rem"
                  color="text.secondary"
                >
                  Voucher Type
                </Typography>
                <StyledTextField
                  name="voucherType"
                  fullWidth
                  select
                  {...formik.getFieldProps("voucherType")}
                  error={Boolean(
                    formik.touched.voucherType && formik.errors.voucherType
                  )}
                  helperText={
                    formik.touched.voucherType && !!formik.errors.voucherType
                      ? formik.errors.voucherType
                      : "Enter the voucher type in form of coupon or voucher here"
                  }
                  disabled={_params.id}
                >
                  <MenuItem value="coupon">Coupon</MenuItem>
                  {/* TODO: Options Commented as directed by Shama QA */}
                  {/* <MenuItem value="voucher">Voucher</MenuItem> */}
                </StyledTextField>
              </Box>
            </Grid>
            <Grid item xs={4} lg={4}>
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  fontSize="1.25rem"
                  color="text.secondary"
                >
                  Amount
                </Typography>
                <StyledTextField
                  name="amount"
                  type="text"
                  value={formik.values.amount}
                  onChange={(e) => {
                    isValidNumber(e.target.value) && formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  py={2}
                  disabled={_params.id}
                  error={Boolean(formik.touched.amount && formik.errors.amount)}
                  helperText={
                    formik.touched.amount && !!formik.errors.amount
                      ? formik.errors.amount
                      : "Enter amount of the voucher here in numbers"
                  }
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      lineHeight: 1,
                      fontSize: "2rem",
                      fontWeight: "bold",
                      color: "text.secondary",
                      height: "auto",
                      padding: "0.5rem 0.75rem",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <Typography
                        color="text.disabled"
                        sx={{
                          fontSize: "1.25rem",
                          borderRight: "1px solid #00000038",
                          paddingRight: "1rem",
                        }}
                      >
                        {formik.values.discountType === "amount"
                          ? CURRENCY
                          : "%"}
                      </Typography>
                    ),
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={4} lg={4}>
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  fontSize="1.25rem"
                  color="text.secondary"
                >
                  Voucher Code
                </Typography>
                <StyledTextField
                  name="voucherCode"
                  type="text"
                  value={formik.values.voucherCode}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^[A-Za-z0-9]*$/.test(value)) {
                      formik.setFieldValue("voucherCode", value);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  disabled={_params.id}
                  py={2}
                  error={Boolean(
                    formik.touched.voucherCode && formik.errors.voucherCode
                  )}
                  helperText={
                    formik.touched.voucherCode && !!formik.errors.voucherCode
                      ? formik.errors.voucherCode
                      : "Enter a voucher code (4-8 characters, letters and numbers only)"
                  }
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      lineHeight: 1,
                      fontSize: "2rem",
                      fontWeight: "bold",
                      color: "text.secondary",
                      height: "auto",
                      padding: "0.5rem 0.75rem",
                    },
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={4} lg={4}>
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  fontSize="1.25rem"
                  color="text.secondary"
                >
                  Start Date
                </Typography>
                <DatePicker
                  name="startDate"
                  value={dayjs(formik.values.startDate)}
                  onBlur={formik.handleBlur}
                  inputProps={{ readOnly: true }}
                  disabled={_params.id}
                  onChange={(value) => {
                    formik.setFieldValue("startDate", new Date(value), true);
                  }}
                  error={Boolean(
                    formik.touched.startDate && formik.errors.startDate
                  )}
                  helperText={
                    _params.id
                      ? null
                      : formik.touched.startDate && !!formik.errors.startDate
                      ? formik.errors.startDate
                      : "Enter the starting date of the voucher here"
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={4} lg={4}>
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  fontSize="1.25rem"
                  color="text.secondary"
                >
                  End Date
                </Typography>
                <DatePicker
                  name="endDate"
                  value={dayjs(formik.values.endDate)}
                  error={Boolean(
                    formik.touched.endDate && formik.errors.endDate
                  )}
                  onBlur={formik.handleBlur}
                  disabled={_params.id}
                  onChange={(value) => {
                    formik.setFieldValue("endDate", new Date(value), true);
                  }}
                  helperText={
                    _params.id
                      ? null
                      : formik.touched.endDate && !!formik.errors.endDate
                      ? formik.errors.endDate
                      : "Enter the ending date of the voucher here"
                  }
                />
              </Box>
            </Grid>
            {formik.values?.voucherType === "coupon" && (
              <ProductSelect formik={formik} />
            )}
          </Grid>
        </CardContent>
      </StyledMuiCard>
    </Box>
  );
}

VoucherDetailSection.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(VoucherDetailSection);
