import {
  getErrorMessage,
  getFormattedDateParams,
  getResponseData,
} from "utils/helpers/apiDataHelpers";
import { downloadCSV } from "utils/helpers/downloadCsv";
import { toast } from "utils/hooks/useToast";
import http from "utils/httpRequest/http";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const getTransactions = createAsyncThunk(
  "reports/getTransactions",
  async (data, { getState, rejectWithValue }) => {
    const { page, limit } = getState().reports;
    const { userRole } = getState().auth;
    const url =
      userRole === "admin" ? "/transactions/admin" : `/transactions/seller`;
    try {
      const response = await http.get(url, {
        params: {
          page: data?.page ?? page,
          limit: data?.limit ?? limit,
          sortBy: "-createdAt",
          ...data,
        },
      });
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const getAllTransactions = createAsyncThunk(
  "reports/getAllTransactions",
  async (data, { getState, rejectWithValue }) => {
    const { totalResults } = getState().reports;
    const { userRole } = getState().auth;
    const url =
      userRole === "admin" ? "/transactions/admin" : `/transactions/seller`;
    try {
      const response = await http.get(url, {
        params: {
          ...data,
          page: 1,
          limit: totalResults,
          sortBy: "-createdAt",
        },
      });
      const responseData = getResponseData(response);
      downloadCSV(responseData.results, "transaction_export");
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const getOrdersFees = createAsyncThunk(
  "reports/getOrdersFees",
  async (data, { getState, rejectWithValue }) => {
    const { limit } = getState().reports;
    try {
      const response = await http.get("/transactions/orderDetail/admin", {
        params: {
          page: 1,
          limit: limit,
          sortBy: "-createdAt",
          ...data,
        },
      });
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const getCancelOrders = createAsyncThunk(
  "orders/getCancelOrders",
  async (data, { getState, rejectWithValue }) => {
    const { userRole } = getState().auth;
    const { page, limit } = getState().orders;
    const url = userRole === "admin" ? "/order/admin" : "/order/seller";
    try {
      const response = await http.get(url, {
        params: {
          page: 1 ?? page,
          limit: 20 ?? limit,
          sortBy: "-createdAt",
          status: "cancel",
          ...data,
        },
      });
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getCancelOrdersCsv = createAsyncThunk(
  "orders/getCancelOrdersCsv",
  async (data, { getState, rejectWithValue }) => {
    const { userRole } = getState().auth;
    // const { totalResults } = getState().reports;
    const url = userRole === "admin" ? "/order/admin" : "/order/seller";
    try {
      const response = await http.get(url, {
        params: {
          page: 1,
          limit: 500, // hard code for now
          sortBy: "-createdAt",
          status: "cancel",
          ...data,
        },
      });
      const responseData = getResponseData(response);
      downloadCSV(responseData.results, "cancel_orders");
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const generateTransaction = createAsyncThunk(
  "reports/generateTransaction",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.post("/transactions", data);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const getOrderStats = createAsyncThunk(
  "/stats/orderCount",
  async (data, { rejectWithValue }) => {
    try {
      const { from, to } = data || {};
      const formattedDateParams = getFormattedDateParams({ from, to });

      const response = await http.get("/stats/orderCount", {
        params: {
          ...data,
        },
      });
      const statusAge = await http.get("/stats/orderAge", {
        params: {
          ...formattedDateParams,
        },
      });
      const responseData = getResponseData(response);
      const statusAgeData = getResponseData(statusAge);
      return { orderStats: responseData, statusAge: statusAgeData };
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

const initialState = {
  loading: true,
  page: 1,
  limit: 20,
  stats: {},
  csvTransactions: [],
  orders: [],
  cancelOrders: [],
  vmtTab: "TRANSACTIONS",
  totalPages: 0,
  totalResults: 0,
  reportError: "",
  error: "",
  financeTab: "TRANSACTIONS",
  orderStats: {},
  statusAge: {},
};

const reportSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    resetState: () => initialState,
    setVmtTab: (state, action) => {
      state.vmtTab = action.payload;
    },
    setFinanceTab: (state, action) => {
      state.financeTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTransactions.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getTransactions.fulfilled, (state, action) => {
        state.results = action.payload.results;
        state.stats = action.payload.stats;
        state.totalResults = action.payload.totalResults;
        state.totalPages = action.payload.totalPages;
        state.page = action.payload.page;
        state.limit = action.payload.limit;
        state.loading = false;
      })
      .addCase(getTransactions.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getAllTransactions.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getAllTransactions.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getAllTransactions.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getCancelOrdersCsv.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getCancelOrdersCsv.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getCancelOrdersCsv.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getOrdersFees.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getOrdersFees.fulfilled, (state, action) => {
        state.loading = false;
        state.orders = action.payload[0].results;
        state.totalResults = action.payload[0].totalResults;
        state.totalPages = action.payload[0].totalPages;
        state.page = action.payload[0].page;
        state.limit = action.payload[0].limit;
      })
      .addCase(getOrdersFees.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getCancelOrders.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getCancelOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.cancelOrders = action.payload.results;
        state.totalResults = action.payload.totalResults;
        state.totalPages = action.payload.totalPages;
        state.page = action.payload.page;
        state.limit = action.payload.limit;
      })
      .addCase(getCancelOrders.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(generateTransaction.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(generateTransaction.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(generateTransaction.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getOrderStats.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getOrderStats.fulfilled, (state, action) => {
        state.loading = false;
        state.stats = action.payload.orderStats;
        state.statusAge = action.payload.statusAge;
      })
      .addCase(getOrderStats.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});
export const { resetState, setVmtTab, setFinanceTab } = reportSlice.actions;
export default reportSlice.reducer;
