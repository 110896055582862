import ExpandLess from "@mui/icons-material/ExpandLess";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import styled from "@mui/material/styles/styled";
import WritingIcon from "assets/icons/WritingIcon.svg";
import deleteIcon from "assets/icons/deleteIcon.svg";
import CurrencyFormatter from "components/CurrencyFormatter.jsx";
import { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteVariantProduct,
  setVariantIndex,
} from "store/slices/productsSlice";
import VariantForm from "./VariantForm.jsx";

const StyledDel = styled("del")(() => ({}));

function ManageVariantsSection() {
  const dispatch = useDispatch();
  const variants = useSelector(
    (state) => state.products.product?.variants ?? []
  );
  const variantIndex = useSelector((state) => state.products.variantIndex);
  const isLoading = useSelector(
    (state) => state.products.productVariantLoading
  );

  const handleChange = useCallback(
    (index) => {
      if (variantIndex === index) {
        dispatch(setVariantIndex("-1"));
      } else {
        dispatch(setVariantIndex(index));
      }
    },
    [dispatch, variantIndex]
  );

  const handleDelete = useCallback(
    (variant) => {
      dispatch(deleteVariantProduct(variant?.id));
    },
    [dispatch]
  );

  return (
    <Stack direction="column" gap={2} p={{ xs: 1, md: 1.5 }}>
      {variants?.map((variant, index) => (
        <Accordion
          key={new Date().toString() + variant?.slug}
          expanded={variantIndex === index}
          TransitionProps={{ unmountOnExit: true }}
          elevation={0}
          sx={{
            "&::before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary
            aria-controls={`variant-${index}`}
            id={`variant-${index}-header`}
            sx={{
              cursor: "default !important",
              ":hover": {
                cursor: "default !important",
              },
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gap={2}
              width="100%"
            >
              <Box display="flex" gap={2} alignItems="center">
                <img
                  src={variant?.mainImage}
                  alt="product-variant"
                  style={{
                    height: "100px",
                    aspectRatio: "1/1",
                    borderRadius: "12px",
                  }}
                />
                <Box display="flex" gap={1} flexDirection="column">
                  <Box display="flex" gap={1}>
                    {(variant?.selectedAttributes ?? [])?.map(
                      ({ name, value }) => (
                        <Typography
                          key={name}
                          fontSize={{ md: "1rem", xl: "1.2rem" }}
                        >
                          {`${name}: `}
                          <span style={{ fontWeight: 600 }}>{value}</span>
                        </Typography>
                      )
                    )}
                  </Box>
                  <Box display="flex" alignItems="baseline" gap={1}>
                    <Typography fontSize={{ md: "1rem", xl: "1.2rem" }}>
                      Price:
                    </Typography>
                    {variant.onSale ? (
                      <Box display="flex" alignItems="baseline" gap={1}>
                        <CurrencyFormatter
                          className="product-price"
                          number={variant.salePrice}
                        />
                        <StyledDel
                          className="product-price"
                          sx={{ color: "error.dark", fontSize: "1rem" }}
                        >
                          <CurrencyFormatter number={variant.regularPrice} />
                        </StyledDel>
                      </Box>
                    ) : (
                      <CurrencyFormatter number={variant.regularPrice} />
                    )}
                  </Box>
                  <Box display="flex" alignItems="baseline" gap={1}>
                    <Typography fontSize={{ md: "1rem" }}>
                      SKU:{" "}
                      <span
                        style={{
                          fontWeight: 600,
                        }}
                      >
                        {variant?.sku ?? "N/A"}
                      </span>
                    </Typography>
                    <Typography fontSize={{ md: "1rem" }}>
                      Quantity:{" "}
                      <span
                        style={{
                          fontWeight: 600,
                        }}
                      >
                        {variant?.quantity ?? "N/A"}
                      </span>
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box display="flex" gap={2}>
                <Tooltip title={variantIndex === index ? "Close" : "Edit"}>
                  <IconButton
                    size="small"
                    disabled={isLoading}
                    onClick={() => handleChange(index)}
                  >
                    {variantIndex === index ? (
                      <ExpandLess />
                    ) : (
                      <img src={WritingIcon} alt="edit" />
                    )}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    size="small"
                    disabled={isLoading}
                    onClick={() => handleDelete(variant)}
                  >
                    <img src={deleteIcon} alt="delete" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <VariantForm variant={variant} />
          </AccordionDetails>
        </Accordion>
      ))}
    </Stack>
  );
}

export default memo(ManageVariantsSection);
