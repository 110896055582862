import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useCallback, useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { FILTER_TEXT_COLOR, FILTERS_BACKGROUND_COLOR } from "styles/colors";
import useQueryParams from "utils/hooks/useQueryParams";

const addOnTypes = [
  { label: "Add-On", value: "addOn" },
  { label: "Cancel Order", value: "cancelOrder" },
  { label: "Platform", value: "platform" },
];

function AddOnTypeAutocomplete() {
  const params = useQueryParams();
  const navigate = useNavigate();
  const [selectedAddOnType, setSelectedAddOnType] = useState(null);

  const handleChange = useCallback(
    (_, newValue) => {
      let _params = { ...params };

      if (newValue?.value) _params.addOnType = newValue.value;
      else if (!newValue && _params?.addOnType) {
        delete _params.addOnType;
      }
      setSelectedAddOnType(newValue);
      navigate({
        search: createSearchParams(_params).toString(),
      });
    },
    [navigate, params]
  );

  useEffect(() => {
    if (params?.addOnType)
      setSelectedAddOnType(
        addOnTypes.find((type) => type.value === params.addOnType) ?? null
      );
  }, [params]);

  return (
    <Autocomplete
      sx={{ minWidth: 170, maxWidth: 180 }}
      size="small"
      options={addOnTypes}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      value={selectedAddOnType}
      noOptionsText="No Options Available"
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select Add-On Type"
          fullWidth
          sx={{
            "& .MuiInputLabel-root": {
              fontSize: "1.063rem",
              height: 44,
            },
            "& .MuiInputBase-root": {
              height: 44,
              backgroundColor: FILTERS_BACKGROUND_COLOR,
              cursor: "pointer",
            },
            input: {
              color: FILTER_TEXT_COLOR,
              "&::placeholder": {
                opacity: 1,
              },
            },
          }}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  );
}

export default AddOnTypeAutocomplete;
