import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import {
  getDateRangeFormat,
  getErrorMessage,
  getFormattedDateParams,
  getResponseData,
} from "utils/helpers/apiDataHelpers";
import { toast } from "utils/hooks/useToast";
import http from "utils/httpRequest/http";

export const getInsights = createAsyncThunk(
  "insights/getInsights",
  async (data, { rejectWithValue }) => {
    const { from, to, ...keywords } = data || {};
    const _from = from ?? dayjs().subtract(1, "month").format("YYYY-MM-DD");
    const _to = to ?? dayjs().format("YYYY-MM-DD");

    const formattedStatusAgeDateParams = getFormattedDateParams({ from, to });
    const formattedOrdersGraphDateParams = getFormattedDateParams(
      { from, to },
      true // Uses startDate/endDate naming convention
    );
    const dateRangeFormat = getDateRangeFormat({ from, to });

    const insightsAPI = http.post("/google-analytics", {
      from: _from,
      to: _to,
      ...keywords,
    });
    const insightsGraphAPI = http.post("/google-analytics/graph", {
      from: _from,
      to: _to,
      metrics: ["totalUsers", "sessions", "screenPageViews"].map((keyword) => ({
        name: keyword,
      })),
    });
    const ordersGraphAPI = http.get(`/analytics/ordersChart`, {
      params: {
        ...formattedOrdersGraphDateParams,
        format: dateRangeFormat,
      },
    });
    const revenueAPI = http.get(`/analytics/revenue`, {
      params: {
        from: _from,
        to: _to,
      },
    });
    const statusAgeAPI = http.get("/stats/orderAge", {
      params: {
        ...formattedStatusAgeDateParams,
      },
    });

    try {
      const response = await Promise.all([
        insightsAPI,
        insightsGraphAPI,
        ordersGraphAPI,
        revenueAPI,
        statusAgeAPI,
      ]);
      const insights = getResponseData(response[0]);
      const insightsGraph = getResponseData(response[1]);
      const ordersGraph = getResponseData(response[2]);
      const revenue = getResponseData(response[3]);
      const statusAge = getResponseData(response[4]);

      const usersGraph = insightsGraph.find(
        (graph) => graph?.label?.name === "totalUsers"
      );
      const sessionsGraph = insightsGraph.find(
        (graph) => graph?.label?.name === "sessions"
      );
      const viewsGraph = insightsGraph.find(
        (graph) => graph?.label?.name === "screenPageViews"
      );

      return {
        insights,
        usersGraph: usersGraph?.graph ?? [],
        sessionsGraph: sessionsGraph?.graph ?? [],
        viewsGraph: viewsGraph?.graph ?? [],
        ordersGraph: ordersGraph ?? [],
        revenue: revenue?.length ? revenue[0] : revenue,
        statusAge,
      };
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

const initialState = {
  loading: true,
  insights: [],
  usersGraph: [],
  sessionsGraph: [],
  viewsGraph: [],
  ordersGraph: [],
  revenue: {},
  statusAge: {},
  error: "",
};

const insightsSlice = createSlice({
  name: "insights",
  initialState,
  reducers: {
    // Actions
    resetInsightsState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInsights.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getInsights.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.insights = action.payload.insights;
        state.usersGraph = action.payload.usersGraph;
        state.sessionsGraph = action.payload.sessionsGraph;
        state.viewsGraph = action.payload.viewsGraph;
        state.ordersGraph = action.payload.ordersGraph;
        state.revenue = action.payload.revenue;
        state.statusAge = action.payload.statusAge;
      })
      .addCase(getInsights.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      });
  },
});

// Action creators generated for each case reducer function
export const { resetInsightsState } = insightsSlice.actions;

// Exporting default reducer
export default insightsSlice.reducer;
