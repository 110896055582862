import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RemoveCircle from "@mui/icons-material/RemoveCircle";
import { styled, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import ProductAddImagePlaceholder from "assets/icons/ProductAddImagePlaceholder.svg";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import { useField, useFormikContext } from "formik";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import "react-image-crop/dist/ReactCrop.css";
import { useParams } from "react-router-dom";
import {
  deleteVideo,
  removeGalleryImage,
  uploadGalleryImages,
  uploadMainImage,
  uploadVideo,
} from "services/Product";
import { getErrorMessage, getResponseData } from "utils/helpers/apiDataHelpers";
import validateImageAspectRatio from "utils/helpers/validateImageAspectRatio";
import { toast } from "utils/hooks/useToast";
import GalleryImagePlaceholder from "./GalleryImagePlaceholder";
import ImageCropDialog from "./ImageCropDialog";

function MediaSection() {
  const params = useParams();
  const mainImageRef = useRef(null);
  const galleryRef = useRef(null);
  const videoRef = useRef(null);
  const { setFieldValue, setFieldTouched, setFieldError } = useFormikContext();
  const [, mainImageMeta] = useField("mainImage");
  const [, galleryMeta] = useField("gallery");
  const [, videoMeta] = useField("video");
  const [mainImageLoading, setMainImageLoading] = useState(false);
  const [galleryImageLoading, setGalleryImageLoading] = useState(false);
  const [videoLoading, setVideoLoading] = useState(false);
  const [cropQueue, setCropQueue] = useState([]);
  const [currentCropImage, setCurrentCropImage] = useState(null);
  const [croppedImages, setCroppedImages] = useState(null);
  const [isCropDialogOpen, setIsCropDialogOpen] = useState(false);
  const [currentCropIndex, setCurrentCropIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const isLaptop = useMediaQuery("(min-width:1024px)");
  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCropConfirm = useCallback(
    async (croppedBlob, field) => {
      try {
        const croppedFile = new File(
          [croppedBlob],
          currentCropImage?.file?.name,
          {
            type: currentCropImage?.file?.type,
          }
        );
        const isGallery = field === "gallery";
        const isMainImage = field === "mainImage";

        if (cropQueue.length > 1) {
          setCroppedImages((prevImages) => {
            return [...prevImages, croppedFile];
          });

          const currentIndex = currentCropIndex + 1;
          if (currentCropIndex < cropQueue.length) {
            const nextImage = cropQueue[currentIndex];
            const fileURL = URL.createObjectURL(nextImage);
            setCurrentCropImage({
              src: fileURL,
              file: nextImage,
              field: "gallery",
            });
            setCurrentCropIndex(currentIndex);
          } else {
            setCurrentCropImage(null);
          }
        }

        if (isGallery && cropQueue.length < 1) {
          setGalleryImageLoading(true);
          try {
            const response = await uploadGalleryImages(params?.id, [
              croppedFile,
            ]);
            const responseData = getResponseData(response);
            if (responseData?.gallery) {
              setFieldValue("gallery", responseData.gallery);
            }
          } catch (error) {
            setFieldError("gallery", getErrorMessage(error));
          } finally {
            setCurrentCropImage(null);
            setGalleryImageLoading(false);
            setIsCropDialogOpen(false);
          }
        }

        if (isMainImage) {
          setMainImageLoading(true);
          await uploadMainImage(params?.id, croppedFile)
            .then((response) => {
              const responseData = getResponseData(response);
              if (responseData?.mainImage) {
                setFieldValue("mainImage", responseData.mainImage);
              }
            })
            .catch((error) => {
              setFieldError("mainImage", getErrorMessage(error));
            })
            .finally(() => {
              setCurrentCropImage(null);
              setMainImageLoading(false);
              setIsCropDialogOpen(false);
            });
        }
      } catch (error) {
        console.error(getErrorMessage(error));
      }
    },
    [
      currentCropImage,
      cropQueue,
      currentCropIndex,
      params?.id,
      setFieldValue,
      setFieldError,
    ]
  );

  const mainImageHandler = useCallback(
    (e) => {
      setFieldTouched("mainImage", true);
      const image = e.target.files[0];
      if (image) {
        validateImageAspectRatio(image, 500, 500, false, true, 1 / 1, "1 / 1")
          .then((img) => {
            setMainImageLoading(true);
            uploadMainImage(params.id, img)
              .then((response) => {
                const responseData = getResponseData(response);
                if (responseData?.mainImage) {
                  setFieldValue("mainImage", responseData?.mainImage);
                }
              })
              .catch((error) => {
                setFieldError("mainImage", getErrorMessage(error));
              })
              .finally(() => {
                mainImageRef.current.value = "";
                setMainImageLoading(false);
              });
          })
          .catch(() => {
            mainImageRef.current.value = "";
            const reader = new FileReader();
            reader.onload = () => {
              setCurrentCropImage({
                src: reader.result,
                file: image,
                field: "mainImage",
              });
              setIsCropDialogOpen(true);
            };

            reader.readAsDataURL(image);
          });
      }
    },
    [params.id, setFieldTouched, setFieldValue, setFieldError]
  );

  const galleryImageHandler = useCallback(
    async (e) => {
      setFieldTouched("gallery", true);
      const selectedFiles = Array.from(e.target.files);
      const currentGalleryLength = galleryMeta.value.length ?? 0;
      if (currentGalleryLength + selectedFiles.length > 6) {
        toast.error("You can upload a maximum of 6 images");
        galleryRef.current.value = "";
        return;
      }

      const validImages = [];
      const invalidImages = [];
      for (const image of selectedFiles) {
        try {
          const validatedImage = await validateImageAspectRatio(
            image,
            500,
            500,
            false,
            true,
            1 / 1,
            "1 / 1"
          );
          validImages.push(validatedImage);
        } catch {
          invalidImages.push(image);
        }
      }

      if (validImages.length) {
        setGalleryImageLoading(true);
        try {
          const response = await uploadGalleryImages(params?.id, validImages);
          const responseData = getResponseData(response);
          setFieldValue("gallery", responseData.gallery);
        } catch (error) {
          setFieldError("gallery", getErrorMessage(error));
        } finally {
          setGalleryImageLoading(false);
        }
      }

      if (invalidImages.length === 1) {
        const reader = new FileReader();
        reader.onload = () => {
          setCurrentCropImage({
            src: reader.result,
            file: invalidImages[0],
            field: "gallery",
          });
          setIsCropDialogOpen(true);
        };
        reader.readAsDataURL(invalidImages[0]);
      }

      if (invalidImages.length > 1) {
        setCroppedImages(() => []);
        setCurrentCropIndex(() => 0);
        setCropQueue(() => invalidImages);
        const reader = new FileReader();
        reader.onload = () => {
          setCurrentCropImage({
            src: reader.result,
            file: invalidImages[currentCropIndex],
            field: "gallery",
          });
          setIsCropDialogOpen(true);
        };

        reader.readAsDataURL(invalidImages[currentCropIndex]);
      }
    },
    [
      currentCropIndex,
      galleryMeta.value?.length,
      params?.id,
      setFieldError,
      setFieldTouched,
      setFieldValue,
    ]
  );

  const handleRemoveGalleryImage = useCallback(
    (index) => {
      removeGalleryImage(params?.id, galleryMeta.value[index], index)
        .then((response) => {
          const responseData = getResponseData(response);
          setFieldValue("gallery", responseData.gallery);
        })
        .catch((error) => {
          const message = getErrorMessage(error);
          setFieldError("gallery", message);
        })
        .finally(() => {
          galleryRef.current.value = "";
        });
    },
    [galleryMeta.value, params?.id, setFieldError, setFieldValue]
  );

  const videoUploadHandler = useCallback(
    (e) => {
      setFieldTouched("video", true);
      setVideoLoading(() => true);
      uploadVideo(params?.id, e.target.files[0])
        .then((response) => {
          const responseData = getResponseData(response);
          responseData?.videoUrl &&
            setFieldValue("video", responseData.videoUrl);
        })
        .catch((error) => {
          setFieldError("video", getErrorMessage(error));
        })
        .finally(() => {
          setVideoLoading(() => false);
          videoRef.current.value = "";
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params?.id]
  );

  const handleDeleteVideo = useCallback(() => {
    deleteVideo(params?.id, videoMeta.value)
      .then(() => setFieldValue("video", ""))
      .catch((error) => {
        setFieldError("video", getErrorMessage(error));
      })
      .finally(() => {
        videoRef.current.value = "";
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id, videoMeta.value]);

  useEffect(() => {
    const updateGallery = async () => {
      if (
        croppedImages?.length === cropQueue?.length &&
        croppedImages?.length > 0
      ) {
        setGalleryImageLoading(true);
        try {
          const response = await uploadGalleryImages(params?.id, croppedImages);
          const responseData = getResponseData(response);

          if (responseData?.gallery) {
            setFieldValue("gallery", responseData.gallery);
          }
        } catch (error) {
          setFieldError("gallery", getErrorMessage(error));
        } finally {
          setCurrentCropIndex(0);
          setCropQueue([]);
          setCroppedImages([]);
          setGalleryImageLoading(false);
          setIsCropDialogOpen(false);
        }
      }
    };

    if (
      croppedImages?.length === cropQueue?.length &&
      croppedImages?.length > 0
    ) {
      updateGallery();
    }
  }, [
    cropQueue?.length,
    croppedImages,
    croppedImages?.length,
    params?.id,
    setFieldError,
    setFieldValue,
  ]);

  return (
    <StyledMuiCard
      title="Media Section"
      subTitle="Upload images and videos relevant to the product ( Allowed image formats: JPEG, JPG, PNG )"
    >
      <CardContent>
        <Box display="flex" flexWrap="wrap" gap={4}>
          {/* Product Main Image */}
          <Stack direction="column" gap={2}>
            <Stack direction="row" gap={1} alignItems="center" height="auto">
              <Typography
                variant="h3"
                fontWeight="bold"
                fontSize="1.25rem"
                color="text.secondary"
              >
                Main Image
              </Typography>
              <LightTooltip
                open={isLaptop ? undefined : open}
                onClose={handleClose}
                disableHoverListener={!isLaptop}
                disableTouchListener={!isLaptop}
                title={
                  <Box padding={1} width={450}>
                    <Typography
                      variant="h4"
                      fontWeight="bold"
                      fontSize="1.1rem"
                    >
                      See Example
                    </Typography>
                    <Box display="flex" paddingTop={1}>
                      <img
                        src="https://bazaarghar-main.s3.me-south-1.amazonaws.com/vmt/PakWholesaller-04-04-24/PAK-WHL-370.jpg"
                        alt="Example Product"
                        width={180}
                        height={180}
                      />
                      <Typography>
                        1. Image resolution should be min 600x600 & max
                        1200x1200px (square image 1:1) White background. The
                        product should be properly visible. <br />
                        2. Image file size should be in KB (less than 2MB).
                        <br />
                        3. Images should be appropriate and accurate Gallery
                        images must have 5 to 6 different angle images.
                      </Typography>
                    </Box>
                    <Typography
                      fontWeight="bold"
                      fontSize="0.9rem"
                      marginTop={2}
                    >
                      Note: It is prohibited to watermark your phone number,
                      email address, or any link, etc. on pictures.
                    </Typography>
                  </Box>
                }
                placement="right-start"
              >
                <InfoOutlinedIcon
                  color="text.secondary"
                  onClick={handleToggle}
                  onBlur={handleClose}
                  sx={{ cursor: "pointer" }}
                />
              </LightTooltip>
            </Stack>
            <Box
              width={{ xs: "150px", md: "350px", xl: "400px" }}
              height={{ xs: "150px", md: "350px", xl: "400px" }}
              position="relative"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {mainImageLoading ? (
                <CircularProgress />
              ) : (
                <img
                  src={
                    mainImageMeta.value
                      ? mainImageMeta.value
                      : ProductAddImagePlaceholder
                  }
                  alt="Product Main Media"
                  style={{
                    ...(!mainImageMeta.value && { cursor: "pointer" }),
                    height: "100%",
                    width: "100%",
                  }}
                  onClick={() =>
                    !mainImageMeta.value && mainImageRef.current.click()
                  }
                />
              )}
              {mainImageMeta.value && (
                <Tooltip title="Update Image">
                  <IconButton
                    color="primary"
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                    }}
                    onClick={() => mainImageRef.current.click()}
                  >
                    <ChangeCircleIcon />
                  </IconButton>
                </Tooltip>
              )}
              <input
                type="file"
                ref={mainImageRef}
                style={{ display: "none" }}
                accept="image/x-png,image/png,image/jpeg,image/jpg"
                onChange={mainImageHandler}
              />
            </Box>

            {mainImageMeta?.touched && mainImageMeta?.error && (
              <Typography variant="subtitle1" color="error">
                {mainImageMeta.error}
              </Typography>
            )}
          </Stack>

          <Stack
            direction="column"
            gap={2}
            height="100%"
            justifyContent="space-between"
          >
            {/* Product Gallery Images */}
            <Stack direction="column" gap={2}>
              <Stack direction="row" gap={1} alignItems="center">
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  fontSize="1.25rem"
                  color="text.secondary"
                >
                  Gallery Images
                </Typography>
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  fontSize="0.85rem"
                  color="text.secondary"
                  lineHeight={1}
                >
                  (500*500 pixels)
                </Typography>
              </Stack>
              <Box display="flex" flexDirection="row" gap={2}>
                <input
                  multiple
                  type="file"
                  ref={galleryRef}
                  style={{ display: "none" }}
                  id="upload-product-gallery-images"
                  accept="image/x-png,image/png,image/jpeg,image/jpg"
                  onChange={galleryImageHandler}
                />
                {galleryImageLoading ? (
                  <Box
                    height="auto"
                    width="150px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress />
                  </Box>
                ) : galleryMeta.value.length < 6 ? (
                  <img
                    src={ProductAddImagePlaceholder}
                    alt="Add Product Gallery Placeholder"
                    height="auto"
                    width="150px"
                    style={{ cursor: "pointer" }}
                    onClick={() => galleryRef.current.click()}
                  />
                ) : null}
                {!galleryMeta.value?.length
                  ? [...Array(5)]?.map((e, i) => (
                      <Box key={i}>
                        <GalleryImagePlaceholder />
                      </Box>
                    ))
                  : galleryMeta.value?.map((image, index) => (
                      <Stack
                        position="relative"
                        key={index}
                        direction="row"
                        alignItems="flex-start"
                      >
                        <img src={image} alt="" height="auto" width="150px" />
                        {!galleryImageLoading && (
                          <Tooltip title="Remove Image">
                            <IconButton
                              disabled={galleryImageLoading}
                              onClick={() => handleRemoveGalleryImage(index)}
                              sx={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                              }}
                            >
                              <RemoveCircle sx={{ color: "#ff0000" }} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Stack>
                    ))}
              </Box>
              {galleryMeta.touched && galleryMeta.error && (
                <Typography variant="subtitle1" color="error">
                  {galleryMeta.error}
                </Typography>
              )}
            </Stack>

            {/* Product Video */}
            <Stack direction="column" gap={2}>
              <Stack direction="row" gap={1} alignItems="center">
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  fontSize="1.25rem"
                  color="text.secondary"
                >
                  Product Video
                </Typography>
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  fontSize="0.85rem"
                  color="text.secondary"
                  lineHeight={1}
                >
                  (Less then 350MB)
                </Typography>
              </Stack>
              {videoLoading ? (
                <Box
                  height="150px"
                  width="150px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress />
                </Box>
              ) : videoMeta.value ? (
                <Box
                  minHeight={150}
                  position="relative"
                  width="fit-content"
                  display="flex"
                  alignItems="center"
                >
                  <video
                    src={videoMeta.value}
                    alt="Product Video"
                    height="auto"
                    width="150px"
                    controls
                  />
                  <Tooltip title="Delete Video">
                    <IconButton
                      disabled={videoLoading}
                      onClick={handleDeleteVideo}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                      }}
                    >
                      <RemoveCircle sx={{ color: "#ff0000" }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              ) : (
                <img
                  src={ProductAddImagePlaceholder}
                  alt="Add Product Video Placeholder"
                  height="auto"
                  width="150px"
                  style={{ cursor: "pointer" }}
                  onClick={() => videoRef.current.click()}
                />
              )}
              <input
                type="file"
                ref={videoRef}
                style={{ display: "none" }}
                id="update-product-video"
                accept="video/mp4,video/x-m4v,video/*"
                onChange={videoUploadHandler}
              />
              {videoMeta.touched && videoMeta.error && (
                <Typography variant="subtitle1" color="error">
                  {videoMeta.error}
                </Typography>
              )}
            </Stack>
            <ImageCropDialog
              imgSrc={currentCropImage?.src}
              isOpen={isCropDialogOpen}
              onClose={() => {
                cropQueue.length === croppedImages.length &&
                  setIsCropDialogOpen(false);
              }}
              onCropConfirm={(croppedBlob) => {
                handleCropConfirm(croppedBlob, currentCropImage?.field);
              }}
            />
          </Stack>
        </Box>
      </CardContent>
    </StyledMuiCard>
  );
}

export default memo(MediaSection);

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    maxWidth: 700,
  },
}));
