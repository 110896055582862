import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import styled from "@mui/material/styles/styled";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CurrencyFormatter from "components/CurrencyFormatter";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { quickUpdateProduct } from "store/slices/productsSlice";
import isValidNumber from "utils/helpers/isValidNumber";
import { toast } from "utils/hooks/useToast";

const StyledDel = styled("del")(() => ({}));

export const EditableCell = ({ type, params }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      setIsEditing(() => false);
    } else if (e.key === "Enter") {
      handleSaveClick();
    }
  };

  const handleChange = (e) => {
    setError(() => false);
    const inputValue = e.target.value;
    if (type === "price" && isValidNumber(inputValue, true)) {
      setValue(() => inputValue);
    } else if (/^[1-9][0-9]*$/.test(inputValue) || inputValue === "") {
      setValue(() => inputValue);
    }
  };

  const handleSaveClick = () => {
    const updateKey =
      type === "price"
        ? params.row.onSale
          ? "salePrice"
          : "regularPrice"
        : type;

    if (type === "price" && params.row.onSale && updateKey === "salePrice") {
      if (parseFloat(value) >= parseFloat(params.row.regularPrice)) {
        toast.error("Sale price must be less than regular price");
        setError(() => true);
        return;
      }
    }

    setLoading(() => true);
    dispatch(
      quickUpdateProduct({
        productId: params.row.id,
        body: {
          [updateKey]: +value,
        },
      })
    )
      .unwrap()
      .then(() => {
        setIsEditing(() => false);
      })
      .finally(() => {
        setLoading(() => false);
      });
  };

  useEffect(() => {
    if (isEditing) {
      if (type === "price") {
        setValue(() =>
          params.row.onSale ? params.row.salePrice : params.row.regularPrice
        );
      } else {
        setValue(() => params.row[type]);
      }
    }
    return () => {
      setValue(() => "");
    };
  }, [isEditing, type, params]);

  return (
    <Box>
      {isEditing ? (
        <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <TextField
            autoFocus
            type="text"
            size="small"
            value={value}
            error={error}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            sx={{ maxWidth: 80 }}
          />

          <IconButton
            onClick={handleSaveClick}
            sx={{ padding: "3px" }}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress color="primary" size={15} />
            ) : (
              <CheckCircleOutline color="primary" sx={{ fontSize: "1.2rem" }} />
            )}
          </IconButton>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            cursor: "pointer",
          }}
          onDoubleClick={handleDoubleClick}
        >
          {type === "quantity" ? (
            <Box>
              <Typography>Qty: {params?.row?.[type]}</Typography>
            </Box>
          ) : params.row.onSale ? (
            <Box>
              <CurrencyFormatter
                className="product-price"
                number={params.row.salePrice}
              />
              <StyledDel
                className="product-price"
                sx={{ color: "error.dark", fontSize: "1rem" }}
              >
                <CurrencyFormatter number={params.row.regularPrice} />
              </StyledDel>
            </Box>
          ) : (
            <CurrencyFormatter number={params.row.regularPrice} />
          )}
          <IconButton onClick={handleEditClick} sx={{ padding: "3px" }}>
            <EditIcon sx={{ fontSize: "1.2rem" }} />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default EditableCell;

EditableCell.propTypes = {
  type: PropTypes.oneOf(["quantity", "price"]).isRequired,
  params: PropTypes.shape({
    row: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      salePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      regularPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      variants: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      onSale: PropTypes.bool,
    }).isRequired,
  }).isRequired,
};
