import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import PeopleIcon from "@mui/icons-material/People";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InsightCard from "components/UI/InsightCard";
import PropTypes from "prop-types";
import { useCallback } from "react";

function MarketingInsights({ insights }) {
  const getKeywordIcon = useCallback((title) => {
    switch (title) {
      case "activeUsers":
        return <PeopleIcon />;
      case "averageSessionDuration":
        return <HistoryToggleOffIcon />;
      case "userRetentionRate":
        return <HistoryToggleOffIcon />;
      default:
        return;
    }
  }, []);

  const userRetentionRate = insights?.checkouts + insights?.totalUsers;
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Grid container spacing={2} wrap="nowrap">
        {["activeUsers", "averageSessionDuration"].map((keyword) => (
          <Grid item xs={4} xl={4} key={keyword} flex="1">
            <InsightCard
              keyword={keyword}
              value={insights?.[keyword]}
              icon={getKeywordIcon(keyword)}
              showTime={keyword === "averageSessionDuration"}
            />
          </Grid>
        ))}
        <Grid item xs={4} xl={4} key="userRetentionRate" flex="1">
          <InsightCard
            keyword="userRetentionRate"
            value={!isNaN(userRetentionRate) ? userRetentionRate : 0}
            icon={getKeywordIcon("userRetentionRate")}
            title="User Retention Rate"
          />
        </Grid>
      </Grid>
    </Box>
  );
}

MarketingInsights.propTypes = {
  insights: PropTypes.object.isRequired,
};

export default MarketingInsights;
